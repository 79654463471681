import React, { useRef, useEffect, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Video from "../../assets/images/performance_video.mp4";
import ImagemFundo from "../../assets/images/Carrosel_5.jpg"
import { BiClipboard, BiBookOpen, BiMap, BiUser } from "react-icons/bi";

function HomeRelacionamento() {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error: any) => {
        console.error("Erro ao reproduzir vídeo:", error);
      });
    }
  }, []);

  sessionStorage.setItem("portalLogado", "portalInstitucional");

  const linksHome = [
    {
      icon: <BiClipboard color="#165788" />,
      titulo: t("Cadastro"),
      link: "/cadastro_relacionamento",
      buttonClass: "button-icon-relacionamento",
    },
    {
      icon: <BiMap color="#00544d" />,
      titulo: t("Mapa"),
      link: "/mapa",
      buttonClass: "button-icon-institucional",
    },
    {
      icon: <BiBookOpen color="#e57200" />,
      titulo: t("Manual"),
      link: "/manual",
      buttonClass: "button-icon-instituto",
    },
    {
      icon: <BiUser color="#fbaf17" />,
      titulo: t("Perfil"),
      link: "/perfil",
      buttonClass: "button-icon-indicadores",
    },
  ];

  return (
    <div className="home-page">
      <img className="video-background" src={ImagemFundo} />
      {/* <video
        ref={videoRef}
        autoPlay
        loop
        muted
        controls={false}
        className="video-background"
        src={Video}
      >
        <source src={Video} type="video/mp4" />
      </video> */}
      <Col sm={12} id="home">
        {/* <Row> */}
        <Col sm={12} style={{ zIndex: "1" }}>
          <Col className="col-lg-6 col-sm-12">
            <h5>
              {t(
                "Acreditamos que o crescimento e o sucesso que valem a pena são aqueles conquistados juntos e que podem ser compartilhados."
              )}
            </h5>
          </Col>

          <Col className="position-card col-lg-8 col-sm-12 col-12 mb-5">
            <Row>
              {linksHome.map(({ icon, titulo, link, buttonClass }, i) => (
                <Col sm={3} key={i}>
                  <Link to={link} className={`col-6 link ${buttonClass}`}>
                    <Col sm={12}>
                      <span className="col-12 icons-home">{icon}</span>
                      <h3>{titulo}</h3>
                    </Col>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Col>

        {/* <Col sm={6}>
          <video
            ref={videoRef}
            autoPlay
            loop
            muted
            controls={false}
            width="100%"
          >
            <source src={Video} type="video/mp4" />
          </video>
        </Col> */}
        {/* </Row> */}
      </Col>
    </div>
  );
}

export default HomeRelacionamento;
