import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container, Row, Col, Modal, Card, ListGroup, Form } from 'react-bootstrap';
import Api from "../../config/api";
import { ArrowDownIcon } from "@heroicons/react/24/outline";

interface Comunidade {
    id: number;
    comunidade: string;
    lat: number;
    lng: number;
    unidade: string;
}

interface Coordinates {
    lat: number;
    lng: number;
}

const containerStyle = {
    width: "100%",
    height: "500px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const center = {
    lat: -18.171909,
    lng: -47.777259,
};

const infoWindowStyle = {
    fontSize: '15px', // Ajuste o tamanho da fonte conforme necessário
    // padding: '5px',  // Ajuste o padding
    maxWidth: '150px', // Defina a largura máxima
};

const GOOGLE_API_KEY = "AIzaSyCysGhoOi0XTrKJVPqSfJNZ_04CEOu-a0Y";

const houseIconBase64 = "data:image/svg+xml;base64," + btoa(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#00FF00" class="size-6">
  <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
  <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
</svg>
`);

const redPinIconBase64 = "data:image/svg+xml;base64," + btoa(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FF0000" class="size-6">
  <path d="M12 2C8.134 2 5 5.134 5 9c0 4 7 13 7 13s7-9 7-13c0-3.866-3.134-7-7-7zm0 11.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"/>
</svg>
`);

interface MapAtendimentosProps {
    onCoordinatesSelected: (coordinates: Coordinates) => void;
}

const MapAtendimentos: React.FC<MapAtendimentosProps> = ({ onCoordinatesSelected }) => {
    const [selectedPosition, setSelectedPosition] = useState<Coordinates | null>(null);
    const [comunidades, setComunidades] = useState<Comunidade[]>([]);
    const [expandedUnidades, setExpandedUnidades] = useState<Record<string, boolean>>({});
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [addingMarker, setAddingMarker] = useState<boolean>(false);
    const [markingCoordinates, setMarkingCoordinates] = useState<boolean>(false);
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);
    const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
    const [activeMarker, setActiveMarker] = useState<number | null>(null);
    const [savedCoordinates, setSavedCoordinates] = useState<Coordinates | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [communityToDelete, setCommunityToDelete] = useState<number | null>(null);

    // Estados para o modal de adicionar comunidade
    const [showAddCommunityModal, setShowAddCommunityModal] = useState<boolean>(false);
    const [unidades, setUnidades] = useState<string[]>([]);
    const [selectedUnidade, setSelectedUnidade] = useState<string>("");
    const [newCommunityName, setNewCommunityName] = useState<string>("");

    // Estado para coordenadas marcadas
    const [markedCoordinates, setMarkedCoordinates] = useState<Coordinates | null>(null);

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            if (addingMarker) {
                setMarkedCoordinates({ lat, lng });
                setShowAddCommunityModal(true); // Abre o modal de adicionar comunidade
                setAddingMarker(false); // Desativa o modo de adicionar marcador
            } else if (markingCoordinates) {
                setSavedCoordinates({ lat, lng });
                onCoordinatesSelected({ lat, lng });
                setMarkingCoordinates(false); // Desativa o modo de marcar coordenadas
            }
        }
    };

    const loadComunidades = async () => {
        try {
            const comunidadesSalvas = await Api.GetMapCoordenadaComunidade();
            setComunidades(comunidadesSalvas);
            // Suponha que as unidades estejam disponíveis de alguma maneira, talvez de uma API
            const unidadesList = ["Unidade 1", "Unidade 2", "Unidade 3"]; // Exemplo
            setUnidades(unidadesList);
        } catch (error) {
            console.error("Erro ao carregar comunidades:", error);
        }
    };

    const saveComunidade = async () => {
        if (!markedCoordinates || !selectedUnidade || !newCommunityName) return;

        const novaComunidade: Omit<Comunidade, 'id'> = { comunidade: newCommunityName, lat: markedCoordinates.lat, lng: markedCoordinates.lng, unidade: selectedUnidade };

        try {
            await Api.PostMapCoordenadaComunidade(novaComunidade);
            loadComunidades();
            setShowAddCommunityModal(false);
            setMarkedCoordinates(null);
            alert("Comunidade salva com sucesso!");
        } catch (error) {
            console.error("Erro ao salvar comunidade:", error);
        }
    };

    const deleteComunidade = async (id: number) => {
        setIsDeleting(true);
        try {
            await Api.DeleteMapCoordenadaComunidade(id);
            loadComunidades();
            setShowDeleteModal(false);
        } catch (error) {
            console.error("Erro ao deletar comunidade:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const confirmDelete = (id: number) => {
        setCommunityToDelete(id);
        setShowDeleteModal(true);
    };

    useEffect(() => {
        loadComunidades();
    }, []);

    const onMapClick = (event: google.maps.MapMouseEvent) => {
        handleMapClick(event);
    };

    const handleMapLoad = useCallback((map: google.maps.Map) => {
        setMapInstance(map);
        setMapLoaded(true);
    }, []);

    const handleMarkerClick = (index: number) => {
        setActiveMarker(index);
    };

    const centerMap = (lat: number, lng: number) => {
        if (mapInstance) {
            mapInstance.panTo({ lat, lng });
            mapInstance.setZoom(15);
        }
    };

    const handleCommunityClick = (lat: number, lng: number) => {
        centerMap(lat, lng);
    };

    const handleUnidadeClick = (unidade: string) => {
        setExpandedUnidades(prev => ({
            ...prev,
            [unidade]: !prev[unidade]
        }));
    };

    const groupedComunidades = comunidades.reduce<Record<string, Comunidade[]>>((acc, comunidade) => {
        if (!acc[comunidade.unidade]) {
            acc[comunidade.unidade] = [];
        }
        acc[comunidade.unidade].push(comunidade);
        return acc;
    }, {});

    return (
        <Container fluid>
            <Row className="my-3">
                <Col md={8} className="mb-4">
                    <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={12}
                            mapTypeId="satellite"
                            onClick={onMapClick}
                            onLoad={handleMapLoad}
                        >
                            {mapLoaded && savedCoordinates && (
                                <Marker
                                    position={savedCoordinates}
                                    icon={{
                                        url: redPinIconBase64,
                                        scaledSize: new google.maps.Size(30, 30),
                                    }}
                                />
                            )}

                            {mapLoaded && comunidades.map((comunidade, index) => (
                                <Marker
                                key={index}
                                position={{ lat: comunidade.lat, lng: comunidade.lng }}
                                icon={{
                                    url: houseIconBase64,
                                    scaledSize: new google.maps.Size(30, 30),
                                }}
                                onClick={() => handleMarkerClick(index)}
                            >
                                {activeMarker === index && (
                                    <InfoWindow>
                                        <div style={infoWindowStyle}>
                                            <p>{comunidade.comunidade}</p>
                                        </div>
                                    </InfoWindow>
                                )}
                            </Marker>
                            ))}
                        </GoogleMap>
                    </LoadScript>
                </Col>
                <Col md={4}>
                    <div className="d-grid gap-2 mb-3">
                        <Button variant="primary" onClick={() => setAddingMarker(true)}>
                            Adicionar Comunidade
                        </Button>
                        <Button variant="success" onClick={() => setMarkingCoordinates(true)}>
                            Marcar Coordenadas
                        </Button>
                    </div>

                    {savedCoordinates && (
                        <Card className="mb-3 shadow-sm">
                            <Card.Body>
                                <Card.Title>Coordenada Marcada</Card.Title>
                                <Card.Text>
                                    <strong>Latitude:</strong> {savedCoordinates.lat.toFixed(6)}<br />
                                    <strong>Longitude:</strong> {savedCoordinates.lng.toFixed(6)}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    )}

                    <Card className="scrollable-list shadow-sm" style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <Card.Body>
                            {Object.entries(groupedComunidades).map(([unidade, comunidades]) => (
                                <div key={unidade} className="mb-2">
                                    <Button
                                        variant="link"
                                        style={{
                                            textDecoration: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => handleUnidadeClick(unidade)}
                                    >
                                        <strong className="me-2">{unidade}</strong>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                transform: expandedUnidades[unidade] ? 'rotate(360deg)' : 'rotate(270deg)',
                                                transition: 'transform 0.3s',
                                                minWidth: '20px',
                                            }}
                                        >
                                            <ArrowDownIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                        </span>
                                    </Button>
                                    {expandedUnidades[unidade] && (
                                        <ListGroup>
                                            {comunidades.map((comunidade) => (
                                                <ListGroup.Item
                                                    key={comunidade.id}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleCommunityClick(comunidade.lat, comunidade.lng)}
                                                >
                                                    {comunidade.comunidade}
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() => confirmDelete(comunidade.id)}
                                                        className="ml-2 float-end"
                                                    >
                                                        Excluir
                                                    </Button>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )}
                                </div>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal para Adicionar Comunidade */}
            <Modal show={showAddCommunityModal} onHide={() => setShowAddCommunityModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Comunidade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formUnidade">
                            <Form.Label>Unidade</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedUnidade}
                                onChange={(e) => setSelectedUnidade(e.target.value)}
                            >
                                <option value="">Selecione a Unidade</option>
                                {Object.entries(groupedComunidades).map(([unidade], index) => (
                                    <option key={index} value={unidade}>{unidade}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formCommunityName" className="mt-3">
                            <Form.Label>Nome da Comunidade</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Digite o nome da comunidade"
                                value={newCommunityName}
                                onChange={(e) => setNewCommunityName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddCommunityModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={saveComunidade}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza de que deseja excluir esta comunidade?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => deleteComunidade(communityToDelete!)} disabled={isDeleting}>
                        {isDeleting ? "Excluindo..." : "Excluir"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default MapAtendimentos;
