import "./styles.css";
import LogoWhite from "../../assets/brand/logoWhite.png";
import LoadingIcon from "../../components/loadingIcon";
import { useTranslation } from "react-i18next";

function LoadingPage() {
  const { t } = useTranslation();

  return (
    <div id="loadingPage">
      <div className="loadingPageContent">
        <img src={LogoWhite} alt="teste" />

        <div className="loadingHolder">
          <LoadingIcon />

          <span>{t("Carregando o seu portal...")}</span>
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
