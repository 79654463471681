import "./styles.css";
import { Col, Form, Row, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../config/api";
import { useTranslation } from "react-i18next";

function Noticias({
    config,
    permissoesUser,
    toggleCard,
}: {
    config: any;
    toggleCard: any;
    permissoesUser: {
        administrador: boolean;
        atendimentos_create: boolean;
        atendimentos_edit: boolean;
        atendimentos_view: boolean;
        demandas_create: boolean;
        demandas_edit: boolean;
        demandas_view: boolean;
        engajamentos_create: boolean;
        engajamentos_edit: boolean;
        engajamentos_view: boolean;
        id: number;
        id_user: number;
        noticias_create: boolean;
        noticias_edit: boolean;
        noticias_view: boolean;
        notificacoes_create: boolean;
        notificacoes_edit: boolean;
        notificacoes_view: boolean;
        stakeholders_create: boolean;
        stakeholders_edit: boolean;
        stakeholders_view: boolean;
        usuario_create: boolean;
        usuario_edit: boolean;
        visitas_create: boolean;
        visitas_edit: boolean;
        visitas_view: boolean;
        sms_view: boolean;
        sms_create: boolean;
        solicitacoes_view: boolean;
        solicitacoes_edit: boolean;
    };
}) {
    const { t } = useTranslation();

    const [showModalRegister, setShowModalRegister] = useState<boolean>(false);

    //input
    const [titulo, setTitulo] = useState<string>("");
    const [assunto, setAssunto] = useState<string>("");
    const [texto, setTexto] = useState<string>("");
    const [autor, setAutor] = useState<string>("");

    const [registroFotografico, setRegistroFotografico] = useState(null);

    //Select
    const [estado, setEstado] = useState<string>("");
    const [municipio, setMunicipio] = useState<string>("");

    const [listaEstados, setListaEstados] = useState<any>([]);
    const [listaMunicipios, setListaMunicipios] = useState<any>([]);

    //Date
    const [data, setData] = useState<string>("");

    const [toastExibido, setToastExibido] = useState(false);

    useEffect(() => {
        if (!permissoesUser.noticias_create && !toastExibido) {
            toast.error("Você não tem permissão para criar uma nova Noticia!");
            setToastExibido(true);
        }
    }, [permissoesUser.noticias_create, toastExibido]);

    useEffect(() => {
        Api.GetLocales().then((data) => {
            setListaEstados(data.localidades.estados);
            setListaMunicipios(data.localidades.municipios);
        });
    }, []);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const formData = new FormData();

        if (registroFotografico !== null) {
            formData.append("avatar", registroFotografico);
        }

        formData.append("texto", texto);
        formData.append("titulo", titulo);
        formData.append("autor", autor);
        formData.append("data", data);
        formData.append("assunto", assunto);
        formData.append("estado", estado);
        formData.append("municipio", municipio);

        Api.PostNoticia(formData, config)
            .then((res) => {
                handleCloseModalRegister();
                handleClearFields();
                toast.success("Noticia cadastrada com sucesso!");
            })
            .catch((err) => {
                toast.error("Não foi possível cadastrar a noticia!");
            });
    };

    const handleClearFields = () => {
        setTitulo("");
        setAssunto("");
        setTexto("");
        setAutor("");
        setEstado("");
        setMunicipio("");
        setData("");
        setRegistroFotografico(null);
    };

    const handleCloseModalRegister = () => {
        setShowModalRegister(false);
    };

    const handleOpenModalRegister = () => {
        setShowModalRegister(true);
    };

    return (
        <Col sm={12} id="noticiasPage">
            <Form onReset={handleClearFields} encType="multipart/form-data">
                <Col sm={12} className="mt-3 mb-3">
                    <Row>
                        <Col sm={6}>
                            <Form.Label>{t("Titulo")}</Form.Label>

                            <Form.Control
                                className="formInput"
                                type={"text"}
                                required
                                value={titulo}
                                onChange={(e) => {
                                    setTitulo(e.target.value);
                                }}
                            />
                        </Col>
                        <Col sm={6}>
                            <Form.Label>{t("Assunto")}</Form.Label>

                            <Form.Control
                                className="formInput"
                                type={"text"}
                                required
                                value={assunto}
                                onChange={(e) => {
                                    setAssunto(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Form.Label className="mt-3">{t("Texto")}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        className="formInput"
                        required
                        value={texto}
                        onChange={(e) => {
                            setTexto(e.target.value);
                        }}
                    />
                </Col>
                <Col sm={12} className="mt-3 mb-3">
                    <Row>
                        <Col sm={6}>
                            <Form.Label>{t("Autor")}</Form.Label>

                            <Form.Control
                                className="formInput"
                                type={"text"}
                                required
                                value={autor}
                                onChange={(e) => {
                                    setAutor(e.target.value);
                                }}
                            />
                        </Col>
                        <Col sm={6}>
                            <Form.Label>{t("Data")}</Form.Label>

                            <Form.Control
                                className="formInput"
                                type={"date"}
                                required
                                value={data}
                                onChange={(e) => {
                                    setData(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} className="mt-3 mb-3">
                    <Row>
                        <Col sm={6}>
                            <Form.Label>{t("Estado")}</Form.Label>

                            <Form.Select
                                required
                                value={estado}
                                onChange={(e) => {
                                    setEstado(e.target.value);
                                }}
                            >
                                <option value={""}>
                                    {t("Selecione o estado")}
                                </option>
                                {listaEstados?.map((estado: any) => {
                                    return (
                                        <option value={estado.sigla}>
                                            {estado.nome}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>{t("Municipio")}</Form.Label>

                            <Form.Select
                                required
                                value={municipio}
                                onChange={(e) => {
                                    setMunicipio(e.target.value);
                                }}
                            >
                                <option value={""}>
                                    {t("Selecione o municipio")}
                                </option>
                                {listaMunicipios[estado]?.map(
                                    (municipio: any) => {
                                        return (
                                            <option value={municipio}>
                                                {municipio}
                                            </option>
                                        );
                                    }
                                )}
                            </Form.Select>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} className="mt-3 mb-3">
                    <Col sm={12} className="labelFotos mt-3 p-2">
                        <Form.Label>
                            {t(
                                "Foto (Use arquivos .jpg, jpeg, png, e webp com no máximo 2048 kB)"
                            )}
                        </Form.Label>
                    </Col>
                    <Col sm={6} className="cadastroFotos mt-3">
                        <Form.Control
                            type="file"
                            accept="image/*"
                            name="avatar"
                            multiple
                            onChange={(e: any) => {
                                setRegistroFotografico(
                                    e.target.files[0] ? e.target.files[0] : null
                                );
                            }}
                        />
                    </Col>
                </Col>
                <Col sm={12}>
                    <Row>
                        <Col sm={6}>
                            {permissoesUser.noticias_create ? (
                                <button
                                    className="botao-noticia"
                                    type="button"
                                    onClick={handleOpenModalRegister}
                                >
                                    {t("Registrar")}
                                </button>
                            ) : (
                                <button
                                    className="botao-noticia"
                                    type="button"
                                    onClick={() =>
                                        toast.error(
                                            "Você não tem permissão para criar uma nova Noticia!"
                                        )
                                    }
                                >
                                    {t("Registrar")}
                                </button>
                            )}
                        </Col>
                        <Col sm={6}>
                            <button type="reset" className="botao-noticia">
                                {t("Limpar")}
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Form>
            <Modal
                show={showModalRegister}
                onHide={handleCloseModalRegister}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja enviar o cadastro?")}
                            </p>

                            <button
                                className="botao-grid"
                                onClick={handleSubmit}
                            >
                                {t("Sim")}
                            </button>
                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalRegister}
                            >
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default Noticias;
