import axios from "axios";
import {
    TemplateBody,
    TemplateFooter,
    TemplateHeader,
    TemplateHeaderFormats,
    TemplateItem,
    WppContact,
} from "./types";

const wppAxios = axios.create({
    baseURL: "https://whatsapp-api.directy.com.br/",
    // baseURL: "http://localhost:62401/",
    headers: { "Content-Type": "application/json" },
});

const configFormdata = { headers: { "Content-Type": "multipart/form-data" } };

class WppApi {
    static async postTemplate(
        templateName: string,
        headerType: TemplateHeaderFormats | undefined,
        headerText: string | undefined,
        headerFile: File | undefined,
        bodyText: string,
        footerText: string
    ) {
        const headerObj: TemplateHeader | undefined =
            headerType === "TEXT"
                ? {
                      type: "HEADER",
                      format: headerType,
                      text: headerText || "",
                  }
                : headerType === "IMAGE" || headerType === "DOCUMENT" || headerType === "VIDEO"
                ? {
                      type: "HEADER",
                      format: headerType,
                  }
                : undefined;
        const bodyObj: TemplateBody = {
            type: "BODY",
            text: bodyText,
        };
        const footerObj: TemplateFooter | undefined = footerText
            ? {
                  type: "FOOTER",
                  text: footerText,
              }
            : undefined;

        const templateObj = new FormData();

        templateObj.append("name", templateName);
        if (headerObj) templateObj.append("header", JSON.stringify(headerObj));
        templateObj.append("body", JSON.stringify(bodyObj));
        if (footerObj) templateObj.append("footer", JSON.stringify(footerObj));
        if (headerFile) templateObj.append("file", headerFile);

        return await wppAxios
            .post("/template/performance", templateObj, configFormdata)
            .then((res) => res.data);
    }

    static async deleteTemplate(id: string, name: string) {
        return await wppAxios
            .delete("/template", { params: { id: id, name: name } })
            .then((res) => res.data);
    }

    static async getTemplate() {
        return await wppAxios.get("/template/performance").then((res) => res.data);
    }

    static async postMessage(template: TemplateItem, contacts: WppContact[]) {
        const recipients = contacts.map((c) => ({ phone: c.telefone }));

        const messageObj = { template, recipients };

        return await wppAxios.post("/message", messageObj).then((res) => res.data);
    }
}

export default WppApi;
