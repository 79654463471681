import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { BiSolidHome, BiLogOut, BiClipboard, BiBookOpen, BiUser, BiMap } from "react-icons/bi";
import {
    FaPeopleGroup,
    FaHandsHoldingChild,
    FaHandHoldingHeart,
    FaWhatsapp,
} from "react-icons/fa6";
import { FaChartBar, FaBars, FaTimes, FaPeopleArrows } from "react-icons/fa";
import { GoPasskeyFill } from "react-icons/go";
import { ClearSessionStorage } from "../../config/api";

import { MdOutlineKeyboardReturn } from "react-icons/md";
import { Button } from "react-bootstrap";

function Menu() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMenuVisible, setMenuVisible] = useState<boolean | null>(false);
    const [isIndicadoresDropdownVisible, setIndicadoresDropdownVisible] = useState<boolean>(false);
    const [portalLogado, setPortalLogado] = useState("");
    const [indicadoresMenuClicked, setIndicadoresMenuClicked] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const portal = sessionStorage.getItem("portalLogado");
        if (portal) setPortalLogado(portal);

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            closeMenu();
        }
    };

    const toggleMenuVisibility = () => {
        setMenuVisible(!isMenuVisible);
        if (!isMenuVisible) {
            closeIndicadoresDropdown();
        }
    };

    const closeMenu = () => {
        setMenuVisible(false);
        closeIndicadoresDropdown();
    };

    const toggleIndicadoresDropdown = () => {
        setIndicadoresDropdownVisible(!isIndicadoresDropdownVisible);
    };

    const closeIndicadoresDropdown = () => {
        setIndicadoresDropdownVisible(false);
        setIndicadoresMenuClicked(false);
    };

    const handleIndicadoresClick = () => {
        setIndicadoresMenuClicked(!indicadoresMenuClicked);
        toggleIndicadoresDropdown();
    };

    const logoutItem = {
        title: "Sair",
        link: "/login",
    };

    const linkItensComunidades = [
        {
            icon: BiSolidHome,
            title: "Portais",
            link: "/portais",
        },
        {
            icon: MdOutlineKeyboardReturn,
            title: "Home",
            link: "/",
        },
        {
            icon: BiClipboard,
            title: "Cadastro",
            link: "/cadastro",
        },
        {
            icon: FaWhatsapp,
            title: "Comunicação",
            link: "/comunicacao",
        },
        {
            icon: BiBookOpen,
            title: "Manual",
            link: "https://online.fliphtml5.com/oxhzi/guww/",
            external: true,
        },
        {
            icon: FaChartBar,
            title: "Indicadores",
            submenu: [
                {
                    icon: FaHandHoldingHeart,
                    title: "Painel Social",
                    link: "/indicadores_painelSocial",
                },
                {
                    icon: FaPeopleGroup,
                    title: "Comunidades",
                    link: "/indicadores_comunidades",
                },
                {
                    icon: FaHandsHoldingChild,
                    title: "Institucional",
                    link: "/indicadores_institucional",
                },
                {
                    icon: FaPeopleArrows,
                    title: "Desenvolvimento Social",
                    link: "/indicadores_desenvolvimentoSocial",
                },
            ],
        },
        {
            icon: GoPasskeyFill,
            title: "Permissões",
            link: "/permissoes",
        },
        {
            icon: BiUser,
            title: "Perfil",
            link: "/perfil",
        },
    ];

    const linkItensInstitucional = [
        {
            icon: BiSolidHome,
            title: "Portais",
            link: "/portais",
        },
        {
            icon: MdOutlineKeyboardReturn,
            title: "Home",
            link: "/relacionamento_institucional",
        },
        {
            icon: BiClipboard,
            title: "Cadastro",
            link: "/cadastro_relacionamento",
        },
        {
            icon: BiMap,
            title: "Mapa",
            link: "/mapa",
        },
        {
            icon: BiBookOpen,
            title: "Manual",
            link: "https://online.fliphtml5.com/oxhzi/guww/",
            external: true,
        },
        {
            icon: FaChartBar,
            title: "Indicadores",
            submenu: [
                {
                    icon: FaHandHoldingHeart,
                    title: "Painel Social",
                    link: "/indicadores_painelSocial",
                },
                {
                    icon: FaPeopleGroup,
                    title: "Comunidades",
                    link: "/indicadores_comunidades",
                },
                {
                    icon: FaHandsHoldingChild,
                    title: "Institucional",
                    link: "/indicadores_institucional",
                },
                {
                    icon: FaPeopleArrows,
                    title: "Desenvolvimento Social",
                    link: "/indicadores_desenvolvimentoSocial",
                },
            ],
        },
        {
            icon: GoPasskeyFill,
            title: "Permissões",
            link: "/permissoes",
        },
        {
            icon: BiUser,
            title: "Perfil",
            link: "/perfil",
        },
    ];

    const linkItensIndicadores = [
        {
            icon: BiSolidHome,
            title: "Portais",
            link: "/portais",
            external: false,
        },
        {
            icon: FaChartBar,
            title: "Indicadores",
            submenu: [
                {
                    icon: FaHandHoldingHeart,
                    title: "Painel Social",
                    link: "/indicadores_painelSocial",
                },
                {
                    icon: FaPeopleGroup,
                    title: "Comunidades",
                    link: "/indicadores_comunidades",
                },
                {
                    icon: FaHandsHoldingChild,
                    title: "Institucional",
                    link: "/indicadores_institucional",
                },
                {
                    icon: FaPeopleArrows,
                    title: "Desenvolvimento Social",
                    link: "/indicadores_desenvolvimentoSocial",
                },
            ],
        },
        {
            icon: BiUser,
            title: "Perfil",
            link: "/perfil",
            external: false,
        },
    ];

    const deslogar = () => {
        if (window.confirm(t("Deseja realmente sair?") as string)) {
            ClearSessionStorage();
            navigate("/login");
        }
    };

    return (
        <div className="teste-teste">
            {!isMenuVisible ? (
                <Button
                    onClick={toggleMenuVisibility}
                    aria-controls="basic-navbar-nav"
                    className="botao-toggle-expand"
                >
                    <FaBars className="navbar-toggler-icon toggle-menu-expand" />
                </Button>
            ) : (
                <button className="botao-toggle-fechar" onClick={closeMenu}>
                    <FaTimes className="navbar-toggler-icon toggle-menu-fechar" />
                </button>
            )}
            <div id="menuComponent">
                <div
                    className={`menuToggle ${isMenuVisible ? "visible" : ""}`}
                    onMouseLeave={closeIndicadoresDropdown}
                >
                    <div className={`menuItens ${isMenuVisible ? "expanded" : ""}`}>
                        {portalLogado === "portalComunidades" &&
                            linkItensComunidades.map(
                                ({ title, link, icon: Icon, external, submenu }, i) => {
                                    const menuItemLink = title;
                                    return (
                                        <div key={i}>
                                            {submenu ? (
                                                <div>
                                                    <a
                                                        onClick={handleIndicadoresClick}
                                                        className="menuLinkItem"
                                                    >
                                                        <span>
                                                            <Icon className="icons-menu" />
                                                        </span>
                                                        <span className="menuLinkText">
                                                            {t(menuItemLink)}
                                                        </span>
                                                    </a>
                                                    {isIndicadoresDropdownVisible && (
                                                        <div className="dropdownMenu">
                                                            {submenu.map((item, index) => (
                                                                <NavLink
                                                                    key={index}
                                                                    to={item.link}
                                                                    className="menuLinkItem"
                                                                    onClick={closeMenu}
                                                                >
                                                                    <span>
                                                                        {item.icon && (
                                                                            <item.icon className="icons-subMenu" />
                                                                        )}
                                                                    </span>
                                                                    <span className="menuLinkTextSubMenu">
                                                                        {item.title}
                                                                    </span>
                                                                </NavLink>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <NavLink
                                                    to={link}
                                                    className="menuLinkItem"
                                                    target={external ? "_blank" : undefined}
                                                    onClick={closeMenu}
                                                >
                                                    <span>
                                                        <Icon className="icons-menu" />
                                                    </span>
                                                    <span className="menuLinkText">
                                                        {t(menuItemLink)}
                                                    </span>
                                                </NavLink>
                                            )}
                                        </div>
                                    );
                                }
                            )}
                        {portalLogado === "portalInstitucional" &&
                            linkItensInstitucional.map(
                                ({ title, link, icon: Icon, external, submenu }, i) => {
                                    const menuItemLink = title;
                                    return (
                                        <div key={i}>
                                            {submenu ? (
                                                <div>
                                                    <a
                                                        onClick={handleIndicadoresClick}
                                                        className="menuLinkItem"
                                                    >
                                                        <span>
                                                            <Icon className="icons-menu" />
                                                        </span>
                                                        <span className="menuLinkText">
                                                            {t(menuItemLink)}
                                                        </span>
                                                    </a>
                                                    {isIndicadoresDropdownVisible && (
                                                        <div className="dropdownMenu">
                                                            {submenu.map((item, index) => (
                                                                <NavLink
                                                                    key={index}
                                                                    to={item.link}
                                                                    className="menuLinkItem"
                                                                    onClick={closeMenu}
                                                                >
                                                                    <span>
                                                                        {item.icon && (
                                                                            <item.icon className="icons-subMenu" />
                                                                        )}
                                                                    </span>
                                                                    <span className="menuLinkTextSubMenu">
                                                                        {item.title}
                                                                    </span>
                                                                </NavLink>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <NavLink
                                                    to={link}
                                                    className="menuLinkItem"
                                                    target={external ? "_blank" : undefined}
                                                    onClick={closeMenu}
                                                >
                                                    <span>
                                                        <Icon className="icons-menu" />
                                                    </span>
                                                    <span className="menuLinkText">
                                                        {t(menuItemLink)}
                                                    </span>
                                                </NavLink>
                                            )}
                                        </div>
                                    );
                                }
                            )}
                        {portalLogado === "portalIndicadores" &&
                            linkItensIndicadores.map(
                                ({ title, link, icon: Icon, external, submenu }, i) => {
                                    const menuItemLink = title;
                                    return (
                                        <div key={i}>
                                            {submenu ? (
                                                <div>
                                                    <a
                                                        onClick={handleIndicadoresClick}
                                                        className="menuLinkItem"
                                                    >
                                                        <span>
                                                            <Icon className="icons-menu" />
                                                        </span>
                                                        <span className="menuLinkText">
                                                            {t(menuItemLink)}
                                                        </span>
                                                    </a>
                                                    {isIndicadoresDropdownVisible && (
                                                        <div className="dropdownMenu">
                                                            {submenu.map((item, index) => (
                                                                <NavLink
                                                                    key={index}
                                                                    to={item.link}
                                                                    className="menuLinkItem"
                                                                    onClick={closeMenu}
                                                                >
                                                                    <span>
                                                                        {item.icon && (
                                                                            <item.icon className="icons-subMenu" />
                                                                        )}
                                                                    </span>
                                                                    <span className="menuLinkTextSubMenu">
                                                                        {item.title}
                                                                    </span>
                                                                </NavLink>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <NavLink
                                                    to={link}
                                                    className="menuLinkItem"
                                                    target={external ? "_blank" : undefined}
                                                    onClick={closeMenu}
                                                >
                                                    <span>
                                                        <Icon className="icons-menu" />
                                                    </span>
                                                    <span className="menuLinkText">
                                                        {t(menuItemLink)}
                                                    </span>
                                                </NavLink>
                                            )}
                                        </div>
                                    );
                                }
                            )}
                        <button className={"logoutButton menuLinkItem"} onClick={deslogar}>
                            <span>
                                <BiLogOut className="icons-menu" />
                            </span>
                            <span className="menuLinkText">{t(logoutItem.title)}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
