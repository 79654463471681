import React, { useEffect, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { BiClipboard, BiBookOpen, BiMap, BiUser } from "react-icons/bi";
import { GoPasskeyFill } from "react-icons/go";
import ImagemFundo from "../../assets/images/Carrosel_5.jpg";
import Api from "../../config/api";

function HomeComunidades() {
  const { t } = useTranslation();

  const tokenUsuario = sessionStorage.getItem("tokenUsuario");
  let config = { headers: { authorization: tokenUsuario } };

  sessionStorage.setItem("portalLogado", "portalComunidades");

  // const userString = sessionStorage.getItem("loginUsuario");
  // const objUser = userString ? JSON.parse(userString) : {};

  const linksHome = [
    {
      icon: <BiClipboard color="#165788" />,
      titulo: t("Cadastro"),
      link: "/cadastro",
      buttonClass: "button-icon-relacionamento",
    },
    {
      icon: <BiBookOpen color="#e57200" />,
      titulo: t("Manual"),
      link: "https://online.fliphtml5.com/lzkli/bhpv/",
      external: true,
      buttonClass: "button-icon-instituto",
    },
    {
      icon: <GoPasskeyFill color="#00544d" />,
      titulo: t("Permissões"),
      link: "/permissoes",
      buttonClass: "button-icon-institucional",
    },

    {
      icon: <BiUser color="#fbaf17" />,
      titulo: t("Perfil"),
      link: "/perfil",
      buttonClass: "button-icon-indicadores",
    },
  ];

  return (
    <div className="home-page">
      <img className="video-background" src={ImagemFundo} />
      <Col sm={12} id="home">
        <Col sm={12} style={{ zIndex: "1" }}>
          <Col className="col-lg-6 col-sm-12">
            <h5>
              {t(
                "Acreditamos que o crescimento e o sucesso que valem a pena são aqueles conquistados juntos e que podem ser compartilhados."
              )}
            </h5>
          </Col>

          <Col className="position-card col-lg-8 col-sm-12 col-12 mb-5">
            <Row>
              {linksHome.map(
                ({ icon, titulo, link, buttonClass, external }, i) => (
                  <Col className="col-sm-3 col-12" key={i}>
                    <Link
                      to={link}
                      className={`col-6 link ${buttonClass}`}
                      target={external ? "_blank" : undefined}
                    >
                      <Col sm={12}>
                        <span className="col-12 icons-home">{icon}</span>
                        <h3>{titulo}</h3>
                      </Col>
                    </Link>
                  </Col>
                )
              )}
            </Row>
          </Col>
        </Col>
      </Col>
    </div>
  );
}

export default HomeComunidades;
