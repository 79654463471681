import "./styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Image } from "react-bootstrap";
import ImagemHome from "../../assets/images/home.png";
import {
  FaPeopleArrows,
  FaHandHoldingWater,
  FaLeaf,
  FaChartBar,
} from "react-icons/fa";

function NewPortal() {
  const { t } = useTranslation();

  const linksHome = [
    {
      icon: <FaPeopleArrows color="#165788" />,
      titulo: t("Relacionamento com Comunidades"),
      link: "/",
      buttonClass: "button-icon-relacionamento",
      click: sessionStorage.setItem("portalLogado", "portalComunidades")
    },
    {
      icon: <FaHandHoldingWater color="#00544d" />,
      titulo: t("Relacionamento Institucional"),
      link: "/relacionamento_institucional",
      buttonClass: "button-icon-institucional",
      click: sessionStorage.setItem("portalLogado", "portalInstitucional")
    },
    {
      icon: <FaLeaf color="#e57200" />,
      titulo: t("Desenvolvimento Social"),
      link: "/",
      buttonClass: "button-icon-instituto",
    },
    {
      icon: <FaChartBar color="#fbaf17" />,
      titulo: t("Painel Social"),
      link: "/indicadores_painelSocial",
      buttonClass: "button-icon-indicadores",
    },
  ];

  return (
    <Col sm={12} id="portal">
      <Row>
        <Col sm={6} style={{ zIndex: "1" }}>
          <Col sm={12}>
            <h5>
              {t(
                "Acreditamos que o crescimento e o sucesso que valem a pena são aqueles conquistados juntos e que podem ser compartilhados."
              )}
            </h5>
          </Col>

          <Col sm={8} className="position-card mb-3">
            {linksHome.map(({ icon, titulo, link, buttonClass, click}, i) => (
              <Col className="col-12 col-sm-6" key={i}>
                <Link to={link} className={`col-6 link ${buttonClass}`} onClick={() => click}>
                  <Col sm={12}>
                    <span className="col-12 icons-portal">{icon}</span>
                    <h3>{titulo}</h3>
                  </Col>
                </Link>
              </Col>
            ))}
          </Col>
        </Col>

        <Col sm={4}>
          <Image src={ImagemHome} className="o-interest-area"></Image>
        </Col>
      </Row>
    </Col>
  );
}

export default NewPortal;
