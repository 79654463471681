import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";

const IndicadoresPainelSocialTelevisao = () => {
  const [currentSrcIndex, setCurrentSrcIndex] = useState(0);
  const powerBiLinks = [
    "https://app.powerbi.com/view?r=eyJrIjoiM2ZhODIxMzktOWI3ZC00MGUxLThiMDUtMjEzZmQ5MTkzZWMwIiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9"
    // "https://app.powerbi.com/view?r=eyJrIjoiNjI4ZDkyYzUtY2JkNy00MmQ0LThlMGQtNTYyYzU3YzBjNTRkIiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9",
    // "https://app.powerbi.com/view?r=eyJrIjoiOGRiMDI2MjItMzllNy00NGExLWEyOTktMDZiODhiMWI0MzRmIiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9",
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentSrcIndex((prevIndex) => (prevIndex + 1) % powerBiLinks.length);
    }, 30000);

    return () => clearTimeout(timer);
  }, [currentSrcIndex]);

  return (
    <Col xs={12}>
      <iframe
        title="Report Section"
        src={powerBiLinks[currentSrcIndex]}
        className="dashTv"
      ></iframe>
    </Col>
  );
};

export default IndicadoresPainelSocialTelevisao;
