import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Api, { linkBackend } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card } from "react-bootstrap";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import DateRangeInput from "../../../utils/DateRangeInput";
import treatArray from "../../../utils/treatArray";
import treatString from "../../../utils/treatString";
import { capitalize, capitalizeArray, toUpperArray } from "../../../utils/capitalize";
import ButtonExportExcel from "../../../components/buttonExportListExcel";

function GridNoticias({
    config,
    permissoesUser,
}: {
    config: any;
    permissoesUser: {
        administrador: boolean;
        atendimentos_create: boolean;
        atendimentos_edit: boolean;
        atendimentos_view: boolean;
        demandas_create: boolean;
        demandas_edit: boolean;
        demandas_view: boolean;
        engajamentos_create: boolean;
        engajamentos_edit: boolean;
        engajamentos_view: boolean;
        id: number;
        id_user: number;
        noticias_create: boolean;
        noticias_edit: boolean;
        noticias_view: boolean;
        notificacoes_create: boolean;
        notificacoes_edit: boolean;
        notificacoes_view: boolean;
        stakeholders_create: boolean;
        stakeholders_edit: boolean;
        stakeholders_view: boolean;
        usuario_create: boolean;
        usuario_edit: boolean;
        visitas_create: boolean;
        visitas_edit: boolean;
        visitas_view: boolean;
        sms_view: boolean;
        sms_create: boolean;
        solicitacoes_view: boolean;
        solicitacoes_edit: boolean;
    };
}) {
    const { t } = useTranslation();
    const navegar = useNavigate();

    const [datagridRows, setDatagridRows] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModalFiltro, setOpenModalFiltro] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);

    const [listaEstados, setListaEstados] = useState<any>([]);
    const [listaMunicipios, setListaMunicipios] = useState<any>([]);

    const [idNoticia, setIdNoticia] = useState<number>(0);

    //input
    const [titulo, setTitulo] = useState<string>("");
    const [assunto, setAssunto] = useState<string>("");
    const [texto, setTexto] = useState<string>("");
    const [autor, setAutor] = useState<string>("");

    const [registroFotografico, setRegistroFotografico] = useState(null);

    //Select
    const [estado, setEstado] = useState<string>("");
    const [municipio, setMunicipio] = useState<string>("");

    //Date
    const [data, setData] = useState<Date>();

    const [toastExibido, setToastExibido] = useState(false);

    useEffect(() => {
        if (!permissoesUser.noticias_view && !toastExibido) {
            toast.error("Você não tem permissão para visualizar Notícias!");
            setToastExibido(true);
        }
    }, [permissoesUser.noticias_view, toastExibido]);

    const [estadosExistentes, municipiosExistentes, autoresExistentes] = useMemo(() => {
        const estados: string[] = [];
        const municipios: string[] = [];
        const autores: string[] = [];

        datagridRows.forEach((r) => {
            const estado = toUpperArray(treatArray(r.estado))?.filter(
                (v) => v && !estados.includes(v)
            );
            const municipio = capitalizeArray(treatArray(r.municipio))?.filter(
                (v) => v && !municipios.includes(v)
            );
            const autor = capitalize(treatString(r.autor));

            if (estado) estados.push(...estado);
            if (municipio) municipios.push(...municipio);
            if (autor && !autores.includes(autor)) autores.push(autor);
        });

        return [
            estados.sort((a, b) => a.localeCompare(b)),
            municipios.sort((a, b) => a.localeCompare(b)),
            autores.sort((a, b) => a.localeCompare(b)),
        ];
    }, [datagridRows]);

    //filtros
    const [filtroEstado, setFiltroEstado] = useState<string>();
    const [filtroMunicipio, setFiltroMunicipio] = useState<string>();
    const [filtroAutor, setFiltroAutor] = useState<string>();
    const [filtroTitulo, setFiltroTitulo] = useState<string>();
    const [filtroTexto, setFiltroTexto] = useState<string>();
    const [filtroData, setFiltroData] = useState<DateRange>();

    const [showPickerData, setShowPickerData] = useState<string>();

    const tiposFiltro = [
        {
            value: "Data",
            label: t("Data"),
            type: "date",
            filter: filtroData,
            setFilter: setFiltroData,
        },
        {
            value: "Estado",
            label: t("Estado"),
            type: "select",
            opts: estadosExistentes,
            filter: filtroEstado,
            setFilter: setFiltroEstado,
        },
        {
            value: "Municipio",
            label: t("Municipio"),
            type: "select",
            opts: municipiosExistentes,
            filter: filtroMunicipio,
            setFilter: setFiltroMunicipio,
        },
        {
            value: "Autor",
            label: t("Autor"),
            type: "select",
            opts: autoresExistentes,
            filter: filtroAutor,
            setFilter: setFiltroAutor,
        },
        {
            value: "Titulo",
            label: t("Titulo"),
            type: "string",
            filter: filtroTitulo,
            setFilter: setFiltroTitulo,
        },
        {
            value: "Texto",
            label: t("Texto"),
            type: "string",
            filter: filtroTexto,
            setFilter: setFiltroTexto,
        },
    ];

    const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

    const dadosFiltrados = useMemo(
        () =>
            datagridRows.filter(
                (v) =>
                    (!filtroEstado ||
                        v?.estado?.toLowerCase().includes(filtroEstado?.toLowerCase())) &&
                    (!filtroMunicipio ||
                        v.municipio?.toLowerCase().includes(filtroMunicipio?.toLowerCase())) &&
                    (!filtroAutor ||
                        v.estado?.toLowerCase().includes(filtroAutor?.toLowerCase())) &&
                    (!filtroTitulo ||
                        v.status?.toLowerCase().includes(filtroTitulo?.toLowerCase())) &&
                    (!filtroTexto ||
                        v.comunidade?.toLowerCase().includes(filtroTexto?.toLowerCase())) &&
                    (!filtroData ||
                        (v.data >= (filtroData.from || new Date()) &&
                            v.data <= (filtroData.to || new Date())))
            ),
        [
            datagridRows,
            filtroEstado,
            filtroMunicipio,
            filtroData,
            filtroAutor,
            filtroTexto,
            filtroTitulo,
        ]
    );

    useEffect(() => {
        if (!selectedRowData) return;

        setIdNoticia(selectedRowData.id || 0);
        setTitulo(selectedRowData.titulo || "");
        setAssunto(selectedRowData.assunto || "");
        setTexto(selectedRowData.texto || "");
        setAutor(selectedRowData.autor || "");
        setEstado(selectedRowData.estado || "");
        setMunicipio(selectedRowData.municipio || "");
        setData(selectedRowData.data);
    }, [selectedRowData]);

    const handleRowClick = (params: any) => {
        setSelectedRowData(params.row);
        setIsModalOpen(true);
    };

    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
        updateDatagrid();
    };

    const handleOpenModalDelete = () => {
        setShowModalDelete(true);
    };

    const datagridColunas: readonly GridColDef<any>[] = [
        {
            field: "data",
            headerName: t("Data") as string,
            type: "date",
            width: 150,
            valueGetter: ({ row }: { row: any }) => new Date(row.data),
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                return params.value && params.value instanceof Date
                    ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
                    : "";
            },
        },
        {
            field: "estado",
            headerName: t("Estado") as string,
            width: 120,
        },
        {
            field: "municipio",
            headerName: t("Municipio") as string,
            width: 150,
        },
        {
            field: "autor",
            headerName: t("Autor") as string,
            width: 150,
        },
        {
            field: "titulo",
            headerName: t("Titulo") as string,
            width: 200,
        },
        {
            field: "texto",
            headerName: t("Texto") as string,
            width: 200,
        },
        {
            field: "visualizacoes",
            headerName: t("Visualizações") as string,
            width: 150,
        },
    ];

    const AddDia = (dataString: any) => {
        if (!dataString) return null;
        const data = new Date(dataString);
        data.setDate(data.getDate() + 1);
        return data
      };

    const updateDatagrid = useCallback(() => {
        if (!config || !navegar || !t) return;

        Api.GetNoticias(config)
            .then((data: any[]) => {
                setDatagridRows(
                    data
                        .map((d, i) => ({
                            ...d,
                            arrayId: i + 1,
                            data: d.data ? AddDia(d.data) : null,
                        }))
                        .sort((a, b) => (b.data?.valueOf() || 0) - (a.data?.valueOf() || 0))
                );
            })
            .catch((err: any) => {
                if (typeof err === "string") {
                    toast.error(t(err));
                    navegar("/login");
                    return;
                }

                toast.error(t("Houve um erro ao carregar as noticias"));
            });
    }, [config, navegar, t]);

    const updateLocales = useCallback(() => {
        Api.GetLocales().then((data) => {
            setListaEstados(data.localidades.estados);
            setListaMunicipios(data.localidades.municipios);
        });
    }, []);

    useEffect(updateDatagrid, [updateDatagrid]);
    useEffect(updateLocales, [updateLocales]);

    const handleModalFiltros = (filtro: string) => {
        setFiltroAtivo((filtros) => {
            const newFiltros = [...filtros];
            const fIndex = newFiltros.findIndex((f) => f === filtro);
            if (fIndex >= 0) {
                newFiltros.splice(fIndex, 1);
                tiposFiltro.find((f) => f.value === filtro)?.setFilter(undefined);
            } else {
                newFiltros.push(filtro);
            }
            return newFiltros;
        });
        setOpenModalFiltro(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData();

        if (registroFotografico !== null) {
          formData.append("avatar", registroFotografico);
        }

        formData.append("id", String(idNoticia));
        formData.append("titulo", titulo);
        formData.append("texto", texto);
        formData.append("autor", autor);
        if (data) formData.append("data", data?.toISOString());
        formData.append("assunto", assunto);
        formData.append("estado", estado);
        formData.append("municipio", municipio);

        Api.PutNoticia(formData, config)
            .then((res) => {
                updateDatagrid();
                closeModal();
                toast.success("Alterações salvas com sucesso!");
            })
            .catch((err) => {
                toast.error("Não foi possível salvar as alterações!");
            });
    };

    const handleDelete = (event: any) => {
        event.preventDefault();
        Api.DeleteNoticia(selectedRowData.id, config)
            .then((res) => {
                updateDatagrid();
                toast.success("Registro deletado com sucesso!");
                handleCloseModalDelete();
                closeModal();
            })
            .catch((err) => {
                toast.error("Não foi possível deletar o registro!");
            });
    };

    const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
        const handleViewImage = () => {
            const overlayElement = document.createElement("div");
            overlayElement.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      `;

            let imgElement: HTMLImageElement | null = document.createElement("img");

            imgElement.onload = () => {
                if (imgElement && imgElement.complete) {
                    overlayElement.appendChild(imgElement);
                } else {
                    showNoImageMessage();
                }
            };

            imgElement.onerror = () => {
                showNoImageMessage();
            };

            const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
            const linkConcat = `${linkBackend}${formattedImageUrl}`;
            const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
            imgElement.src = linkCompleto;
            imgElement.alt = "Imagem";
            imgElement.style.cssText = `
        max-height: 80vh;
        max-width: 100%;
      `;

            const showNoImageMessage = () => {
                const noImageMessage = document.createElement("div");
                noImageMessage.textContent = "Nenhuma imagem disponível";
                noImageMessage.style.cssText = `
          color: white;
          font-size: 20px;
        `;

                overlayElement.appendChild(noImageMessage);
            };

            overlayElement.addEventListener("click", () => {
                document.body.removeChild(overlayElement);
            });

            document.body.appendChild(overlayElement);
        };

        return (
            <Col sm={12}>
                <Col sm={6}>
                    <button onClick={handleViewImage} className="botao-grid" type="button">
                        {t("Visualizar foto")}
                    </button>
                </Col>
            </Col>
        );
    };

    const getRangeText = (range: DateRange): string => {
        if (!range?.from) return "";

        return (
            format(range.from, "dd/MM/yyyy", { locale: ptBR }) +
            " à " +
            format(range.to || range.from, "dd/MM/yyyy", { locale: ptBR })
        );
    };

    return (
        <Col sm={12}>
            <div className="grid-dados">
                <Col sm={12}>
                    <Row>
                        <Col sm={9}>
                            {filtrosAtivos.map((filtro, i) => {
                                const filterObj = tiposFiltro.find(({ value }) => value === filtro);
                                if (!filterObj) return null;

                                const { filter, setFilter, type, opts } = filterObj;

                                return (
                                    <Col className="filtro-ativo" key={i}>
                                        <Row>
                                            <Col sm={9}>
                                                {type === "date" ? (
                                                    <>
                                                        <button
                                                            onClick={() =>
                                                                setShowPickerData(filtro)
                                                            }
                                                            style={{
                                                                border: 0,
                                                                outline: 0,
                                                                backgroundColor: "#0000",
                                                                display: "block",
                                                                width: "100%",
                                                                height: "100%",
                                                                padding: 0,
                                                                margin: 0,
                                                            }}
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={`Filtrar ${filtro}`}
                                                                value={getRangeText(filter as any)}
                                                                style={{ height: "73%" }}
                                                                onFocus={(e) => e.target.blur()}
                                                                readOnly
                                                            />
                                                        </button>
                                                        {showPickerData === filtro && (
                                                            <DateRangeInput
                                                                selected={filter as any}
                                                                onChange={setFilter}
                                                                onFinish={() =>
                                                                    setShowPickerData(undefined)
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : type === "select" ? (
                                                    <Form.Select
                                                        value={filter?.toString() || ""}
                                                        onChange={(e) => {
                                                            setFilter(e.target.value as any);
                                                        }}
                                                        style={{ height: "73%" }}
                                                    >
                                                        <option
                                                            label={`Filtrar ${filtro}`}
                                                            value={""}
                                                        />
                                                        {opts?.map((opt, i) => (
                                                            <option
                                                                key={i}
                                                                label={opt}
                                                                value={opt}
                                                            />
                                                        ))}
                                                    </Form.Select>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Filtrar ${filtro}`}
                                                        value={filter?.toString() || ""}
                                                        onChange={(e) => {
                                                            setFilter(e.target.value as any);
                                                        }}
                                                        style={{ height: "73%" }}
                                                    />
                                                )}
                                            </Col>
                                            <Col sm={3}>
                                                <button
                                                    onClick={() => {
                                                        setFilter(undefined);
                                                    }}
                                                    className="botao-filtro mt-2"
                                                >
                                                    {t("Limpar Filtro")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>
                        <Col sm={3}>
                            <button
                                className="botao-filtro mt-2"
                                onClick={() => setOpenModalFiltro(!openModalFiltro)}
                            >
                                {t("Filtros")}
                            </button>
                        </Col>
                    </Row>

                    {openModalFiltro && (
                        <Col sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <Col sm={12} className="justify-content-center">
                                        <Row>
                                            {tiposFiltro.map((filtro, i) => (
                                                <Col sm={3} key={i}>
                                                    <button
                                                        className={`filtros-modal ${
                                                            filtrosAtivos?.includes(filtro.value)
                                                                ? "ativo"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            handleModalFiltros(filtro.value);
                                                        }}
                                                    >
                                                        {filtro.label}
                                                    </button>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Col>

                <div>
                    {!permissoesUser.noticias_view && (
                        <div>
                            <DataGrid rows={[]} columns={datagridColunas} />
                        </div>
                    )}
                    {permissoesUser.noticias_view && (
                        <DataGrid
                            rows={dadosFiltrados}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    )}
                </div>
                <div className="mt-2">
                    <ButtonExportExcel lista={dadosFiltrados}></ButtonExportExcel>
                </div>
            </div>

            <Modal
                show={isModalOpen}
                onHide={closeModal}
                className="modal-xl"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Detalhes da Notícia")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRowData && (
                        <div id="content-grid">
                            <Col sm={12} className="mb-2">
                                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Label>{t("Titulo")}</Form.Label>

                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    value={titulo}
                                                    onChange={(e) => {
                                                        setTitulo(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label>{t("Assunto")}</Form.Label>

                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    value={assunto}
                                                    onChange={(e) => {
                                                        setAssunto(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label>{t("Autor")}</Form.Label>

                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    value={autor}
                                                    onChange={(e) => {
                                                        setAutor(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Form.Label className="mt-2">{t("Texto")}</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            className="formInput"
                                            value={texto}
                                            onChange={(e) => {
                                                setTexto(e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Col sm={12} className="mt-3 mb-3">
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Label>{t("Estado")}</Form.Label>

                                                <Form.Select
                                                    value={estado}
                                                    onChange={(e) => {
                                                        setEstado(e.target.value);
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t("Selecione o estado")}
                                                    </option>
                                                    {listaEstados?.map((estado: any) => {
                                                        return (
                                                            <option value={estado.sigla}>
                                                                {estado.nome}
                                                            </option>
                                                        );
                                                    })}
                                                </Form.Select>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label>{t("Municipio")}</Form.Label>

                                                <Form.Select
                                                    value={municipio}
                                                    onChange={(e) => {
                                                        setMunicipio(e.target.value);
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t("Selecione o municipio")}
                                                    </option>
                                                    {listaMunicipios[estado]?.map(
                                                        (municipio: any) => {
                                                            return (
                                                                <option value={municipio}>
                                                                    {municipio}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Form.Select>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label>{t("Data")}</Form.Label>

                                                <Form.Control
                                                    className="formInput"
                                                    type={"date"}
                                                    value={
                                                        data
                                                            ? format(data, "yyyy-MM-dd", {
                                                                  locale: ptBR,
                                                              })
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        setData(
                                                            e.target.value
                                                                ? new Date(e.target.value)
                                                                : undefined
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12}>
                                        <Col sm={12} className="labelFotos mt-3 p-2">
                                            <Form.Label>
                                                {t(
                                                    "Foto (Use arquivos .jpg, jpeg, png, e webp com no máximo 2048 kB)"
                                                )}
                                            </Form.Label>
                                        </Col>
                                        <Col sm={6} className="cadastroFotos">
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                name="avatar"
                                                multiple
                                                onChange={(e: any) => {
                                                    setRegistroFotografico(
                                                        e.target.files[0] ? e.target.files[0] : null
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Col>
                                    <Col sm={12}>
                                        <Col sm={6}>
                                            <ExibirImagem imageUrl={selectedRowData.foto} />
                                        </Col>
                                    </Col>
                                    <Col sm={12}>
                                        <Row>
                                            {permissoesUser.noticias_edit ? (
                                                <Col sm={6}>
                                                    <button className="botao-grid" type="submit">
                                                        {t("Salvar")}
                                                    </button>
                                                </Col>
                                            ) : (
                                                <Col sm={6}>
                                                    <button
                                                        className="botao-grid"
                                                        type="button"
                                                        onClick={() =>
                                                            toast.error(
                                                                "Você não tem permissão para editar uma Notícia!"
                                                            )
                                                        }
                                                    >
                                                        {t("Salvar")}
                                                    </button>
                                                </Col>
                                            )}
                                            {/* <Col sm={4}>
                                                <button
                                                    className="botao-grid"
                                                    type="reset"
                                                >
                                                    {t("Limpar")}
                                                </button>
                                            </Col> */}
                                            <Col sm={6}>
                                                {permissoesUser.noticias_edit ? (
                                                    <button
                                                        className="botao-grid"
                                                        type="button"
                                                        onClick={handleOpenModalDelete}
                                                    >
                                                        {t("Excluir")}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="botao-grid"
                                                        type="button"
                                                        onClick={() =>
                                                            toast.error(
                                                                "Você não tem permissão para excluir uma Notícia!"
                                                            )
                                                        }
                                                    >
                                                        {t("Excluir")}
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </Col>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={showModalDelete} onHide={handleCloseModalDelete} centered={true}>
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja excluir o registro ?")}
                            </p>

                            <button className="botao-grid" onClick={handleDelete}>
                                {t("Sim")}
                            </button>
                            <button className="botao-grid" onClick={handleCloseModalDelete}>
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default GridNoticias;
