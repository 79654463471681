import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Api, { linkBackend } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card } from "react-bootstrap";
import { DateRange } from "react-day-picker";
import { BsCircleFill } from "react-icons/bs";
import DateRangeInput from "../../../utils/DateRangeInput";
import { ptBR } from "date-fns/locale";
import { format } from "date-fns";
import treatArray from "../../../utils/treatArray";
import { capitalizeArray, toUpperArray } from "../../../utils/capitalize";
import ButtonExportExcel from "../../../components/buttonExportListExcel";
import Select from "react-select";

const UNDEFINED_COLOR = process.env.REACT_APP_UNDEFINED_COLOR;
const RED_COLOR = process.env.REACT_APP_RED_COLOR;
const GREEN_COLOR = process.env.REACT_APP_GREEN_COLOR;

type SetFilterFunction<T> = React.Dispatch<React.SetStateAction<T | undefined>>;
interface Filtro {
  value: string;
  label: string;
  type: string;
  opts?: any;
  filter: any;
  setFilter: SetFilterFunction<any>;
}

function GridVisitas({
  config,
  permissoesUser,
}: {
  config: any;
  permissoesUser: {
    administrador: boolean;
    atendimentos_create: boolean;
    atendimentos_edit: boolean;
    atendimentos_view: boolean;
    demandas_create: boolean;
    demandas_edit: boolean;
    demandas_view: boolean;
    engajamentos_create: boolean;
    engajamentos_edit: boolean;
    engajamentos_view: boolean;
    id: number;
    id_user: number;
    noticias_create: boolean;
    noticias_edit: boolean;
    noticias_view: boolean;
    notificacoes_create: boolean;
    notificacoes_edit: boolean;
    notificacoes_view: boolean;
    stakeholders_create: boolean;
    stakeholders_edit: boolean;
    stakeholders_view: boolean;
    usuario_create: boolean;
    usuario_edit: boolean;
    visitas_create: boolean;
    visitas_edit: boolean;
    visitas_view: boolean;
    sms_view: boolean;
    sms_create: boolean;
    solicitacoes_view: boolean;
    solicitacoes_edit: boolean;
  };
}) {
  const { t } = useTranslation();
  const navegar = useNavigate();

  const [datagridRows, setDatagridRows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
  const [openModalFiltro, setOpenModalFiltro] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [idVisita, setIdVisita] = useState<number>(0);

  const [listaEstados, setListaEstados] = useState<any>([]);
  const [listaMunicipios, setListaMunicipios] = useState<any>([]);

  //input
  const [stakeholder, setStakeholder] = useState<string>("");
  const [contato, setContato] = useState<string>("");
  const [localidade, setLocalidade] = useState<string>("");
  const [resumo, setResumo] = useState<string>("");

  const [registroFotografico, setRegistroFotografico] = useState(null);

  //Select
  const [estado, setEstado] = useState<string>("");
  const [municipio, setMunicipio] = useState<string>("");
  const [classificacao, setClassificacao] = useState<string>("");
  const [climaDaVisita, setClimaDaVisita] = useState<string>("");

  //Switch
  const [incidentePotencial, setIncidentePotencial] = useState<boolean>(false);

  //InputDate
  const [data, setData] = useState<Date>();

  const [toastExibido, setToastExibido] = useState(false);

  useEffect(() => {
    if (!permissoesUser.visitas_view && !toastExibido) {
      toast.error("Você não tem permissão para visualizar Visitas!");
      setToastExibido(true);
    }
  }, [permissoesUser.visitas_view, toastExibido]);

  const [
    estadosExistentes,
    municipiosExistentes,
    localidadesExistentes,
    stakeholdersExistentes,
    classificacaoExistenes,
    climaExistentes,
  ] = useMemo(() => {
    const estados: string[] = [];
    const municipios: string[] = [];
    const localidades: string[] = [];
    const stakeholders: string[] = [];
    const classificacoes: string[] = [];
    const climas: string[] = [];

    datagridRows.forEach((r) => {
      const estado = toUpperArray(treatArray(r.estado))?.filter(
        (v) => v && !estados.includes(v)
      );
      const municipio = capitalizeArray(treatArray(r.municipio))?.filter(
        (v) => v && !municipios.includes(v)
      );
      const localidade = capitalizeArray(treatArray(r.localidade))?.filter(
        (v) => v && !localidades.includes(v)
      );
      const stakeholder = capitalizeArray(treatArray(r.stakeholder))?.filter(
        (v) => v && !stakeholders.includes(v)
      );
      const classificacao = capitalizeArray(treatArray(r.classificacao))?.filter(
        (v) => v && !classificacoes.includes(v)
      );
      const clima = capitalizeArray(treatArray(r.clima_da_visita))?.filter(
        (v) => v && !climas.includes(v)
      );

      if (estado) estados.push(...estado);
      if (municipio) municipios.push(...municipio);
      if (localidade) localidades.push(...localidade);
      if (stakeholder) stakeholders.push(...stakeholder);
      if (classificacao) classificacoes.push(...classificacao);
      if (clima) climas.push(...clima);
    });

    return [
      estados.sort((a, b) => a.localeCompare(b)),
      municipios.sort((a, b) => a.localeCompare(b)),
      localidades.sort((a, b) => a.localeCompare(b)),
      stakeholders.sort((a, b) => a.localeCompare(b)),
      classificacoes.sort((a, b) => a.localeCompare(b)),
      climas.sort((a, b) => a.localeCompare(b)),
      
    ];
  }, [datagridRows]);

  const [filtroStakeholder, setFiltroStakeholder] = useState<string>();
  const [filtroLocalidade, setFiltroLocalidade] = useState<string>();
  const [filtroEstado, setFiltroEstado] = useState<string>();
  const [filtroContato, setFiltroContato] = useState<string>();
  const [filtroData, setFiltroData] = useState<DateRange>();
  const [filtroClassificacao, setFiltroClassificacao] = useState<string>();
  const [filtroClima, setFiltroClima] = useState<string>();
  const [filtroSelectedMunicipios, setFiltroSelectedMunicipios] = useState<string[] | undefined>(undefined);

  const [showPickerData, setShowPickerData] = useState<string>();

  const tiposFiltro: Filtro[] = [
    {
      value: "Stakeholder",
      label: t("Stakeholder"),
      type: "select",
      opts: stakeholdersExistentes,
      filter: filtroStakeholder,
      setFilter: setFiltroStakeholder,
    },
    {
      value: "Estado",
      label: t("Estado"),
      type: "select",
      opts: estadosExistentes,
      filter: filtroEstado,
      setFilter: setFiltroEstado,
    },
    {
      value: "Municipio",
      label: t("Municipio"),
      type: "municipio",
      opts: municipiosExistentes,
      filter: filtroSelectedMunicipios,
      setFilter: setFiltroSelectedMunicipios,
    },
    {
      value: "Localidade",
      label: t("Localidade"),
      type: "select",
      opts: localidadesExistentes,
      filter: filtroLocalidade,
      setFilter: setFiltroLocalidade,
    },
    {
      value: "Contato",
      label: t("Contato"),
      type: "string",
      filter: filtroContato,
      setFilter: setFiltroContato,
    },
    {
      value: "Data",
      label: t("Data"),
      type: "date",
      filter: filtroData,
      setFilter: setFiltroData,
    },
    {
      value: "Classificação",
      label: t("Classificação"),
      type: "select",
      opts: classificacaoExistenes,
      filter: filtroClassificacao,
      setFilter: setFiltroClassificacao,
    },
    {
      value: "Clima da Visita",
      label: t("Clima da Visita"),
      type: "select",
      opts: climaExistentes,
      filter: filtroClima,
      setFilter: setFiltroClima,
    },
  ];

  const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

  const dadosFiltrados = useMemo(
    () =>
      datagridRows.filter(
        (v) =>
          (!filtroStakeholder ||
            v?.stakeholder
              ?.toLowerCase()
              .includes(filtroStakeholder?.toLowerCase())) &&
          (!filtroEstado ||
            v.estado?.toLowerCase().includes(filtroEstado?.toLowerCase())) &&
            (!filtroSelectedMunicipios?.length ||
              filtroSelectedMunicipios.some((municipio) => v.municipio?.toLowerCase() === municipio.toLowerCase())) &&
          (!filtroLocalidade ||
            v.localidade
              ?.toLowerCase()
              .includes(filtroLocalidade?.toLowerCase())) &&
          (!filtroContato ||
            v.contato?.toLowerCase().includes(filtroContato?.toLowerCase())) &&
          (!filtroData ||
            (v.dt_visita >= (filtroData.from || new Date()) &&
              v.dt_visita <= (filtroData.to || new Date()))) &&
          (!filtroClassificacao ||
            v.classificacao
              ?.toLowerCase()
              .includes(filtroClassificacao?.toLowerCase())) &&
          (!filtroClima ||
            v.clima_da_visita
              ?.toLowerCase()
              .includes(filtroClima?.toLowerCase()))
      ),
    [
      datagridRows,
      filtroStakeholder,
      filtroEstado,
      filtroSelectedMunicipios,
      filtroLocalidade,
      filtroContato,
      filtroData,
      filtroClassificacao,
      filtroClima,
    ]
  );

  useEffect(() => {
    if (!selectedRowData) return;

    setIdVisita(selectedRowData.id || 0);
    setStakeholder(selectedRowData.stakeholder || "");
    setContato(selectedRowData.contato || "");
    setLocalidade(selectedRowData.localidade || "");
    setResumo(selectedRowData.resumo || "");
    setEstado(selectedRowData.estado || "");
    setMunicipio(selectedRowData.municipio || "");
    setClassificacao(selectedRowData.classificacao || "");
    setClimaDaVisita(selectedRowData.clima_da_visita || "");
    setIncidentePotencial(!!selectedRowData.incidente_potencial);
    setData(selectedRowData.dt_visita);
  }, [selectedRowData]);

  const handleRowClick = (params: any) => {
    setSelectedRowData(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
    updateDatagrid();
  };

  const handleOpenModalDelete = () => {
    setShowModalDelete(true);
  };

  const datagridColunas: readonly GridColDef<any>[] = [
    {
      field: "stakeholder",
      headerName: t("Stakeholder") as string,
      width: 150,
    },
    {
      field: "dt_visita",
      headerName: t("Data da Visita") as string,
      width: 150,
      type: "date",
      valueGetter: ({ row }: { row: any }) => new Date(row.dt_visita),
      renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        return params.value && params.value instanceof Date
          ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
          : "";
      },
    },
    {
      field: "estado",
      headerName: t("Estado") as string,
      width: 150,
    },
    {
      field: "municipio",
      headerName: t("Municipio") as string,
      width: 150,
    },
    {
      field: "localidade",
      headerName: t("Localidade") as string,
      width: 150,
    },
    {
      field: "classificacao",
      headerName: t("Classificação") as string,
      width: 150,
    },
    {
      field: "contato",
      headerName: t("Contato") as string,
      width: 150,
    },
    {
      field: "resumo",
      headerName: t("Resumo") as string,
      width: 200,
    },
    {
      field: "clima_da_visita",
      headerName: t("Clima da Visita") as string,
      width: 150,
      renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        const icon =
          params.value === t("Desfavorável")
            ? RED_COLOR
            : params.value === t("Neutro")
            ? UNDEFINED_COLOR
            : params.value === t("Favorável")
            ? GREEN_COLOR
            : UNDEFINED_COLOR;

        return (
          <span>
            <BsCircleFill color={icon} /> {params.value}
          </span>
        );
      },
    },
  ];

  const AddDia = (dataString: any) => {
    if (!dataString) return null;
    const data = new Date(dataString);
    data.setDate(data.getDate() + 1);
    return data
  };

  const updateDatagrid = useCallback(() => {
    if (!config || !navegar || !t) return;

    Api.GetVisitas(config)
      .then((data: any[]) => {
        setDatagridRows(
          data.map((d, i) => ({
            ...d,
            arrayId: i + 1,
            dt_visita: AddDia(d.dt_visita),
          }))
        );
      })
      .catch((err: any) => {
        if (typeof err === "string") {
          toast.error(t(err));
          navegar("/login");
          return;
        }

        toast.error(t("Houve um erro ao carregar as visitas"));
      });
  }, [config, navegar, t]);

  const updateLocales = useCallback(() => {
    Api.GetLocales().then((data) => {
      setListaEstados(data.localidades.estados);
      setListaMunicipios(data.localidades.municipios);
    });
  }, []);

  useEffect(updateDatagrid, [updateDatagrid]);
  useEffect(updateLocales, [updateLocales]);

  const handleModalFiltros = (filtro: string) => {
    setFiltroAtivo((filtros) => {
      const newFiltros = [...filtros];
      const fIndex = newFiltros.findIndex((f) => f === filtro);
      if (fIndex >= 0) {
        newFiltros.splice(fIndex, 1);
        tiposFiltro.find((f) => f.value === filtro)?.setFilter(undefined);
      } else {
        newFiltros.push(filtro);
      }
      return newFiltros;
    });
    setOpenModalFiltro(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formData = new FormData();

    if (registroFotografico !== null) {
      formData.append("avatar", registroFotografico);
    }

    formData.append("id", String(idVisita));
    formData.append("estado", estado);
    formData.append("municipio", municipio);
    formData.append("localidade", localidade);
    formData.append("climaDaVisita", climaDaVisita);
    formData.append("incidentePotencial", String(incidentePotencial));
    formData.append("stakeholder", stakeholder);
    formData.append("contato", contato);
    formData.append("resumo", resumo);
    formData.append("classificacao", classificacao);
    if (data) formData.append("dataVisita", data?.toISOString());

    await Api.PutVisita(formData, config)
      .then(() => {
        closeModal();
        updateDatagrid();
        toast.success("Alterações salvas com sucesso!");
      })
      .catch((err) => {
        toast.error("Não foi possível salvar as alterações!");
      });
  };

  const handleDelete = (event: any) => {
    event.preventDefault();
    Api.DeleteVisita(selectedRowData.id, config)
      .then((res) => {
        updateDatagrid();
        handleCloseModalDelete();
        closeModal();
        toast.success("Registro deletado com sucesso!");
      })
      .catch((err) => {
        toast.error("Não foi possível deletar o registro!");
      });
  };

  const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
    const handleViewImage = () => {
      const overlayElement = document.createElement("div");
      overlayElement.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      `;

      let imgElement: HTMLImageElement | null = document.createElement("img");

      imgElement.onload = () => {
        if (imgElement && imgElement.complete) {
          overlayElement.appendChild(imgElement);
        } else {
          showNoImageMessage();
        }
      };

      imgElement.onerror = () => {
        showNoImageMessage();
      };

      const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
      const linkConcat = `${linkBackend}${formattedImageUrl}`;
      const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
      imgElement.src = linkCompleto;
      imgElement.alt = "Imagem";
      imgElement.style.cssText = `
              max-height: 80vh;
              max-width: 100%;
            `;

      const showNoImageMessage = () => {
        const noImageMessage = document.createElement("div");
        noImageMessage.textContent = "Nenhuma imagem disponível";
        noImageMessage.style.cssText = `
          color: white;
          font-size: 20px;
        `;

        overlayElement.appendChild(noImageMessage);
      };

      overlayElement.addEventListener("click", () => {
        document.body.removeChild(overlayElement);
      });

      document.body.appendChild(overlayElement);
    };

    return (
      <Col sm={12}>
        <Col sm={6}>
          <button
            onClick={handleViewImage}
            className="botao-grid"
            type="button"
          >
            {t("Visualizar foto")}
          </button>
        </Col>
      </Col>
    );
  };

  const getRangeText = (range: DateRange): string => {
    if (!range?.from) return "";

    return (
      format(range.from, "dd/MM/yyyy", { locale: ptBR }) +
      " à " +
      format(range.to || range.from, "dd/MM/yyyy", { locale: ptBR })
    );
  };

  return (
    <Col sm={12}>
      <div className="grid-dados">
        <Col sm={12}>
          <Row>
            <Col sm={9}>
              {filtrosAtivos.map((filtro, i) => {
                const filterObj = tiposFiltro.find(
                  ({ value }) => value === filtro
                );
                if (!filterObj) return null;

                const { filter, setFilter, type, opts } = filterObj;

                return (
                  <Col className="filtro-ativo" key={i}>
                    <Row>
                      <Col sm={9}>
                        {type === "date" ? (
                          <>
                            <button
                              onClick={() => setShowPickerData(filtro)}
                              style={{
                                border: 0,
                                outline: 0,
                                backgroundColor: "#0000",
                                display: "block",
                                width: "100%",
                                height: "100%",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              <Form.Control
                                type="text"
                                placeholder={`Filtrar ${filtro}`}
                                value={getRangeText(filter as any)}
                                style={{ height: "73%" }}
                                onFocus={(e) => e.target.blur()}
                                readOnly
                              />
                            </button>
                            {showPickerData === filtro && (
                              <DateRangeInput
                                selected={filter as any}
                                onChange={setFilter}
                                onFinish={() => setShowPickerData(undefined)}
                              />
                            )}
                          </>
                        ) : type === "select" ? (
                          <Form.Select
                            value={filter?.toString() || ""}
                            onChange={(e) => {
                              setFilter(e.target.value as any);
                            }}
                            style={{ height: "73%" }}
                          >
                            <option label={`Filtrar ${filtro}`} value={""} />
                            {opts?.map((opt: any, i: any) => (
                              <option key={i} label={opt} value={opt} />
                            ))}
                          </Form.Select>

                          ) : type === "municipio" ? (
                            <Select
                            className="mb-3"
                            value={filtroSelectedMunicipios?.map((municipio) => ({ label: municipio, value: municipio }))}
                            onChange={(selectedOptions) => {
                              const selectedMunicipios = selectedOptions?.map((option) => option.value) || [];
                              setFilter(selectedMunicipios);
                            }}
                            isMulti
                            options={opts?.map((opt: any) => {
                              const isString = typeof opt === "string";
                              return {
                                label: isString ? opt : opt.label,
                                value: isString ? opt : opt.value,
                              };
                            })}
                            placeholder="Selecione Município"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                height: "100%",
                                minHeight: "100%",
                                width: '135%',
                              }),
                            }}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            placeholder={`Filtrar ${filtro}`}
                            value={filter?.toString() || ""}
                            onChange={(e) => {
                              setFilter(e.target.value as any);
                            }}
                            style={{ height: "73%" }}
                          />
                        )}
                      </Col>
                      { type !== "municipio" && (
                      <Col sm={3}>
                        <button
                          onClick={() => {
                            setFilter(undefined);
                          }}
                          className="botao-filtro mt-2"
                        >
                          {t("Limpar Filtro")}
                        </button>
                      </Col>
                      )}
                    </Row>
                  </Col>
                );
              })}
            </Col>
            <Col sm={3}>
              <button
                className="botao-filtro mt-2"
                onClick={() => setOpenModalFiltro(!openModalFiltro)}
              >
                {t("Filtros")}
              </button>
            </Col>
          </Row>

          {openModalFiltro && (
            <Col sm={12} className="mb-2">
              <Card>
                <Card.Body>
                  <Col sm={12} className="justify-content-center">
                    <Row>
                      {tiposFiltro.map((filtro, i) => (
                        <Col sm={3} key={i}>
                          <button
                            className={`filtros-modal ${
                              filtrosAtivos?.includes(filtro.value)
                                ? "ativo"
                                : ""
                            }`}
                            onClick={() => {
                              handleModalFiltros(filtro.value);
                            }}
                          >
                            {filtro.label}
                          </button>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Col>

        <div>
          {!permissoesUser.visitas_view && (
            <div>
              <DataGrid rows={[]} columns={datagridColunas} />
            </div>
          )}
          {permissoesUser.visitas_view && (
            <DataGrid
              rows={dadosFiltrados}
              columns={datagridColunas}
              onRowClick={handleRowClick}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
            />
          )}
        </div>

        <div className="mt-2">
          <ButtonExportExcel lista={dadosFiltrados}></ButtonExportExcel>
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        className="modal-xl"
        dialogClassName="modal-rollover"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Detalhes da Visita")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRowData && (
            <div id="content-grid">
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <Col sm={12} className="mb-2">
                  <Col sm={12}>
                    <Row>
                      <Col sm={4}>
                        <Form.Label>{t("Classificação")}</Form.Label>
                        <Form.Select
                          value={classificacao}
                          onChange={(event) => {
                            setClassificacao(event.target.value);
                          }}
                        >
                          <option value="">{t("Classificação")}</option>
                          <option value="Institucional">
                            {t("Institucional")}
                          </option>
                          <option value="Domiciliar">{t("Domiciliar")}</option>
                        </Form.Select>
                      </Col>
                      <Col sm={4}>
                        <Form.Label>{t("Clima da visita")}</Form.Label>
                        <Form.Select
                          className="formSelect"
                          value={climaDaVisita}
                          onChange={(event) => {
                            setClimaDaVisita(event.target.value);
                          }}
                        >
                          <option value="">{t("Clima da visita")}</option>
                          <option value="Favorável">{t("Favorável")}</option>
                          <option value="Neutro">{t("Neutro")}</option>
                          <option value="Desfavorável">
                            {t("Desfavorável")}
                          </option>
                        </Form.Select>
                      </Col>
                      <Col sm={4}>
                        <Form.Label>Stakeholders</Form.Label>
                        <Form.Control
                          className="formInput"
                          type={"text"}
                          required
                          value={stakeholder}
                          onChange={(e) => {
                            setStakeholder(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <Row>
                      <Col sm={4}>
                        <Form.Label>{t("Estado")}</Form.Label>
                        <Form.Select
                          required
                          value={estado}
                          onChange={(e) => {
                            setEstado(e.target.value);
                          }}
                        >
                          <option value={""}>{t("Selecione o estado")}</option>
                          {listaEstados?.map((estado: any) => {
                            return (
                              <option value={estado.sigla}>
                                {estado.nome}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                      <Col sm={4}>
                        <Form.Label>{t("Municipio")}</Form.Label>
                        <Form.Select
                          required
                          value={municipio}
                          onChange={(e) => {
                            setMunicipio(e.target.value);
                          }}
                        >
                          <option value={""}>
                            {t("Selecione o municipio")}
                          </option>
                          {listaMunicipios[estado]?.map((municipio: any) => {
                            return (
                              <option value={municipio}>{municipio}</option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                      <Col sm={4}>
                        <Form.Label>{t("Localidade")}</Form.Label>
                        <Form.Control
                          className="formInput"
                          type={"text"}
                          value={localidade}
                          onChange={(e) => {
                            setLocalidade(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="mt-3 mb-3">
                    <Row>
                      <Col sm={3}>
                        <Form.Label>{t("Contato")}</Form.Label>
                        <Form.Control
                          className="formInput"
                          type={"text"}
                          value={contato}
                          onChange={(e) => {
                            setContato(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Data")}</Form.Label>
                        <Form.Control
                          className="formInput"
                          type={"date"}
                          value={
                            data
                              ? format(data, "yyyy-MM-dd", {
                                  locale: ptBR,
                                })
                              : ""
                          }
                          onChange={(e) => {
                            setData(
                              e.target.value
                                ? new Date(e.target.value)
                                : undefined
                            );
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <Row>
                          <Col sm={6}>
                            <Form.Label>{t("Incidente Potencial?")}</Form.Label>
                            <Form.Check
                              type="switch"
                              checked={incidentePotencial}
                              onChange={(e) => {
                                setIncidentePotencial(e.target.checked);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="mt-3 mb-3">
                    <Form.Label>{t("Resumo")}</Form.Label>
                    <Form.Control
                      className="formInput"
                      type={"text"}
                      as="textarea"
                      required
                      value={resumo}
                      onChange={(e) => {
                        setResumo(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={6} className="labelFotos mt-3 p-2">
                    <Col sm={12} className="labelFotos p-2">
                      <Form.Label>
                        {t(
                          "Foto (Use arquivos .jpg, jpeg, png, e webp com no máximo 2048 kB)"
                        )}
                      </Form.Label>
                    </Col>
                  </Col>
                  <Col sm={6} className="cadastroFotos">
                    <Form.Control
                      type="file"
                      accept="image/*"
                      name="avatar"
                      multiple
                      onChange={(e: any) => {
                        setRegistroFotografico(
                          e.target.files[0] ? e.target.files[0] : null
                        );
                      }}
                    />
                  </Col>
                  <Col sm={12}>
                    <Col sm={6}>
                      <ExibirImagem imageUrl={selectedRowData.foto} />
                    </Col>
                  </Col>
                  <Col sm={12}>
                    <Row>
                      <Col sm={6}>
                        {permissoesUser.visitas_edit ? (
                          <button className="botao-grid" type="submit">
                            {t("Salvar")}
                          </button>
                        ) : (
                          <button
                            className="botao-grid"
                            type="button"
                            onClick={() =>
                              toast.error(
                                "Você não tem permissão para editar uma Visita!"
                              )
                            }
                          >
                            {t("Salvar")}
                          </button>
                        )}
                      </Col>
                      {/* <Col sm={4}>
                        <button className="botao-grid" type="reset">
                          {t("Limpar")}
                        </button>
                      </Col> */}
                      <Col sm={6}>
                        {permissoesUser.visitas_edit ? (
                          <button
                            className="botao-grid"
                            type="button"
                            onClick={handleOpenModalDelete}
                          >
                            {t("Excluir")}
                          </button>
                        ) : (
                          <button
                            className="botao-grid"
                            type="button"
                            onClick={() =>
                              toast.error(
                                "Você não tem permissão para excluir uma Visita!"
                              )
                            }
                          >
                            {t("Excluir")}
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Form>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalDelete}
        onHide={handleCloseModalDelete}
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div id="modalLogin">
            <form className="form">
              <p className="titleForgotPassword">
                {t("Deseja excluir o registro ?")}
              </p>

              <button className="botao-grid" onClick={handleDelete}>
                {t("Sim")}
              </button>
              <button className="botao-grid" onClick={handleCloseModalDelete}>
                {t("Não")}
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </Col>
  );
}

export default GridVisitas;
