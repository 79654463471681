import "./styles.css";
import { Col, Form, Row, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Api from "../../config/api";
import { useEffect, useState } from "react";

function Visitas({
  config,
  toggleCard,
  permissoesUser,
}: {
  config: any;
  toggleCard: any;
  permissoesUser: {
    administrador: boolean;
    atendimentos_create: boolean;
    atendimentos_edit: boolean;
    atendimentos_view: boolean;
    demandas_create: boolean;
    demandas_edit: boolean;
    demandas_view: boolean;
    engajamentos_create: boolean;
    engajamentos_edit: boolean;
    engajamentos_view: boolean;
    id: number;
    id_user: number;
    noticias_create: boolean;
    noticias_edit: boolean;
    noticias_view: boolean;
    notificacoes_create: boolean;
    notificacoes_edit: boolean;
    notificacoes_view: boolean;
    stakeholders_create: boolean;
    stakeholders_edit: boolean;
    stakeholders_view: boolean;
    usuario_create: boolean;
    usuario_edit: boolean;
    visitas_create: boolean;
    visitas_edit: boolean;
    visitas_view: boolean;
    sms_view: boolean;
    sms_create: boolean;
    solicitacoes_view: boolean;
    solicitacoes_edit: boolean;
  };
}) {
  const { t } = useTranslation();

  const [showModalRegister, setShowModalRegister] = useState<boolean>(false);

  //input
  const [stakeholder, setStakeholder] = useState<string>("");
  const [contato, setContato] = useState<string>("");
  const [localidade, setLocalidade] = useState<string>("");
  const [resumo, setResumo] = useState<string>("");

  const [registroFotografico, setRegistroFotografico] = useState(null);

  //Select
  const [estado, setEstado] = useState<string>("");
  const [municipio, setMunicipio] = useState<string>("");
  const [classificacao, setClassificacao] = useState<string>("");
  const [climaDaVisita, setClimaDaVisita] = useState<string>("");

  const [listaEstados, setListaEstados] = useState<any>([]);
  const [listaMunicipios, setListaMunicipios] = useState<any>([]);

  //Switch
  const [incidentePotencial, setIncidentePotencial] = useState<boolean>(false);
  const [salvarComoStakeHolder, setSalvarComoStakeHolder] =
    useState<boolean>(false);

  //InputDate
  const [dataVisita, setDataVisita] = useState<string>("");

  const [toastExibido, setToastExibido] = useState(false);

  useEffect(() => {
    if (!permissoesUser.visitas_create && !toastExibido) {
      toast.error("Você não tem permissão para criar uma nova Visita!");
      setToastExibido(true);
    }
  }, [permissoesUser.visitas_create, toastExibido]);

  useEffect(() => {
    Api.GetLocales().then((data) => {
      setListaEstados(data.localidades.estados);
      setListaMunicipios(data.localidades.municipios);
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const obj = {
      stakeholder: stakeholder,
      contato: contato,
      estado: estado,
      municipio: municipio,
    };

    const formData = new FormData();

    if (registroFotografico !== null) {
      formData.append("avatar", registroFotografico);
    }

    formData.append("estado", estado);
    formData.append("municipio", municipio);
    formData.append("localidade", localidade);
    formData.append("climaDaVisita", climaDaVisita);
    formData.append("incidentePotencial", String(incidentePotencial));
    formData.append("stakeholder", stakeholder);
    formData.append("contato", contato);
    formData.append("resumo", resumo);
    formData.append("classificacao", classificacao);
    formData.append("dataVisita", dataVisita);

    Api.PostVisita(formData, config)
      .then((res) => {
        handleCloseModalRegister();
        handleClearFields();
        toast.success(t("Cadastro feito com sucesso!"));
        if (salvarComoStakeHolder) {
          sessionStorage.setItem("stakeholderStorage", JSON.stringify(obj));
          toggleCard(4);
        }
      })
      .catch((err) => {
        toast.error(t("Não foi possível fazer o cadastro!"));
      });
  };

  const handleClearFields = () => {
    setStakeholder("");
    setContato("");
    setLocalidade("");
    setResumo("");
    setEstado("");
    setMunicipio("");
    setClassificacao("");
    setClimaDaVisita("");
    setIncidentePotencial(false);
    setSalvarComoStakeHolder(false);
    setDataVisita("");
    setRegistroFotografico(null);
  };

  const handleCloseModalRegister = () => {
    setShowModalRegister(false);
  };

  const handleOpenModalRegister = () => {
    setShowModalRegister(true);
  };

  return (
    <Col sm={12} id="visitasPage">
      <Form onReset={handleClearFields} encType="multipart/form-data">
        <Col sm={12} className="mt-3">
          <Row>
            <Col sm={6}>
              <Form.Label>{t("Classificação")}</Form.Label>
              <Form.Select
                value={classificacao}
                onChange={(event) => {
                  setClassificacao(event.target.value);
                }}
              >
                <option value="">{t("Classificação")}</option>
                <option value="Institucional">{t("Institucional")}</option>
                <option value="Domiciliar">{t("Domiciliar")}</option>
              </Form.Select>
            </Col>
            <Col sm={6}>
              <Form.Label>{t("Clima da visita")}</Form.Label>
              <Form.Select
                className="formSelect"
                value={climaDaVisita}
                onChange={(event) => {
                  setClimaDaVisita(event.target.value);
                }}
              >
                <option value="">{t("Clima da visita")}</option>
                <option value="Favorável">{t("Favorável")}</option>
                <option value="Neutro">{t("Neutro")}</option>
                <option value="Desfavorável">{t("Desfavorável")}</option>
              </Form.Select>
            </Col>
          </Row>
        </Col>
        <Col sm={12} className="mt-3">
          <Row>
            <Col sm={6}>
              <Form.Label>{t("Estado")}</Form.Label>
              <Form.Select
                required
                value={estado}
                onChange={(e) => {
                  setEstado(e.target.value);
                }}
              >
                <option value={""}>{t("Selecione o estado")}</option>
                {listaEstados?.map((estado: any) => {
                  return <option value={estado.sigla}>{estado.nome}</option>;
                })}
              </Form.Select>
            </Col>
            <Col sm={6}>
              <Form.Label>{t("Municipio")}</Form.Label>
              <Form.Select
                required
                value={municipio}
                onChange={(e) => {
                  setMunicipio(e.target.value);
                }}
              >
                <option value={""}>{t("Selecione o municipio")}</option>
                {listaMunicipios[estado]?.map((municipio: any) => {
                  return <option value={municipio}>{municipio}</option>;
                })}
              </Form.Select>
            </Col>
          </Row>
        </Col>
        <Col sm={12} className="mt-3 mb-3">
          <Row>
            <Col sm={6}>
              <Form.Label>{t("Localidade")}</Form.Label>
              <Form.Control
                className="formInput"
                type={"text"}
                value={localidade}
                onChange={(e) => {
                  setLocalidade(e.target.value);
                }}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Stakeholders</Form.Label>
              <Form.Control
                className="formInput"
                type={"text"}
                required
                value={stakeholder}
                onChange={(e) => {
                  setStakeholder(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} className="mt-3 mb-3">
          <Row>
            <Col sm={6}>
              <Form.Label>{t("Contato")}</Form.Label>
              <Form.Control
                className="formInput"
                type={"text"}
                value={contato}
                onChange={(e) => {
                  setContato(e.target.value);
                }}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>{t("Resumo")}</Form.Label>
              <Form.Control
                className="formInput"
                type={"text"}
                required
                value={resumo}
                onChange={(e) => {
                  setResumo(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} className="mt-3 mb-3">
          <Row>
            <Col sm={6}>
              <Form.Label>{t("Data")}</Form.Label>
              <Form.Control
                className="formInput"
                type={"date"}
                value={dataVisita}
                onChange={(e) => {
                  setDataVisita(e.target.value);
                }}
              />
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Incidente Potencial?")}</Form.Label>
                  <Form.Check
                    type="switch"
                    checked={incidentePotencial}
                    onChange={(e) => {
                      setIncidentePotencial(e.target.checked);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>{t("Salvar com Stakeholder?")}</Form.Label>
                  <Form.Check
                    type="switch"
                    checked={salvarComoStakeHolder}
                    onChange={(e) => {
                      setSalvarComoStakeHolder(e.target.checked);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col sm={12} className="mt-4">
          <Col sm={12} className="labelFotos p-2">
            <Form.Label>
              {t(
                "Foto (Use arquivos .jpg, jpeg, png, e webp com no máximo 2048 kB)"
              )}
            </Form.Label>
          </Col>
          <Col sm={6} className="cadastroFotos">
            <Form.Control
              type="file"
              accept="image/*"
              name="avatar"
              multiple
              onChange={(e: any) => {
                setRegistroFotografico(
                  e.target.files[0] ? e.target.files[0] : null
                );
              }}
            />
          </Col>
        </Col>
        <Col sm={12}>
          <Row>
            <Col sm={6}>
              {permissoesUser.visitas_create ? (
                <button
                  className="botao-grid"
                  type="button"
                  onClick={handleOpenModalRegister}
                >
                  {t("Registrar")}
                </button>
              ) : (
                <button
                  className="botao-grid"
                  type="button"
                  onClick={() =>
                    toast.error(
                      "Você não tem permissão para criar uma nova Visita!"
                    )
                  }
                >
                  {t("Registrar")}
                </button>
              )}
            </Col>
            <Col sm={6}>
              <button className="botao-visita" type="reset">
                {t("Limpar")}
              </button>
            </Col>
          </Row>
        </Col>
      </Form>
      <Modal
        show={showModalRegister}
        onHide={handleCloseModalRegister}
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div id="modalLogin">
            <form className="form">
              <p className="titleForgotPassword">
                {t("Deseja enviar o cadastro?")}
              </p>

              <button className="botao-grid" onClick={handleSubmit}>
                {t("Sim")}
              </button>
              <button
                className="botao-grid"
                type="button"
                onClick={handleCloseModalRegister}
              >
                {t("Não")}
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </Col>
  );
}

export default Visitas;
