import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { MdOutlineKeyboardReturn } from 'react-icons/md';

const BotaoVoltar: React.FC = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleVoltar = () => {
    navigate(-1);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Button 
      onClick={handleVoltar}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      variant="primary" 
      style={{ 
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "90px", 
        height: "25px", 
        borderRadius: "10px",
        padding: "5px 10px",
        backgroundColor: isHovered ? "#003366" : "#165788",
        borderColor: "#003366",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        transform: "translateY(-2px)",
        cursor: "pointer"
      }}
    >
      <MdOutlineKeyboardReturn style={{ marginRight: "5px" }} />
      Voltar
    </Button>
  );
};

export default BotaoVoltar;
