import moment from "moment";
import "./App.css";
import RouterManager from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

window.moment = moment;

function App() {
    return (
        <div className="App">
            <RouterManager />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ zIndex: 999999999 }}
            />
        </div>
    );
}

export default App;
