import React, { useEffect, useState } from "react";
import "./styles.css";
import {
    Modal,
    Card,
    Col,
    Row,
    Button,
    Image,
    Carousel,
} from "react-bootstrap";
import { BiChevronDown, BiChevronUp, BiCheck } from "react-icons/bi";
import ImagemCard1 from "../../../assets/images/card1.png";
import ImagemCard from "../../../assets/images/PersonCashier.png";
import Api from "../../../config/api";
import { useTranslation } from "react-i18next";
import DemandaExterna from "../demandaExterna";
import DemandaInterna from "../demandaInterna";
import GridEngajamento from "../../grids/engajamento";
import GridStakeholdersRI from "../../grids/stakeholders/GridStakeholders.RI";
import GridDemandaExterna from "../grids/demandaExterna";
import GridDemandaInterna from "../grids/demandaInterna";
import GridOficios from "../grids/oficios";
import { toast } from "react-toastify";
import StakeholdersRI from "../../stakeholders/stakeholders.RI";
import Engajamentos from "../../engajamentos";
import Oficios from "../oficios";
import BotaoVoltar from "../../../components/buttonReturnPage";

interface CardItemProps {
    option: string;
    value: number;
}

function CadastroRelacionamento() {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [activeCard, setActiveCard] = useState<number | null>(null);
    const [activeGridCard, setActiveGridCard] = useState<number | null>(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showModalGrid, setShowModalGrid] = useState<boolean>(false);
    const [listaEstados, setListaEstados] = useState<any>([]);
    const [listaMunicipios, setListaMunicipios] = useState<any>([]);
    const [listaStakeholders, setListaStakeholders] = useState<any>([]);
    const [carouselData, setCarouselData] = useState<any[]>([]);
    const [permissoesUser, setPermissoesUser] = useState({
        administrador: false,
        atendimentos_create: false,
        atendimentos_edit: false,
        atendimentos_view: false,
        demandas_create: false,
        demandas_edit: false,
        demandas_view: false,
        engajamentos_create: false,
        engajamentos_edit: false,
        engajamentos_view: false,
        id: 0,
        id_user: 0,
        noticias_create: false,
        noticias_edit: false,
        noticias_view: false,
        notificacoes_create: false,
        notificacoes_edit: false,
        notificacoes_view: false,
        stakeholders_create: false,
        stakeholders_edit: false,
        stakeholders_view: false,
        usuario_create: false,
        usuario_edit: false,
        visitas_create: false,
        visitas_edit: false,
        visitas_view: false,
        sms_view: false,
        sms_create: false,
        solicitacoes_view: false,
        solicitacoes_edit: false,
    });

  const toggleCard = (index: number) => {
    if (index === activeCard) {
      setActiveCard(null);
    } else {
      setActiveCard(index);
    }
  };

    const tokenUsuario = sessionStorage.getItem("tokenUsuario");
    let config = { headers: { authorization: tokenUsuario } };

    useEffect(() => {
        const permissionsUsuario = sessionStorage.getItem("permissoesUsuario");
        if (permissionsUsuario) {
            setPermissoesUser(JSON.parse(permissionsUsuario));
        }
    }, []);

    useEffect(() => {
        const getApi = async () => {
            await Api.GetLocales().then((data) => {
                setListaEstados(data.localidades.estados);
                setListaMunicipios(data.localidades.municipios);
            });

            await Api.GetStakeholders(config).then((data) => {
                setListaStakeholders(data);
            });
        };

        getApi();
    }, []);

    const cardDataGrid = [
        { title: t("Engajamentos"), content: "Formulário do Card 1" },
        { title: t("Stakeholders"), content: "Formulário do Card 2" },
        { title: t("Demanda Externa"), content: "Formulário do Card 3" },
        { title: t("Demanda Interna"), content: "Formulário do Card 4" },
        { title: t("Ofícios"), content: "Formulário do Card 5" },
    ];

  const cardDataCadastro = [
    {
      title: t("Demanda Externa"),
      content: "Formulário do Card 1",
      Element: DemandaExterna,
    },
    {
      title: t("Demanda Interna"),
      content: "Formulário do Card 2",
      Element: DemandaInterna,
    },
    {
      title: t("Engajamento"),
      content: "Formulário do Card 3",
      Element: Engajamentos,
    },
    {
      title: t("Stakeholders"),
      content: "Formulário do Card 4",
      Element: StakeholdersRI,
    },
    {
      title: t("Ofícios"),
      content: "Formulário do Card 5",
      Element: Oficios,
    },
  ];

    const dadosCadastrados = () => {
        Api.GetContadoresInstitucional(config)
            .then((data: any[]) => {
                const dados = data.map((item) => ({
                    title: t(item.titulo),
                    content: Object.entries(item.resultados).map(
                        ([key, value]) => ({
                            option: t(key),
                            value,
                        })
                    ),
                }));

                setCarouselData(dados);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        dadosCadastrados();
    }, []);

    const CardItem: React.FC<CardItemProps> = ({ option, value }) => (
        <Card className="card-numeros mt-2">
            <Card.Body className="style-card-numeros">
                <Row>
                    <Col sm={9} className="col-sm-9 col-8">
                        <Card.Title
                            style={{
                                fontWeight: 300,
                                textTransform: "capitalize",
                            }}
                        >
                            {t(option)}
                        </Card.Title>
                    </Col>
                    <Col sm={3} className="col-sm-3 col-4">
                        <Card.Text className="text-end valores-card">{value}</Card.Text>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleSubmit = () => {
        handleCloseModal();
    };

    const handleCloseModalGrid = () => {
        setShowModalGrid(false);
    };

    const handleOpenModalGrid = () => {
        setActiveGridCard(0);
        setShowModalGrid(true);
    };

  return (
    <div id="baseCard" className="mb-5">
      <Col sm={12} className="p-4">
      <BotaoVoltar />
        <Row>
          <Col sm={3} className="mb-3">
            <Card>
              <Col sm={12} className="d-flex justify-content-center">
                <Image src={ImagemCard1} className="imagem-card-um" />
              </Col>
              {permissoesUser.demandas_view ? (
                <Col sm={12} className="d-flex justify-content-center">
                  <button
                    className="botao-card-table"
                    onClick={handleOpenModalGrid}
                  >
                     {t("Visualizar registros")}
                  </button>
                </Col>
              ) : (
                <Col sm={12} className="d-flex justify-content-center">
                  <button
                    className="botao-card-table"
                    onClick={() =>
                      toast.error(
                        t("Você não tem permissão para visualizar Demandas!")
                      )
                    }
                  >
                     {t("Visualizar registros")}
                  </button>
                </Col>
              )}
            </Card>
          </Col>
          <Col sm={6} className="mb-3">
            <Card>
              <Col sm={12} className="d-flex justify-content-center">
                <Image src={ImagemCard} className="imagem-card" />
              </Col>
              <Col sm={12}>
                <Col sm={12} className="d-flex justify-content-center">
                  <h5>{t("Faça novos cadastros")}</h5>
                </Col>
                <Col sm={12} style={{ paddingLeft: "10%" }}>
                  <BiCheck className="icon-check" />
                  <span>{t("Demanda Externa")}</span>
                </Col>
                <Col sm={12} style={{ paddingLeft: "10%" }}>
                  <BiCheck className="icon-check" />
                  <span>{t("Demanda Interna")}</span>
                </Col>
                <Col sm={12} style={{ paddingLeft: "10%" }}>
                  <BiCheck className="icon-check" />
                  <span>{t("Engajamento")}</span>
                </Col>
                <Col sm={12} style={{ paddingLeft: "10%" }}>
                  <BiCheck className="icon-check" />
                  <span>{t("Stakeholder")}</span>
                </Col>
                <Col sm={12} style={{ paddingLeft: "10%" }}>
                  <BiCheck className="icon-check" />
                  <span>{t("Ofícios")}</span>
                </Col>
              </Col>
              {permissoesUser.demandas_create ? (
                <Col sm={12} className="d-flex justify-content-center">
                  <button className="botao-card" onClick={handleOpenModal}>
                    {t("Novo cadastro")}
                  </button>
                </Col>
              ) : (
                <Col sm={12} className="d-flex justify-content-center">
                  <button
                    className="botao-card"
                    onClick={() =>
                      toast.error(
                        t("Você não tem permissão para criar novas Demandas!")
                      )
                    }
                  >
                    {t("Novo cadastro")}
                  </button>
                </Col>
              )}
            </Card>
          </Col>
          <Col sm={3} className="mb-3">
            <Card className="cards-contadores">
              <Col sm={12} className="d-flex justify-content-center">
                <Carousel className="d-flex justify-content-center mt-3">
                  {carouselData.map((item, index) => (
                    <Carousel.Item key={index}>
                      <h4 style={{ fontWeight: 500 }} className="text-center">
                        {item.title}
                      </h4>
                      <div className="d-flex justify-content-around">
                        <Row>
                          {item.content.map(
                            (
                              data: { option: string; value: number },
                              i: number
                            ) => (
                              <CardItem
                                key={i}
                                option={data.option}
                                value={data.value}
                              />
                            )
                          )}
                        </Row>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Card>
          </Col>
        </Row>
      </Col>

            {/* {MODAL DE VISUALIZAR OS GRIDS} */}
            <Modal
                show={showModalGrid}
                onHide={handleCloseModalGrid}
                centered={true}
                dialogClassName="modal-rollover"
                fullscreen
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Visualizar registros")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-cadastro mb-5">
                    <Col sm={12} className="grids">
                        <Row>
                            {cardDataGrid.map((card, index) => (
                                <Col key={index} sm={10}>
                                    {activeGridCard === index && (
                                        <div>
                                            <hr />
                                            {card.content ===
                                                "Formulário do Card 1" && (
                                                <GridEngajamento
                                                    permissoesUser={
                                                        permissoesUser
                                                    }
                                                    config={config}
                                                />
                                            )}
                                            {card.content ===
                                                "Formulário do Card 2" && (
                                                <GridStakeholdersRI
                                                    permissoesUser={
                                                        permissoesUser
                                                    }
                                                    config={config}
                                                />
                                            )}
                                            {card.content ===
                                                "Formulário do Card 3" && (
                                                <GridDemandaExterna
                                                    config={config}
                                                />
                                            )}
                                            {card.content ===
                                                "Formulário do Card 4" && (
                                                <GridDemandaInterna
                                                    config={config}
                                                />
                                            )}
                                            {card.content ===
                                                "Formulário do Card 5" && (
                                                <GridOficios
                                                />
                                            )}
                                        </div>
                                    )}
                                </Col>
                            ))}
                            <Col sm={2} id="progressCard">
                                <Card
                                    style={{
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                >
                                    {cardDataGrid.map((card, index) => (
                                        <button
                                            key={index}
                                            className={`progressButton ${
                                                index === activeGridCard
                                                    ? "activeCard"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setActiveGridCard(index)
                                            }
                                        >
                                            {card.title}
                                        </button>
                                    ))}
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Body>
            </Modal>

      {/* MODAL DE CADASTRO */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered={true}
        dialogClassName="modal-rollover"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Cadastro")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-cadastro">
          <Col sm={12}>
            <Row>
              <Col
                sm={10}
                className="cards cards-cadastro-mobile-relacionamento"
              >
                {cardDataCadastro.map((card, index) => (
                  <Card key={index} className="card-cadastro-modal">
                    <Card.Body>
                      <Row>
                        <Col className="col-sm-10 col-10">
                          <Card.Title>{card.title}</Card.Title>
                        </Col>
                        <Col className="col-sm-2 col-2 d-flex justify-content-end">
                          <Button
                            onClick={() => toggleCard(index)}
                            className="buttonCard"
                          >
                            {activeCard === index ? (
                              <BiChevronUp className="iconChevron" />
                            ) : (
                              <BiChevronDown className="iconChevron" />
                            )}
                          </Button>
                        </Col>
                      </Row>

                      <div
                        style={activeCard === index ? {} : { display: "none" }}
                      >
                        <hr />

                        <card.Element
                          listaEstados={listaEstados}
                          listaMunicipios={listaMunicipios}
                          listaStakeholders={listaStakeholders}
                          config={config}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                ))}
              </Col>
              <Col sm={2} id="progressCard">
                <Card className="modal-grid-card">
                  {cardDataCadastro.map((card, index) => (
                    <button
                      key={index}
                      className={`progressButton ${
                        index === activeCard ? "activeCard" : ""
                      }`}
                      onClick={() => toggleCard(index)}
                    >
                      {card.title}
                    </button>
                  ))}
                </Card>
              </Col>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CadastroRelacionamento;
