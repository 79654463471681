import "./styles.css";
import { Col, Form, Row, Modal } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Api from "../../config/api";

function StakeholdersRI({
    config,
    // toggleCard,
    listaMunicipios,
    listaEstados,
}: {
    config: any;
    // toggleCard: any;
    listaMunicipios: [string];
    listaEstados: [string];
}) {
    const { t } = useTranslation();

    const [showModalRegister, setShowModalRegister] = useState<boolean>(false);

    // const permissoesUser = await Api.GetPermissoesById( , config)

    let stakeholderPendente = {
        contato: "",
        demandante: "",
        stakeholder: "",
        estado: "",
        municipio: "",
    };
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [funcaoTexto, setFuncaoTexto] = useState<string>("");
    //input
    const [stakeholder, setStakeholder] = useState<string>(
        stakeholderPendente?.demandante || ""
    );
    const [contatoCelular, setContatoCelular] = useState<string>(
        stakeholderPendente?.contato || ""
    );
    const [email, setEmail] = useState<string>("");
    const [endereco, setEndereco] = useState<string>("");

    const [pontosDeInterface, setPontosDeInterface] = useState<string>("");
    const [representacao, setRepresentacao] = useState<string>("");
    const [comentarios, setComentarios] = useState<string>("");

    const [registroFotografico, setRegistroFotografico] = useState(null);
    const [cargo, setCargo] = useState<string>("");
    const [tipo, setTipo] = useState<string>("");
    //Select

    const [esfera, setEsfera] = useState<string>("");
    const [pais, setPais] = useState<string>("");

    const [estado, setEstado] = useState<string>(
        stakeholderPendente?.estado || ""
    );
    const [municipio, setMunicipio] = useState<string>(
        stakeholderPendente?.municipio || ""
    );

    const getStakeholderSessionStorage = async () => {
        let stakeholderStorage = sessionStorage.getItem(
            "stakeholderStorage"
        ) as string;
        stakeholderPendente = JSON.parse(stakeholderStorage);
        if (stakeholderPendente) {
            setStakeholder(
                stakeholderPendente.demandante ||
                    (stakeholderPendente?.stakeholder &&
                        stakeholderPendente.demandante) ||
                    stakeholderPendente?.stakeholder
            );
            setContatoCelular(
                stakeholderPendente.contato && stakeholderPendente.contato
            );
            setEstado(stakeholderPendente.estado && stakeholderPendente.estado);
            setMunicipio(
                stakeholderPendente.municipio && stakeholderPendente.municipio
            );
        }
    };

    useEffect(() => {
        getStakeholderSessionStorage();
    }, [stakeholderPendente]);

    const [funcao, setFuncao] = useState<string>("");
    const [postura, setPostura] = useState<string>("");
    const [influencia, setInfluencia] = useState<string>("");
    const [grauImpacto, setGrauImpacto] = useState<string>("");
    const [criticidade, setCriticidade] = useState<string>("");

    const [listaEstadosStake, setListaEstadosStake] = useState<any>([]);
    const [listaMunicipiosStake, setListaMunicipiosStake] = useState<any>([]);

    const [toastExibido, setToastExibido] = useState(false);

    // useEffect(() => {
    //   if (!permissoesUser.stakeholders_create && !toastExibido) {
    //     toast.error("Você não tem permissão para criar um novo stakeholder!");
    //     setToastExibido(true);
    //   }
    // }, [permissoesUser.stakeholders_create, toastExibido]);

    useEffect(() => {
        Api.GetLocales().then((data) => {
            setListaEstadosStake(data.localidades.estados);
            setListaMunicipiosStake(data.localidades.municipios);
        });
    }, []);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoadingButton(true);

        const formData = new FormData();

        if (registroFotografico !== null) {
            formData.append("avatar", registroFotografico);
        }

        formData.append("esfera", esfera);
        formData.append("pais", pais);
        formData.append("estado", estado);
        formData.append("municipio", municipio);
        formData.append("contato", contatoCelular);
        formData.append("email", email);
        formData.append("endereco", endereco);
        formData.append("interlocutoresMosaic", pontosDeInterface);
        formData.append("stakeholder", stakeholder);
        // formData.append("representacao", representacao);
        formData.append("postura", postura);
        formData.append("influencia", influencia);
        formData.append("criticidade", criticidade);
        formData.append("comentarios", comentarios);
        formData.append("cargo", cargo);
        formData.append("relacionamento", "RI");

        const funcaoGeral = funcao === "outros" ? funcaoTexto : funcao;
        formData.append("categoriaStake", funcaoGeral);
        // tipo && formData.append("tipo", tipo);

        await Api.PostStakeholder(formData, config)
            .then((res) => {
                toast.success(t("Cadastro feito com sucesso!"));
                handleCloseModalRegister();
                if (res) setLoadingButton(false);
                handleClearFields();
            })
            .catch((err) => {
                toast.error(t("Não foi possível fazer o cadastro!"));
                if (err) setLoadingButton(false);
            });
    };

    const handleClearFields = () => {
        setStakeholder("");
        setContatoCelular("");
        setPontosDeInterface("");
        setRepresentacao("");
        setEsfera("");
        setPais("");
        setComentarios("");
        setEstado("");
        setMunicipio("");
        setFuncao("");
        setPostura("");
        setInfluencia("");
        setGrauImpacto("");
        setCriticidade("");
        setTipo("");
        setRegistroFotografico(null);
        setEmail("");
        setEndereco("");
        sessionStorage.removeItem("stakeholderStorage");
    };

    const handleCloseModalRegister = () => {
        setShowModalRegister(false);
    };

    const handleOpenModalRegister = () => {
        setShowModalRegister(true);
    };

    useEffect(() => {
        if (
            (postura === "Favorável" && influencia === "Média") ||
            (postura === "Favorável" && influencia === "Baixo") ||
            (postura === "Neutro" && influencia === "Baixo")
        ) {
            setCriticidade("Baixo");
        } else if (
            (postura === "Neutro" && influencia === "Alto") ||
            (postura === "Neutro" && influencia === "Média") ||
            (postura === "Contrário" && influencia === "Baixo")
        ) {
            setCriticidade("Médio");
        } else if (
            (postura === "Favorável" && influencia === "Alto") ||
            (postura === "Contrário" && influencia === "Alto") ||
            (postura === "Contrário" && influencia === "Média")
        ) {
            setCriticidade("Alto");
        } else {
            setCriticidade("");
        }
    }, [postura, influencia]);

    return (
        <Col sm={12} id="stakeholdersPage">
            <Form onReset={handleClearFields} encType="multipart/form-data">
                <Col sm={12} className="mt-4 mb-4">
                    <Row>
                        <Col sm={6}>
                            <Form.Label>{t("Esfera")}</Form.Label>
                            <Form.Select
                                value={esfera}
                                onChange={(e) => {
                                    setEsfera(e.target.value);
                                }}
                            >
                                <option value={""}>
                                    {t("Selecione a Esfera")}
                                </option>
                                <option value={"Federal"}>
                                    {t("Federal")}
                                </option>
                                <option value={"Estadual"}>
                                    {t("Estadual")}
                                </option>
                                <option value={"Municipal"}>
                                    {t("Municipal")}
                                </option>
                            </Form.Select>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>{t("País")}</Form.Label>
                            <Form.Select
                                value={pais}
                                onChange={(e) => {
                                    setPais(e.target.value);
                                }}
                            >
                                <option value={""}>
                                    {t("Selecione o país")}
                                </option>
                                <option value={"Brasil"}>{t("Brasil")}</option>
                                <option value={"Paraguai"}>
                                    {t("Paraguai")}
                                </option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Col>

                <Col sm={12} className="mt-4 mb-4">
                    <Row>
                        <Col sm={6}>
                            <Form.Label>{t("Estado")}</Form.Label>
                            <Form.Select
                                value={estado}
                                onChange={(e) => {
                                    setEstado(e.target.value);
                                }}
                            >
                                <option value={""}>
                                    {t("Selecione o estado")}
                                </option>
                                {listaEstadosStake?.map((estado: any) => {
                                    return (
                                        <option value={estado.sigla}>
                                            {estado.nome}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>{t("Municipio")}</Form.Label>
                            <Form.Select
                                value={municipio}
                                onChange={(e) => {
                                    setMunicipio(e.target.value);
                                }}
                            >
                                <option value={""}>
                                    {t("Selecione o municipio")}
                                </option>
                                {listaMunicipiosStake[estado]?.map(
                                    (municipio: any) => {
                                        return (
                                            <option value={municipio}>
                                                {municipio}
                                            </option>
                                        );
                                    }
                                )}
                            </Form.Select>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} className="mt-4 mb-4">
                    <Row>
                        <Col sm={6}>
                            <Form.Label>
                                {t("Categoria do Stakeholder")}
                            </Form.Label>
                            <Form.Select
                                value={funcao}
                                onChange={(event) => {
                                    setFuncao(event.target.value);
                                }}
                            >
                                <option value="">
                                    {t("Selecione a Categoria")}
                                </option>
                                <option
                                    value={
                                        "Poder Público Municipal - Secretaria"
                                    }
                                >
                                    {t("Poder Público Municipal - Secretaria")}
                                </option>
                                <option
                                    value={
                                        "Poder Público Municipal - Prefeitura"
                                    }
                                >
                                    {t("Poder Público Municipal - Prefeitura")}
                                </option>
                                <option
                                    value={
                                        "Poder Público Municipal - Autarquia"
                                    }
                                >
                                    {t("Poder Público Municipal - Autarquia")}
                                </option>
                                <option
                                    value={
                                        "Poder Público Municipal - Câmara de Vereadores"
                                    }
                                >
                                    {t(
                                        "Poder Público Municipal - Câmara de Vereadores"
                                    )}
                                </option>

                                <option value="Poder Público Estadual - Executivo">
                                    {t("Poder Público Estadual - Executivo")}
                                </option>
                                <option value="Poder Público Estadual - Legislativo">
                                    {t("Poder Público Estadual - Legislativo")}
                                </option>
                                <option value="Poder Público Estadual - Judiciário">
                                    {t("Poder Público Estadual - Judiciário")}
                                </option>
                                <option
                                    value={"Poder Público Federal- Executivo"}
                                >
                                    {t("Poder Público Federal- Executivo")}
                                </option>
                                <option
                                    value={
                                        "Poder Público Federal - Legislativo"
                                    }
                                >
                                    {t("Poder Público Federal - Legislativo")}
                                </option>
                                <option
                                    value={"Poder Público Federal - Judiciário"}
                                >
                                    {t("Poder Público Federal - Judiciário")}
                                </option>
                                <option value="Ministério Público">
                                    {t("Ministério Público")}
                                </option>
                                <option value="Entidades Filantrópicas">
                                    {t("Entidades Filantrópicas")}
                                </option>
                                <option value={"Órgão de Defesa e Emergência"}>
                                    {t("Órgão de Defesa e Emergência")}
                                </option>
                                <option
                                    value={"Entidades Setoriais e Associações"}
                                >
                                    {t("Entidades Setoriais e Associações")}
                                </option>
                                <option value="Cooperativas">
                                    {t("Cooperativas")}
                                </option>
                                <option value={"Entidades do Terceiro Setor"}>
                                    {t("Entidades do Terceiro Setor")}
                                </option>
                                <option value={"Setor Privado"}>
                                    {t("Setor Privado")}
                                </option>
                                <option value={"Academia"}>
                                    {t("Academia")}
                                </option>
                                <option value={"outros"}>{t("Outros")}</option>
                            </Form.Select>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                className="formInput"
                                placeholder="Digite o Nome"
                                type={"text"}
                                value={stakeholder}
                                onChange={(e) => {
                                    setStakeholder(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>

                {funcao === "outros" && (
                    <Col>
                        <Form.Control
                            className="formInput"
                            type={"text"}
                            value={funcaoTexto}
                            placeholder="Categoria do Stakeholder"
                            onChange={(e) => {
                                setFuncaoTexto(e.target.value);
                            }}
                        />
                    </Col>
                )}

                <Col sm={12} className="mt-4 mb-4">
                    <Row>
                        <Col sm={6}>
                            <Form.Label style={{ fontSize: "85%" }}>
                                {t("Cargo")}
                            </Form.Label>
                            <Form.Control
                                className="formInput"
                                type="text"
                                placeholder="Digite o Cargo"
                                value={cargo}
                                onChange={(e) => {
                                    setCargo(e.target.value);
                                }}
                            />
                        </Col>
                        <Col sm={6}>
                            <Form.Label>
                                {t("Interlocutores Mosaic")}
                            </Form.Label>
                            <Form.Control
                                className="formInput"
                                placeholder="Digite Interlocutores"
                                type={"text"}
                                value={pontosDeInterface}
                                onChange={(e) => {
                                    setPontosDeInterface(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} className="mt-4 mb-4">
                    <Row>
                        <Form.Label>{t("Contato")}</Form.Label>
                        <Col sm={4}>
                            <Form.Control
                                className="formInput"
                                type={"text"}
                                placeholder="Numero de celular"
                                value={contatoCelular}
                                onChange={(e) => {
                                    setContatoCelular(e.target.value);
                                }}
                            />
                        </Col>
                        <Col sm={4}>
                            <Form.Control
                                className="formInput"
                                type={"text"}
                                placeholder="Email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </Col>
                        <Col sm={4}>
                            <Form.Control
                                className="formInput"
                                type={"text"}
                                placeholder="Endereço"
                                value={endereco}
                                onChange={(e) => {
                                    setEndereco(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} className="mt-3 mb-3">
                    <Row>
                        <Col sm={4}>
                            <Form.Label style={{ fontSize: "85%" }}>
                                {t("Postura")}
                            </Form.Label>
                            <Form.Select
                                value={postura}
                                onChange={(event) => {
                                    setPostura(event.target.value);
                                }}
                            >
                                <option value="">{t("Postura")}</option>
                                <option value="Favorável">
                                    {t("Favorável")}
                                </option>
                                <option value="Neutro">{t("Neutro")}</option>
                                <option value="Contrário">
                                    {t("Contrário")}
                                </option>
                            </Form.Select>
                        </Col>
                        <Col sm={4}>
                            <Form.Label style={{ fontSize: "85%" }}>
                                {t("Grau de Influência")}
                            </Form.Label>
                            <Form.Select
                                value={influencia}
                                onChange={(event) => {
                                    setInfluencia(event.target.value);
                                }}
                            >
                                <option value="">
                                    {t("Grau de Influência")}
                                </option>
                                <option value="Alto">{t("Alto")}</option>
                                <option value="Média">{t("Média")}</option>
                                <option value="Baixo">{t("Baixo")}</option>
                            </Form.Select>
                        </Col>
                        <Col sm={4}>
                            <Form.Label style={{ fontSize: "85%" }}>
                                {t("Criticidade")}
                            </Form.Label>
                            <Form.Select
                                disabled
                                value={criticidade}
                                onChange={(event) => {
                                    setCriticidade(event.target.value);
                                }}
                            >
                                <option value="">{t("Criticidade")}</option>
                                <option value="Alto">{t("Alto")}</option>
                                <option value="Médio">{t("Médio")}</option>
                                <option value="Baixo">{t("Baixo")}</option>
                            </Form.Select>
                        </Col>
                        {/* <Col sm={3}>
              <Form.Label style={{ fontSize: "85%" }}>
                {t("Tipo")}
              </Form.Label>
              <Form.Select
                value={tipo}
                onChange={(event) => {
                  setTipo(event.target.value);
                }}
              >
                <option value="">{t("Selecione tipo")}</option>
                <option value="Interno">{t("Interno")}</option>
                <option value="Externo">{t("Externo")}</option>
              </Form.Select>
            </Col> */}
                    </Row>
                </Col>

                <Col sm={12} className="mt-4 mb-4">
                    <Form.Label className="mt-4">{t("Comentarios")}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        className="formInput"
                        value={comentarios}
                        onChange={(e) => {
                            setComentarios(e.target.value);
                        }}
                    />
                </Col>

                <Col sm={12} className="cadastroFotos">
                    <Form.Control
                        type="file"
                        accept="image/*"
                        name="avatar"
                        multiple
                        onChange={(e: any) => {
                            setRegistroFotografico(
                                e.target.files[0] ? e.target.files[0] : null
                            );
                        }}
                    />
                </Col>
                <Col sm={12}>
                    <Row>
                        <Col sm={6}>
                            {/* {permissoesUser.stakeholders_create ? ( */}
                            <button
                                className="botao-stakeholder"
                                type="button"
                                onClick={handleOpenModalRegister}
                            >
                                {t("Registrar")}
                            </button>
                            {/* ) : (
                <button
                  className="botao-stakeholder"
                  type="button"
                  onClick={() =>
                    toast.error(
                      "Você não tem permissão para criar um novo Stakeholder!"
                    )
                  }
                >
                  {t("Registrar")}
                </button>
              )} */}
                        </Col>
                        <Col sm={6}>
                            <button
                                className="botao-stakeholder"
                                type="reset"
                                onClick={handleClearFields}
                            >
                                {t("Limpar")}
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Form>
            <Modal
                show={showModalRegister}
                onHide={handleCloseModalRegister}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja enviar o cadastro?")}
                            </p>

                            <button
                                className="botao-grid"
                                onClick={handleSubmit}
                                disabled={loadingButton}
                                type="button"
                            >
                                {loadingButton ? "Cadastrando..." : "Sim"}
                            </button>
                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalRegister}
                            >
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default StakeholdersRI;
