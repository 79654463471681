import "./styles.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function LoadingIcon() {
    return (
        <div className="loadingIcon">
            <AiOutlineLoading3Quarters />
        </div>
    );
}

export default LoadingIcon;
