import React from 'react';
import * as ExcelJS from 'exceljs';
import moment from 'moment-timezone';

function ButtonExportExcel({ lista }) {
  const exportToExcel = () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Dados');

    const removeNullProperties = (obj) => {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value !== null)
      );
    };

    const dadosFiltradosSemNull = lista.map(removeNullProperties);

    const headers = Object.keys(dadosFiltradosSemNull[0]);
    worksheet.addRow(headers);


    dadosFiltradosSemNull.forEach(item => {
      const row = [];
      headers.forEach(header => {
        const formattedValue = header.includes('dt') && item[header]
          ? moment(item[header]).tz('America/Sao_Paulo').format('DD-MM-YYYY')
          : item[header];
        row.push(formattedValue);
      });
      worksheet.addRow(row);
    });

    workbook.xlsx.writeBuffer()
      .then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'dados-exportados.xlsx';
        link.click();
      })
      .catch(error => {
        console.error('Erro ao exportar o arquivo:', error);
      });
  };

  return (
    <button className="btn btn-success" onClick={exportToExcel}>
      Exportar para Excel
    </button>
  );
}

export default ButtonExportExcel;
