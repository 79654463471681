import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import {
    Modal,
    Card,
    Col,
    Row,
    Button,
    Image,
    Carousel,
} from "react-bootstrap";
import Api, { ClearSessionStorage } from "../../config/api";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp, BiCheck } from "react-icons/bi";
// import Engajamentos from "../engajamentos";
import PainelSocial from "../painelSocial";
import Visitas from "../visitas";
import Noticias from "../noticias";
import Notificacoes from "../notificacoes";
import StakeholdersRC from "../stakeholders/stakeholders.RC";
import Atendimentos from "../atendimentos";
import ImagemCard1 from "../../assets/images/card1.png";
import ImagemCard from "../../assets/images/PersonCashier.png";
import GridAtendimento from "../grids/atendimento";
import GridVisitas from "../grids/visitas";
import GridNoticias from "../grids/noticias";
import GridPainelSocial from "../grids/painelSocial";
import GridNotificacoes from "../grids/notificacoes";
import GridStakeholdersRC from "../grids/stakeholders/GridStakeholders.RC";
import Sms from "../sms";
import GridSms from "../grids/sms";
import GridSolicitacoes from "../grids/solicitacoes";
import { useParams } from "react-router-dom";
import BotaoVoltar from "../../components/buttonReturnPage";

function Cadastro() {
    const { gerarAtendimento } = useParams();
    const navegar = useNavigate();

    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [activeGridCard, setActiveGridCard] = useState<number | null>(0);
    const [activeCard, setActiveCard] = useState<number | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showModalGrid, setShowModalGrid] = useState<boolean>(false);
    const [carouselData, setCarouselData] = useState<any[]>([]);
    const [permissoesUser, setPermissoesUser] = useState({
        administrador: false,
        atendimentos_create: false,
        atendimentos_edit: false,
        atendimentos_view: false,
        demandas_create: false,
        demandas_edit: false,
        demandas_view: false,
        engajamentos_create: false,
        engajamentos_edit: false,
        engajamentos_view: false,
        id: 0,
        id_user: 0,
        noticias_create: false,
        noticias_edit: false,
        noticias_view: false,
        notificacoes_create: false,
        notificacoes_edit: false,
        notificacoes_view: false,
        stakeholders_create: false,
        stakeholders_edit: false,
        stakeholders_view: false,
        usuario_create: false,
        usuario_edit: false,
        visitas_create: false,
        visitas_edit: false,
        visitas_view: false,
        sms_view: false,
        sms_create: false,
        solicitacoes_view: false,
        solicitacoes_edit: false,
        painelSocial_view: false,
        painelSocial_create: false,
        painelSocial_edit: false,
    });

    const toggleCard = (index: number) => {
        if (index === activeCard) {
            setActiveCard(null);
            setIsExpanded(false);
        } else {
            setActiveCard(index);
            setIsExpanded(true);
        }
    };

    const tokenUsuario = sessionStorage.getItem("tokenUsuario");
    let config = { headers: { authorization: tokenUsuario } };

    useEffect(() => {
        const permissionsUsuario = sessionStorage.getItem("permissoesUsuario");
        if (permissionsUsuario) {
            setPermissoesUser(JSON.parse(permissionsUsuario));
        }
    }, []);

    const cardDataRegister = [
        {
            title: t("Atendimentos"),
            content: "Formulário do Card 1",
            Element: Atendimentos,
            permissoes: permissoesUser.atendimentos_view,
        },
        {
            title: t("Visitas"),
            content: "Formulário do Card 2",
            Element: Visitas,
            permissoes: permissoesUser.visitas_view,
        },
        {
            title: t("Notícias"),
            content: "Formulário do Card 3",
            Element: Noticias,
            permissoes: permissoesUser.noticias_view,
        },
        {
            title: t("Notificações"),
            content: "Formulário do Card 4",
            Element: Notificacoes,
            permissoes: permissoesUser.notificacoes_view,
        },
        {
            title: t("Stakeholders"),
            content: "Formulário do Card 5",
            Element: StakeholdersRC,
            permissoes: permissoesUser.stakeholders_view,
        },
        {
            title: t("SMS"),
            content: "Formulário do Card 6",
            Element: Sms,
            permissoes: permissoesUser.sms_view,
        },
        {
            title: t("Painel Social"),
            content: "Formulário do Card 7",
            Element: PainelSocial,
            permissoes: permissoesUser.painelSocial_view,
        },
    ];

    const cardDataGrid = [
        { title: t("Atendimentos"), content: "Formulário do Card 1" },
        { title: t("Visitas"), content: "Formulário do Card 2" },
        { title: t("Notícias"), content: "Formulário do Card 3" },
        { title: t("Notificações"), content: "Formulário do Card 4" },
        { title: t("Stakeholders"), content: "Formulário do Card 5" },
        { title: t("Sms"), content: "Formulário do Card 6" },
        { title: t("Solicitações COMMO"), content: "Formulário do Card 7" },
        { title: t("Painel Social"), content: "Formulário do Card 8" },
    ];

    interface CardItemProps {
        option: string;
        value: number;
    }

    const dadosCadastrados = () => {
        Api.GetContadoresComunidade(config)
            .then((data: any[]) => {
                const dados = data.map((item) => ({
                    title: t(item.titulo),
                    content: Object.entries(item.resultados).map(
                        ([key, value]) => ({
                            option: t(key),
                            value,
                        })
                    ),
                }));

                setCarouselData(dados);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        dadosCadastrados();
    }, []);

    useEffect(() => {
        const expirationUsuario = sessionStorage.getItem("expirationUsuario");
        if (!expirationUsuario || new Date() > new Date(expirationUsuario)) {
            ClearSessionStorage();
            navegar("/login");
        }
    }, []);

  const CardItem: React.FC<CardItemProps> = ({ option, value }) => (
    <Card className="card-numeros mt-2">
      <Card.Body className="style-card-numeros">
        <Row>
          <Col sm={9} className="col-sm-9 col-8 col-md-8">
            <Card.Title
              className="card-text-titles"
              style={{ fontWeight: 300, textTransform: "capitalize" }}
            >
              {t(option)}
            </Card.Title>
          </Col>
          <Col sm={3} className="col-sm-3 col-4 col-md-4">
            <Card.Text className="text-end valores-card">{value}</Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleOpenModalGrid = () => {
        setActiveGridCard(0);
        setShowModalGrid(true);
    };

    const handleCloseModalGrid = () => {
        setShowModalGrid(false);
    };

    useEffect(() => {
        if (gerarAtendimento === "atendimento") {
            toggleCard(0);
            handleOpenModal();
        }
    }, [gerarAtendimento]);

    return (
        <div id="baseCard" className="mb-5">
            <Col sm={12} className="p-4">
                <BotaoVoltar />
                <Row>
                    <Col sm={3} className="mb-3">
                        <Card>
                            <Col
                                sm={12}
                                className="d-flex justify-content-center"
                            >
                                <Image
                                    src={ImagemCard1}
                                    className="imagem-card-um"
                                />
                            </Col>
                            <Col
                                sm={12}
                                className="d-flex justify-content-center"
                            >
                                <button
                                    className="botao-card-table"
                                    onClick={handleOpenModalGrid}
                                >
                                    {t("Visualizar registros")}
                                </button>
                            </Col>
                        </Card>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <Card>
                            <Col
                                sm={12}
                                className="d-flex justify-content-center"
                            >
                                <Image
                                    src={ImagemCard}
                                    className="imagem-card"
                                />
                            </Col>
                            <Col sm={12}>
                                <Col
                                    sm={12}
                                    className="d-flex justify-content-center"
                                >
                                    <h5>{t("Faça novos cadastros")}</h5>
                                </Col>
                                <Col sm={12} style={{ paddingLeft: "10%" }}>
                                    <BiCheck className="icon-check" />
                                    <span>{t("Atendimentos")}</span>
                                </Col>
                                <Col sm={12} style={{ paddingLeft: "10%" }}>
                                    <BiCheck className="icon-check" />
                                    <span>{t("Visitas")}</span>
                                </Col>
                                <Col sm={12} style={{ paddingLeft: "10%" }}>
                                    <BiCheck className="icon-check" />
                                    <span>{t("Notícias")}</span>
                                </Col>
                                <Col sm={12} style={{ paddingLeft: "10%" }}>
                                    <BiCheck className="icon-check" />
                                    <span>{t("Notificações")}</span>
                                </Col>
                                <Col sm={12} style={{ paddingLeft: "10%" }}>
                                    <BiCheck className="icon-check" />
                                    <span>{t("Stakeholders")}</span>
                                </Col>
                                <Col sm={12} style={{ paddingLeft: "10%" }}>
                                    <BiCheck className="icon-check" />
                                    <span>{t("SMS")}</span>
                                </Col>
                                <Col sm={12} style={{ paddingLeft: "10%" }}>
                                    <BiCheck className="icon-check" />
                                    <span>{t("Painel Social")}</span>
                                </Col>
                            </Col>
                            <Col
                                sm={12}
                                className="d-flex justify-content-center"
                            >
                                <button
                                    className="botao-card"
                                    onClick={handleOpenModal}
                                >
                                    {t("Novo cadastro")}
                                </button>
                            </Col>
                        </Card>
                    </Col>
                    <Col sm={3} className="mb-3">
                        <Card className="cards-contadores">
                            <Col
                                sm={12}
                                className="d-flex justify-content-center"
                            >
                                <Carousel className="d-flex justify-content-center mt-3">
                                    {carouselData.map((item, index) => (
                                        <Carousel.Item key={index}>
                                            <h4
                                                style={{ fontWeight: 500 }}
                                                className="text-center"
                                            >
                                                {item.title}
                                            </h4>
                                            <div className="d-flex justify-content-around">
                                                <Row>
                                                    {item.content.map(
                                                        (
                                                            data: {
                                                                option: string;
                                                                value: number;
                                                            },
                                                            i: number
                                                        ) => (
                                                            <CardItem
                                                                key={i}
                                                                option={
                                                                    data.option
                                                                }
                                                                value={
                                                                    data.value
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Row>
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </Col>

            {/* {MODAL DE VISUALIZAR OS GRIDS} */}
            <Modal
                show={showModalGrid}
                onHide={handleCloseModalGrid}
                centered={true}
                dialogClassName="modal-rollover"
                fullscreen
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Visualizar registros")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-cadastro mb-5">
                    <Col sm={12} className="grids">
                        <Row>
                            {cardDataGrid.map((card, index) => (
                                <Col key={index} sm={10}>
                                    {activeGridCard === index && (
                                        <div>
                                            <hr />
                                            {card.content ===
                                                "Formulário do Card 1" &&
                                                permissoesUser.atendimentos_view && (
                                                    <GridAtendimento
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}
                                            {card.content ===
                                                "Formulário do Card 1" &&
                                                !permissoesUser.atendimentos_view && (
                                                    <GridAtendimento
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}

                                            {card.content ===
                                                "Formulário do Card 2" &&
                                                permissoesUser.visitas_view && (
                                                    <GridVisitas
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}
                                            {card.content ===
                                                "Formulário do Card 2" &&
                                                !permissoesUser.visitas_view && (
                                                    <GridVisitas
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}

                                            {card.content ===
                                                "Formulário do Card 3" &&
                                                permissoesUser.noticias_view && (
                                                    <GridNoticias
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}
                                            {card.content ===
                                                "Formulário do Card 3" &&
                                                !permissoesUser.noticias_view && (
                                                    <GridNoticias
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}

                                            {card.content ===
                                                "Formulário do Card 4" &&
                                                permissoesUser.notificacoes_view && (
                                                    <GridNotificacoes
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}
                                            {card.content ===
                                                "Formulário do Card 4" &&
                                                !permissoesUser.notificacoes_view && (
                                                    <GridNotificacoes
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}

                                            {card.content ===
                                                "Formulário do Card 5" &&
                                                permissoesUser.stakeholders_view && (
                                                    <GridStakeholdersRC
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}
                                            {card.content ===
                                                "Formulário do Card 5" &&
                                                !permissoesUser.stakeholders_view && (
                                                    <GridStakeholdersRC
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}

                                            {card.content ===
                                                "Formulário do Card 6" &&
                                                permissoesUser.sms_view && (
                                                    <GridSms
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}
                                            {card.content ===
                                                "Formulário do Card 6" &&
                                                !permissoesUser.sms_view && (
                                                    <GridSms
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}

                                            {card.content ===
                                                "Formulário do Card 7" &&
                                                permissoesUser.solicitacoes_view && (
                                                    <GridSolicitacoes
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                        toggleCard={toggleCard}
                                                    />
                                                )}
                                            {card.content ===
                                                "Formulário do Card 7" &&
                                                !permissoesUser.solicitacoes_view && (
                                                    <GridSolicitacoes
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                        toggleCard={toggleCard}
                                                    />
                                                )}
                                            {card.content ===
                                                "Formulário do Card 8" &&
                                                permissoesUser.painelSocial_view && (
                                                    <GridPainelSocial
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}
                                            {card.content ===
                                                "Formulário do Card 8" &&
                                                !permissoesUser.painelSocial_view && (
                                                    <GridPainelSocial
                                                        permissoesUser={
                                                            permissoesUser
                                                        }
                                                        config={config}
                                                    />
                                                )}
                                        </div>
                                    )}
                                </Col>
                            ))}
                            <Col sm={2} id="progressCard">
                                <Card
                                    style={{
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                >
                                    {cardDataGrid.map((card, index) => (
                                        <button
                                            key={index}
                                            className={`progressButton ${
                                                index === activeGridCard
                                                    ? "activeCard"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setActiveGridCard(index)
                                            }
                                        >
                                            {card.title}
                                        </button>
                                    ))}
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Body>
            </Modal>

            {/* MODAL DE CADASTRO */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered={true}
                dialogClassName="modal-rollover"
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Cadastro")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-cadastro">
                    <Col sm={12}>
                        <Row>
                            <Col
                                sm={10}
                                className="cards cards-cadastro-mobile"
                            >
                                {cardDataRegister.map((card, index) => (
                                    <Card
                                        key={index}
                                        className="card-cadastro-modal"
                                    >
                                        <Card.Body>
                                            <Row>
                                                <Col className="col-sm-10 col-10">
                                                    <Card.Title>
                                                        {t(card.title)}
                                                    </Card.Title>
                                                </Col>
                                                <Col className="col-sm-2 col-2 d-flex justify-content-end">
                                                    <Button
                                                        onClick={() =>
                                                            toggleCard(index)
                                                        }
                                                        className="buttonCard"
                                                    >
                                                        {activeCard === index &&
                                                        isExpanded ? (
                                                            <BiChevronUp className="iconChevron" />
                                                        ) : (
                                                            <BiChevronDown className="iconChevron" />
                                                        )}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <div
                                                style={
                                                    activeCard === index
                                                        ? {}
                                                        : { display: "none" }
                                                }
                                            >
                                                <hr />
                                                {card.content ===
                                                    "Formulário do Card 1" &&
                                                    permissoesUser.atendimentos_view && (
                                                        <Atendimentos
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}
                                                {card.content ===
                                                    "Formulário do Card 1" &&
                                                    !permissoesUser.atendimentos_view && (
                                                        <Atendimentos
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}

                                                {card.content ===
                                                    "Formulário do Card 2" &&
                                                    permissoesUser.visitas_view && (
                                                        <Visitas
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}
                                                {card.content ===
                                                    "Formulário do Card 2" &&
                                                    !permissoesUser.visitas_view && (
                                                        <Visitas
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}

                                                {card.content ===
                                                    "Formulário do Card 3" &&
                                                    permissoesUser.noticias_view && (
                                                        <Noticias
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}
                                                {card.content ===
                                                    "Formulário do Card 3" &&
                                                    !permissoesUser.noticias_view && (
                                                        <Noticias
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}

                                                {card.content ===
                                                    "Formulário do Card 4" &&
                                                    permissoesUser.notificacoes_view && (
                                                        <Notificacoes
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}
                                                {card.content ===
                                                    "Formulário do Card 4" &&
                                                    !permissoesUser.notificacoes_view && (
                                                        <Notificacoes
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}

                                                {card.content ===
                                                    "Formulário do Card 5" &&
                                                    permissoesUser.stakeholders_view && (
                                                        <StakeholdersRC
                                                            config={config}
                                                            listaEstados={[""]}
                                                            listaMunicipios={[""]}
                                                            // permissoesUser={
                                                            //     permissoesUser
                                                            // }
                                                            // toggleCard={
                                                            //     toggleCard
                                                            // }
                                                        />
                                                    )}
                                                {card.content ===
                                                    "Formulário do Card 5" &&
                                                    !permissoesUser.stakeholders_view && (
                                                        <StakeholdersRC
                                                            config={config}
                                                            listaEstados={[""]}
                                                            listaMunicipios={[""]}
                                                            // permissoesUser={
                                                            //     permissoesUser
                                                            // }
                                                            // toggleCard={
                                                            //     toggleCard
                                                            // }
                                                        />
                                                    )}

                                                {card.content ===
                                                    "Formulário do Card 6" &&
                                                    permissoesUser.sms_view && (
                                                        <Sms
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}
                                                {card.content ===
                                                    "Formulário do Card 6" &&
                                                    !permissoesUser.sms_view && (
                                                        <Sms
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                            toggleCard={
                                                                toggleCard
                                                            }
                                                        />
                                                    )}

                                                {card.content ===
                                                    "Formulário do Card 7" &&
                                                    permissoesUser.painelSocial_view && (
                                                        <PainelSocial
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                        />
                                                    )}
                                                {card.content ===
                                                    "Formulário do Card 7" &&
                                                    !permissoesUser.painelSocial_view && (
                                                        <PainelSocial
                                                            config={config}
                                                            permissoesUser={
                                                                permissoesUser
                                                            }
                                                        />
                                                    )}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </Col>
                            <Col sm={2} id="progressCard">
                                <Card className="modal-grid-card">
                                    {cardDataRegister.map((card, index) => (
                                        <button
                                            key={index}
                                            className={`progressButton ${
                                                index === activeCard
                                                    ? "activeCard"
                                                    : ""
                                            }`}
                                            onClick={() => toggleCard(index)}
                                        >
                                            {card.title}
                                        </button>
                                    ))}
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Cadastro;
