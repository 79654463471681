import "./styles.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BsCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import Api, { linkBackend } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card, Button } from "react-bootstrap";
import DateRangeInput from "../../../utils/DateRangeInput";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import treatArray from "../../../utils/treatArray";
import { capitalizeArray, toUpperArray } from "../../../utils/capitalize";
import ButtonExportExcel from "../../../components/buttonExportListExcel";
import Select from "react-select";
import { formatDateToString } from "../../../utils/datesUtils"
import MapModal from "../../../components/mapModal";

const UNDEFINED_COLOR = process.env.REACT_APP_UNDEFINED_COLOR;
const RED_COLOR = process.env.REACT_APP_RED_COLOR;
const YELLOW_COLOR = process.env.REACT_APP_YELLOW_COLOR;
const GREEN_COLOR = process.env.REACT_APP_GREEN_COLOR;

type SetFilterFunction<T> = React.Dispatch<React.SetStateAction<T | undefined>>;
interface Filtro {
  value: string;
  label: string;
  type: string;
  opts?: any;
  filter: any;
  setFilter: SetFilterFunction<any>;
}

function GridAtendimento({
  config,
  permissoesUser,
}: {
  config: any;
  permissoesUser: {
    administrador: boolean;
    atendimentos_create: boolean;
    atendimentos_edit: boolean;
    atendimentos_view: boolean;
    demandas_create: boolean;
    demandas_edit: boolean;
    demandas_view: boolean;
    engajamentos_create: boolean;
    engajamentos_edit: boolean;
    engajamentos_view: boolean;
    id: number;
    id_user: number;
    noticias_create: boolean;
    noticias_edit: boolean;
    noticias_view: boolean;
    notificacoes_create: boolean;
    notificacoes_edit: boolean;
    notificacoes_view: boolean;
    stakeholders_create: boolean;
    stakeholders_edit: boolean;
    stakeholders_view: boolean;
    usuario_create: boolean;
    usuario_edit: boolean;
    visitas_create: boolean;
    visitas_edit: boolean;
    visitas_view: boolean;
    sms_view: boolean;
    sms_create: boolean;
    solicitacoes_view: boolean;
    solicitacoes_edit: boolean;
  };
}) {
  const { t } = useTranslation();
  const navegar = useNavigate();

  const [datagridRows, setDatagridRows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModalFiltro, setOpenModalFiltro] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<any | null>(null);

  const [listaEstados, setListaEstados] = useState<any>([]);
  const [listaMunicipios, setListaMunicipios] = useState<any>([]);
  const [listaComunidades, setListaComunidades] = useState<any>([]);
  const [AllStakeholders, setAllStakeholders] = useState<any>([]);

  const [idAtendimento, setIdAtendimento] = useState<number>(0);

  //input
  const [descricao, setDescricao] = useState<string>("");
  const [tratativa, setTratativa] = useState<string>("");
  const [stakeholder, setStakeholder] = useState<string>("");
  const [contato, setContato] = useState<string>("");
  const [responsavel, setResponsavel] = useState<string>("");
  const [planoAcao, setPlanoAcao] = useState<string>("");

  //Select
  const [categoria, setCategoria] = useState<string>("");
  const [subCategoria, setSubCategoria] = useState<string>("");
  const [estado, setEstado] = useState<string>("");
  const [municipio, setMunicipio] = useState<string>("");
  const [comunidade, setComunidade] = useState<string>("");
  const [complexidade, setComplexidade] = useState<string>("");
  const [tipoAtendimento, setTipoAtendimento] = useState<string>("");

  //Switch
  const [incidentePotencial, setIncidentePotencial] = useState<boolean>(false);

  //Date
  const [prazo, setPrazo] = useState<string | undefined>(undefined);
  const [solicitacao, setSolicitacao] = useState<string | undefined>(undefined);
  const [previsao, setPrevisao] = useState<string | undefined>(undefined);
  const [resposta, setResposta] = useState<string | undefined>(undefined);
  const [conclusao, setConclusao] = useState<string | undefined>(undefined);

  const [registroFotografico, setRegistroFotografico] = useState(null);
  const [toastExibido, setToastExibido] = useState(false);

  // MAPS
  const [isModalMapOpen, setIsModalMapOpen] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState<{ lat: number; lng: number } | null>(null);

  const handleOpenModal = () => {
    setIsModalMapOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalMapOpen(false);
  };

  const handleCoordinatesSelected = (coordinates: { lat: number; lng: number }) => {
    setSelectedCoordinates(coordinates);
    console.log("Coordenadas selecionadas:", coordinates);
  };

  useEffect(() => {
    if (!permissoesUser.atendimentos_view && !toastExibido) {
      toast.error("Você não tem permissão para visualizar Atendimentos!");
      setToastExibido(true);
    }
  }, [permissoesUser.atendimentos_view, toastExibido]);

  const [estadosExistentes, municipiosExistentes, comunidadesExistentes] =
    useMemo(() => {
      const estados: string[] = [];
      const municipios: string[] = [];
      const comunidades: string[] = [];

      datagridRows.forEach((r) => {
        const estado = toUpperArray(treatArray(r.estado))?.filter(
          (v) => v && !estados.includes(v)
        );
        const municipio = capitalizeArray(treatArray(r.municipio))?.filter(
          (v) => v && !municipios.includes(v)
        );
        const comunidade = capitalizeArray(treatArray(r.comunidade))?.filter(
          (v) => v && !comunidades.includes(v)
        );

        if (estado) estados.push(...estado);
        if (municipio) municipios.push(...municipio);
        if (comunidade) comunidades.push(...comunidade);
      });

      return [
        estados.sort((a, b) => a.localeCompare(b)),
        municipios.sort((a, b) => a.localeCompare(b)),
        comunidades.sort((a, b) => a.localeCompare(b)),
      ];
    }, [datagridRows]);

  useEffect(() => {
    Api.GetStakeholders(config).then((data) => {
      setAllStakeholders(data);
    });
  }, []);
  //filtros
  const [filtroStakeholder, setFiltroStakeholder] = useState<string>();
  const [filtroResponsavel, setFiltroResponsavel] = useState<string>();
  const [filtroStatus, setFiltroStatus] = useState<string>();
  const [filtroEstado, setFiltroEstado] = useState<string>();

  const [filtroSelectedMunicipios, setFiltroSelectedMunicipios] = useState<string[] | undefined>(undefined);

  const [filtroComunidade, setFiltroComunidade] = useState<string>();
  const [filtroComplexidade, setFiltroComplexidade] = useState<string>();
  const [filtroData, setFiltroData] = useState<DateRange>();
  const [filtroCategoria, setFiltroCategoria] = useState<string>();
  const [filtroSubCategoria, setFiltroSubCategoria] = useState<string>();

  const [showPickerData, setShowPickerData] = useState<string>();

  type SubCategoriaOptions = {
    [key: string]: { label: string; value: string }[];
  };

  const subCategoriaOptions: SubCategoriaOptions = {
    Relacionamento: [
      { label: t("Destinação de Resíduos"), value: "Destinação de Resíduos" },
      { label: t("Filantropia"), value: "Filantropia" },
      { label: t("Patrocínio"), value: "Patrocínio" },
      { label: t("Segurança"), value: "Segurança" },
      { label: t("Visitas"), value: "Visitas" },
    ],
    "G. da Propriedade": [
      { label: t("Documentação"), value: "Documentação" },
      { label: t("Divisas"), value: "Divisas" },
      { label: t("Acessos"), value: "Acessos" },
      { label: t("Arrendamento"), value: "Arrendamento" },
      {
        label: t("Invasões de Animais / Terceiros"),
        value: "Invasões de Animais / Terceiros",
      },
      {
        label: t("Aquisição de N. Propriedade"),
        value: "Aquisição de N. Propriedade",
      },
    ],
    "Demanda Ambiental": [
      { label: t("Água"), value: "Água" },
      { label: t("Alagamentos"), value: "Alagamentos" },
      { label: t("Queimadas"), value: "Queimadas" },
      { label: t("Poeira"), value: "Poeira" },
      { label: t("Educação Ambiental"), value: "Educação Ambiental" },
      { label: t("Emissões"), value: "Emissões" },
      { label: t("Licenciamento"), value: "Licenciamento" },
      { label: t("Vegetação"), value: "Vegetação" },
      { label: t("Ruídos"), value: "Ruídos" },
    ],
    PAEBM: [
      { label: t("Acessos"), value: "Acessos" },
      { label: t("Rotas de Fuga"), value: "Rotas de Fuga" },
      { label: t("Sirenes"), value: "Sirenes" },
      { label: t("Água"), value: "Água" },
      {
        label: t("Informações / Documentos"),
        value: "Informações / Documentos",
      },
      { label: t("Pedidos Diversos"), value: "Pedidos Diversos" },
      { label: t("Treinamento / Simulado"), value: "Treinamento / Simulado" },
    ],
    Outros: [
      { label: t("Danos Materiais"), value: "Danos Materiais" },
      { label: t("Empregos"), value: "Empregos" },
      { label: t("Outros"), value: "Outros" },
    ],
  };

  const tiposFiltro: Filtro[] = [
    {
      value: "Stakeholder",
      label: t("Stakeholder"),
      type: "string",
      filter: filtroStakeholder,
      setFilter: setFiltroStakeholder,
    },
    {
      value: "Responsavel",
      label: t("Responsavel"),
      type: "string",
      filter: filtroResponsavel,
      setFilter: setFiltroResponsavel,
    },
    {
      value: "Status",
      label: t("Status"),
      type: "select",
      opts: [
        { label: t("Em Andamento"), value: "Em Andamento" },
        { label: t("Concluido"), value: "Concluido" },
      ],
      filter: filtroStatus,
      setFilter: setFiltroStatus,
    },
    {
      value: "Estado",
      label: t("Estado"),
      type: "select",
      opts: estadosExistentes,
      filter: filtroEstado,
      setFilter: setFiltroEstado,
    },
    {
      value: "Municipio",
      label: t("Municipio"),
      type: "municipio",
      opts: municipiosExistentes,
      filter: filtroSelectedMunicipios,
      setFilter: setFiltroSelectedMunicipios,
    },
    {
      value: "Comunidade",
      label: t("Comunidade"),
      type: "select",
      opts: filtroSelectedMunicipios
        ? filtroSelectedMunicipios.flatMap((municipio: any) => listaComunidades[municipio] || [])
        : [],
      filter: filtroComunidade,
      setFilter: setFiltroComunidade,
    },
    {
      value: "Complexidade",
      label: t("Complexidade"),
      type: "select",
      opts: [
        { label: t("Baixo"), value: "Baixo" },
        { label: t("Médio"), value: "Médio" },
        { label: t("Alto"), value: "Alto" },
      ],
      filter: filtroComplexidade,
      setFilter: setFiltroComplexidade,
    },
    {
      value: "Data",
      label: t("Data"),
      type: "date",
      filter: filtroData,
      setFilter: setFiltroData,
    },
    {
      value: "Categoria",
      label: t("Categoria"),
      type: "select",
      opts: [
        { label: t("Relacionamento"), value: "Relacionamento" },
        { label: t("G. da Propriedade"), value: "G. da Propriedade" },
        { label: t("Demanda Ambiental"), value: "Demanda Ambiental" },
        { label: t("PAEBM"), value: "PAEBM" },
        { label: t("Outros"), value: "Outros" },
      ],
      filter: filtroCategoria,
      setFilter: setFiltroCategoria,
    },
    {
      value: "Sub-Categoria",
      label: t("Sub-Categoria"),
      type: "select",
      opts: subCategoriaOptions[filtroCategoria ? filtroCategoria : 0] || [],
      filter: filtroSubCategoria,
      setFilter: setFiltroSubCategoria,
    },
  ];

  const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

  const dadosFiltrados = useMemo(
    () =>
      datagridRows.filter(
        (v) =>
          (!filtroStakeholder ||
            v?.stakeholder
              ?.toLowerCase()
              .includes(filtroStakeholder?.toLowerCase())) &&
          (!filtroResponsavel ||
            v.responsavel
              ?.toLowerCase()
              .includes(filtroResponsavel?.toLowerCase())) &&
          (!filtroStatus ||
            v.status?.toLowerCase().includes(filtroStatus?.toLowerCase())) &&
          (!filtroEstado ||
            v.estado?.toLowerCase().includes(filtroEstado?.toLowerCase())) &&
          (!filtroSelectedMunicipios?.length ||
            filtroSelectedMunicipios.some((municipio) => v.municipio?.toLowerCase() === municipio.toLowerCase())) &&
          (!filtroComunidade ||
            v.comunidade
              ?.toLowerCase()
              .includes(filtroComunidade?.toLowerCase())) &&
          (!filtroComplexidade ||
            v.complexidade
              ?.toLowerCase()
              .includes(filtroComplexidade?.toLowerCase())) &&
          (!filtroData ||
            (v.dt_solicitacao >= (filtroData.from || new Date()) &&
              v.dt_solicitacao <= (filtroData.to || new Date()))) &&
          (!filtroCategoria ||
            v.categoria
              ?.toLowerCase()
              .includes(filtroCategoria?.toLowerCase())) &&
          (!filtroSubCategoria ||
            v.subclasse
              ?.toLowerCase()
              .includes(filtroSubCategoria?.toLowerCase()))
      ),
    [
      datagridRows,
      filtroStakeholder,
      filtroResponsavel,
      filtroStatus,
      filtroEstado,
      filtroSelectedMunicipios,
      filtroComunidade,
      filtroComplexidade,
      filtroData,
      filtroCategoria,
      filtroSubCategoria,
    ]
  );

  useEffect(() => {
    if (!selectedRowData) return;
    console.log("selectedRowData", selectedRowData)
    setIdAtendimento(selectedRowData.id || 0);
    setDescricao(selectedRowData.descricao || "");
    setTratativa(selectedRowData.tratativa || "");
    setStakeholder(selectedRowData.stakeholder || "");
    setContato(selectedRowData.contato || "");
    setResponsavel(selectedRowData.responsavel || "");
    setPlanoAcao(selectedRowData.plano_acao || "");
    setCategoria(selectedRowData.categoria || "");
    setSubCategoria(selectedRowData.subclasse || "");
    setEstado(selectedRowData.estado || "");
    setMunicipio(selectedRowData.municipio || "");
    setComunidade(selectedRowData.comunidade || "");
    setComplexidade(selectedRowData.complexidade || "");
    setTipoAtendimento(selectedRowData.tipo_atendimento || "");
    setIncidentePotencial(!!selectedRowData.incidente_potencial);
    setSolicitacao(formatDateToString(selectedRowData.dt_solicitacao));
    setPrazo(formatDateToString(selectedRowData.dt_prazo));
    setPrevisao(formatDateToString(selectedRowData.dt_previsao));
    setResposta(formatDateToString(selectedRowData.dt_resposta));
    setConclusao(selectedRowData.dt_conclusao ? formatDateToString(selectedRowData.dt_conclusao) : "");
    console.log("conclusaao", conclusao)
    setRegistroFotografico(selectedRowData.foto || null);
    setSelectedCoordinates({ lat: selectedRowData.latitude || null, lng: selectedRowData.longitude || null })
  }, [selectedRowData]);

  const handleRowClick = (params: any) => {
    setSelectedRowData(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
    updateDatagrid();
  };

  const handleOpenModalDelete = () => {
    setShowModalDelete(true);
  };

  const datagridColunas: readonly GridColDef<any>[] = [
    {
      field: "dt_solicitacao",
      headerName: t("header_Solicitacao") as string,
      type: "date",
      width: 150,
      valueGetter: ({ row }: { row: any }) => {
        if (!row.dt_solicitacao) {
          return null;
        }
        return new Date(row.dt_solicitacao);
      },
      renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        return params.value && params.value instanceof Date
          ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
          : "";
      },
    },
    {
      field: "descricao",
      headerName: t("Descricao") as string,
      width: 400,
    },
    {
      field: "stakeholder",
      headerName: t("header_Stakeholder") as string,
      width: 200,
    },
    {
      field: "responsavel",
      headerName: t("header_Responsavel") as string,
      width: 150,
    },
    {
      field: "estado",
      headerName: t("header_Estado") as string,
      width: 150,
    },
    {
      field: "municipio",
      headerName: t("header_Municipio") as string,
      width: 150,
    },
    {
      field: "comunidade",
      headerName: t("header_Comunidade") as string,
      width: 150,
    },
    {
      field: "complexidade",
      headerName: t("header_Complexidade") as string,
      width: 150,
      renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        const icon =
          params.value === t("Alto")
            ? RED_COLOR
            : params.value === t("Médio")
              ? YELLOW_COLOR
              : params.value === t("Baixo")
                ? GREEN_COLOR
                : UNDEFINED_COLOR;

        return (
          <span>
            <BsCircleFill color={icon} /> {params.value}
          </span>
        );
      },
    },
    {
      field: "categoria",
      headerName: t("Categoria") as string,
      width: 150,
    },
    {
      field: "subclasse",
      headerName: t("Sub-Categoria") as string,
      width: 150,
    },
    {
      field: "status",
      headerName: t("Status") as string,
      width: 150,
    },
  ];

  const AddDia = (dataString: any) => {
    if (!dataString) return null;
    const data = new Date(dataString);
    data.setDate(data.getDate() + 1);
    return data
  };

  const updateDatagrid = useCallback(() => {
    if (!config || !navegar || !t) return;

    Api.GetAtendimentos(config)
      .then((data: any[]) => {
        setDatagridRows(
          data.map((d, i) => ({
            ...d,
            arrayId: i + 1,
            dt_solicitacao: AddDia(d.dt_solicitacao),
            dt_prazo: AddDia(d.dt_prazo),
            dt_previsao: AddDia(d.dt_previsao),
            dt_resposta: AddDia(d.dt_resposta),
            dt_conclusao: AddDia(d.dt_conclusao),
          }))
        );
      })
      .catch((err: any) => {
        if (typeof err === "string") {
          toast.error(t(err));
          navegar("/login");
          return;
        }

        toast.error(t("Houve um erro ao carregar os atendimentos"));
      });
  }, [config, navegar, t]);

  const updateLocales = useCallback(() => {
    Api.GetLocales().then((data) => {
      setListaEstados(data.localidades.estados);
      setListaMunicipios(data.localidades.municipios);
      setListaComunidades(data.comunidades);
    });
  }, []);

  useEffect(updateDatagrid, [updateDatagrid]);
  useEffect(updateLocales, [updateLocales]);

  const handleModalFiltros = (filtro: string) => {
    setFiltroAtivo((filtros) => {
      const newFiltros = [...filtros];
      const fIndex = newFiltros.findIndex((f) => f === filtro);
      if (fIndex >= 0) {
        newFiltros.splice(fIndex, 1);
        tiposFiltro.find((f) => f.value === filtro)?.setFilter(undefined);
      } else {
        newFiltros.push(filtro);
      }
      return newFiltros;
    });
    setOpenModalFiltro(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formData = new FormData();

    if (registroFotografico !== null) {
      formData.append("avatar", registroFotografico);
    }

    formData.append("id", String(idAtendimento));
    formData.append("estado", estado);
    formData.append("municipio", municipio);
    formData.append("comunidade", comunidade);
    formData.append("demandante", stakeholder);
    formData.append("status", "Em Andamento");
    formData.append("descricao", descricao);
    formData.append("subclasse", subCategoria);
    formData.append("tratativa", tratativa);
    formData.append("categoria", categoria);
    formData.append("incidentePotencial", String(incidentePotencial));
    formData.append("contato", contato);
    formData.append("responsavel", responsavel);
    formData.append("complexidade", complexidade);
    formData.append("tipoAtendimento", tipoAtendimento);
    formData.append("planoAcao", planoAcao);
    solicitacao && formData.append("solicitacao", solicitacao)
    previsao && formData.append("previsao", previsao);
    resposta && formData.append("resposta", resposta);
    prazo && formData.append("prazo", prazo);
    conclusao && formData.append("conclusao", conclusao);
    selectedCoordinates && formData.append("lat", String(selectedCoordinates?.lat))
    selectedCoordinates && formData.append("lng", String(selectedCoordinates?.lng))

    await Api.PutAtendimento(formData, config)
      .then(() => {
        closeModal();
        updateDatagrid();
        toast.success("Registro alterado com sucesso!");
      })
      .catch((err) => {
        toast.error("Não foi possível alterar o registro!");
      });
  };

  const handleClearFields = () => {
    setDescricao(selectedRowData.descricao);
    setTratativa(selectedRowData.tratativa);
    setStakeholder(selectedRowData.stakeholder);
    setContato(selectedRowData.contato);
    setResponsavel(selectedRowData.responsavel);
    setCategoria(selectedRowData.categoria);
    setSubCategoria(selectedRowData.subclasse);
    setEstado(selectedRowData.estado);
    setMunicipio(selectedRowData.municipio);
    setComunidade(selectedRowData.comunidade);
    setComplexidade(selectedRowData.complexidade);
    setTipoAtendimento(selectedRowData.tipo_atendimento);
    setPlanoAcao(selectedRowData.plano_acao);
    setIncidentePotencial(selectedRowData.incidente_potencial);
    setPrazo(selectedRowData.dt_prazo);
    setSolicitacao(selectedRowData.dt_solicitacao);
    setPrevisao(selectedRowData.dt_previsao);
    setResposta(selectedRowData.dt_resposta);
    setConclusao(selectedRowData.dt_conclusao);
    setRegistroFotografico(null);
  };

  const handleDelete = (event: any) => {
    event.preventDefault();
    Api.DeleteAtendimento(selectedRowData.id, config)
      .then((res) => {
        handleCloseModalDelete();
        closeModal();
        toast.success("Registro deletado com sucesso!");
      })
      .catch((err) => {
        toast.error("Não foi possível deletar o registro!");
      });
  };

  const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
    const handleViewImage = () => {
      const overlayElement = document.createElement("div");
      overlayElement.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      `;

      let imgElement: HTMLImageElement | null = document.createElement("img");

      imgElement.onload = () => {
        if (imgElement && imgElement.complete) {
          overlayElement.appendChild(imgElement);
        } else {
          showNoImageMessage();
        }
      };

      imgElement.onerror = () => {
        showNoImageMessage();
      };

      const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
      const linkConcat = `${linkBackend}${formattedImageUrl}`;
      const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
      imgElement.src = linkCompleto;
      imgElement.alt = "Imagem";
      imgElement.style.cssText = `
              max-height: 80vh;
              max-width: 100%;
            `;

      const showNoImageMessage = () => {
        const noImageMessage = document.createElement("div");
        noImageMessage.textContent = "Nenhuma imagem disponível";
        noImageMessage.style.cssText = `
          color: white;
          font-size: 20px;
        `;

        overlayElement.appendChild(noImageMessage);
      };

      overlayElement.addEventListener("click", () => {
        document.body.removeChild(overlayElement);
      });

      document.body.appendChild(overlayElement);
    };

    return (
      <Col sm={12}>
        <Col sm={6}>
          <button
            onClick={handleViewImage}
            className="botao-grid"
            type="button"
          >
            {t("Visualizar foto")}
          </button>
        </Col>
      </Col>
    );
  };

  const getRangeText = (range: DateRange): string => {
    if (!range?.from) return "";

    return (
      format(range.from, "dd/MM/yyyy", { locale: ptBR }) +
      " à " +
      format(range.to || range.from, "dd/MM/yyyy", { locale: ptBR })
    );
  };

  const generateOptionsStakeholders = useCallback(() => {
    if (Array.isArray(AllStakeholders)) {
      return [
        { value: "", label: "Selecione Stakeholder..." },
        ...AllStakeholders.map(
          ({
            stakeholder,
            estado,
            municipio,
          }: {
            stakeholder: any;
            estado: any;
            municipio: any;
          }) => ({
            value: stakeholder,
            label: `${stakeholder} - ${municipio}`,
          })
        ),
      ];
    }

    return [];
  }, [AllStakeholders]);

  const optionsStakeholders = useMemo(
    () => generateOptionsStakeholders(),
    [generateOptionsStakeholders]
  );

  return (
    <Col sm={12}>
      <div className="grid-dados">
        <Col sm={12}>
          <Row>
            <Col sm={9}>
              {filtrosAtivos.map((filtro, i) => {
                const filterObj = tiposFiltro.find(
                  ({ value }) => value === filtro
                );
                if (!filterObj) return null;

                const { filter, setFilter, type, opts } = filterObj;

                return (
                  <Col className="filtro-ativo" key={i}>
                    <Row>
                      <Col sm={9}>
                        {type === "date" ? (
                          <>
                            <button
                              onClick={() => setShowPickerData(filtro)}
                              style={{
                                border: 0,
                                outline: 0,
                                backgroundColor: "#0000",
                                display: "block",
                                width: "100%",
                                height: "100%",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              <Form.Control
                                type="text"
                                placeholder={`Filtrar ${filtro}`}
                                value={getRangeText(filter as any)}
                                style={{ height: "73%" }}
                                onFocus={(e) => e.target.blur()}
                                readOnly
                              />
                            </button>
                            {showPickerData === filtro && (
                              <DateRangeInput
                                selected={filter as any}
                                onChange={setFilter}
                                onFinish={() => setShowPickerData(undefined)}
                              />
                            )}
                          </>
                        ) : type === "select" ? (
                          <Form.Select
                            value={filter?.toString() || ""}
                            onChange={(e) => {
                              setFilter(e.target.value as any);
                            }}
                            style={{ height: "73%" }}
                          >
                            <option label={`Filtrar ${filtro}`} value={""} />
                            {opts?.map((opt: any, i: any) => {
                              const isString = typeof opt === "string";

                              return (
                                <option
                                  key={i}
                                  label={isString ? opt : opt.label}
                                  value={isString ? opt : opt.value}
                                />
                              );
                            })}
                          </Form.Select>
                        ) : type === "municipio" ? (
                          <Select
                            className="mb-3"
                            value={filtroSelectedMunicipios?.map((municipio) => ({ label: municipio, value: municipio }))}
                            onChange={(selectedOptions) => {
                              const selectedMunicipios = selectedOptions?.map((option) => option.value) || [];
                              setFilter(selectedMunicipios);
                            }}
                            isMulti
                            options={opts?.map((opt: any) => {
                              const isString = typeof opt === "string";
                              return {
                                label: isString ? opt : opt.label,
                                value: isString ? opt : opt.value,
                              };
                            })}
                            placeholder="Selecione Município"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                height: "100%",
                                minHeight: "100%",
                                width: '135%',
                              }),
                            }}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            placeholder={`Filtrar ${filtro}`}
                            value={filter?.toString() || ""}
                            onChange={(e) => {
                              setFilter(e.target.value as any);
                            }}
                            style={{ height: "73%" }}
                          />
                        )}
                      </Col>
                      {type !== "municipio" && (
                        <Col sm={3}>
                          <button
                            onClick={() => {
                              setFilter(undefined);
                            }}
                            className="botao-filtro mt-2"
                          >
                            {t("Limpar Filtro")}
                          </button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                );
              })}
            </Col>
            <Col sm={3}>
              <button
                className="botao-filtro mt-2"
                onClick={() => setOpenModalFiltro(!openModalFiltro)}
              >
                {t("Filtros")}
              </button>
            </Col>
          </Row>

          {openModalFiltro && (
            <Col sm={12} className="mb-2">
              <Card>
                <Card.Body>
                  <Col sm={12} className="justify-content-center">
                    <Row>
                      {tiposFiltro.map((filtro, i) => (
                        <Col sm={3} key={i}>
                          <button
                            className={`filtros-modal ${filtrosAtivos?.includes(filtro.value)
                                ? "ativo"
                                : ""
                              }`}
                            onClick={() => {
                              handleModalFiltros(filtro.value);
                            }}
                          >
                            {filtro.label}
                          </button>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Col>



        <div>
          {!permissoesUser.atendimentos_view && (
            <div>
              <DataGrid rows={[]} columns={datagridColunas} />
            </div>
          )}
          {permissoesUser.atendimentos_view && (
            <DataGrid
              rows={dadosFiltrados}
              columns={datagridColunas}
              onRowClick={handleRowClick}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
            />
          )}
        </div>
        <div className="mt-2">
          <ButtonExportExcel lista={dadosFiltrados}></ButtonExportExcel>
        </div>
      </div>

      <Modal
        show={isModalOpen}
        onHide={closeModal}
        className="modal-xl"
        dialogClassName="modal-rollover"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Detalhes do Atendimento")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRowData && (
            <div id="content-grid">
              <Form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Col sm={12} className="mb-2">
                  <Col sm={12} className="mb-2">
                    <Row>
                      <Col sm={3}>
                        <Form.Label>{t("Categoria")}</Form.Label>
                        <Form.Select
                          className="formSelect"
                          value={categoria || ""}
                          onChange={(event) => {
                            setCategoria(event.target.value);
                          }}
                        >
                          <option value="">{t("Selecione a Categoria")}</option>
                          <option value="Relacionamento">
                            {t("Relacionamento")}
                          </option>
                          <option value="G. da Propriedade">
                            {t("G. da Propriedade")}
                          </option>
                          <option value="Demanda Ambiental">
                            {t("Demanda Ambiental")}
                          </option>
                          <option value="PAEBM">PAEBM</option>
                          <option value="Outros">{t("Outros")}</option>
                        </Form.Select>
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Sub-Categoria")}</Form.Label>
                        <Form.Select
                          className="formSelect"
                          value={subCategoria || ""}
                          onChange={(event) => {
                            setSubCategoria(event.target.value);
                          }}
                        >
                          <option value="">
                            {t("Selecione a Sub-Categoria")}
                          </option>

                          {categoria === "Relacionamento" ? (
                            <>
                              <option value="Destinação de Resíduos">
                                {t("Destinação de Resíduos")}
                              </option>
                              <option value="Filantropia">
                                {t("Filantropia")}
                              </option>
                              <option value="Patrocínio">
                                {t("Patrocínio")}
                              </option>
                              <option value="Segurança">
                                {t("Segurança")}
                              </option>
                              <option value="Visitas">{t("Visitas")}</option>
                            </>
                          ) : (
                            ""
                          )}

                          {categoria === "G. da Propriedade" ? (
                            <>
                              <option value="Documentação">
                                {t("Documentação")}
                              </option>
                              <option value="Divisas">{t("Divisas")}</option>
                              <option value="Acessos">{t("Acessos")}</option>
                              <option value="Arrendamento / Comodato">
                                {t("Arrendamento / Comodato")}
                              </option>
                              <option value="Invasões de Animais / Terceiros">
                                {t("Invasões de Animais / Terceiros")}
                              </option>
                              <option value="Aquisição de N. Propriedade">
                                {t("Aquisição de N. Propriedade")}
                              </option>
                            </>
                          ) : (
                            ""
                          )}

                          {categoria === "Demanda Ambiental" ? (
                            <>
                              <option value="Água">{t("Água")}</option>
                              <option value="Alagamentos">
                                {t("Alagamentos")}
                              </option>
                              <option value="Queimadas">
                                {t("Queimadas")}
                              </option>
                              <option value="Poeira">{t("Poeira")}</option>
                              <option value="Educação Ambiental">
                                {t("Educação Ambiental")}
                              </option>
                              <option value="Emissões">{t("Emissões")}</option>
                              <option value="Licenciamento">
                                {t("Licenciamento")}
                              </option>
                              <option value="Vegetação">
                                {t("Vegetação")}
                              </option>
                              <option value="Ruídos">{t("Ruídos")}</option>
                            </>
                          ) : (
                            ""
                          )}

                          {categoria === "PAEBM" ? (
                            <>
                              <option value="Acessos">{t("Acessos")}</option>
                              <option value="Rotas de Fuga">
                                {t("Rotas de Fuga")}
                              </option>
                              <option value="Sirenes">{t("Sirenes")}</option>
                              <option value="Água">{t("Água")}</option>
                              <option value="Informações / Documentos">
                                {t("Informações / Documentos")}
                              </option>
                              <option value="Pedidos Diversos">
                                {t("Pedidos Diversos")}
                              </option>
                              <option value="Treinamento / Simulado">
                                {t("Treinamento / Simulado")}
                              </option>
                            </>
                          ) : (
                            ""
                          )}

                          {categoria === "Outros" ? (
                            <>
                              <option value="Danos Materiais">
                                {t("Danos Materiais")}
                              </option>
                              <option value="Empregos">{t("Empregos")}</option>
                              <option value="Outros">{t("Outros")}</option>
                            </>
                          ) : (
                            ""
                          )}
                        </Form.Select>
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Estado")}</Form.Label>
                        <Form.Select
                          className="formSelect"
                          required
                          value={estado || ""}
                          onChange={(e) => {
                            setEstado(e.target.value);
                          }}
                        >
                          <option value={""}>{t("Selecione o estado")}</option>
                          {listaEstados?.map((estado: any) => (
                            <option
                              value={estado.sigla}
                              key={estado.sigla}
                              label={estado.nome}
                            />
                          ))}
                        </Form.Select>
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Municipio")}</Form.Label>
                        <Form.Select
                          className="formSelect"
                          required
                          value={municipio || ""}
                          onChange={(e) => {
                            setMunicipio(e.target.value);
                          }}
                        >
                          <option value={""}>
                            {t("Selecione o municipio")}
                          </option>
                          {listaMunicipios[estado]?.map((municipio: any) => (
                            <option
                              value={municipio}
                              key={municipio}
                              label={municipio}
                            />
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={12} className="mt-2">
                    <Row>
                      <Col sm={3}>
                        <Form.Label>{t("Comunidade")}</Form.Label>
                        <Form.Select
                          className="formSelect"
                          required
                          value={comunidade || ""}
                          onChange={(e) => {
                            setComunidade(e.target.value);
                          }}
                        >
                          <option value={""}>
                            {t("Selecione a comunidade")}
                          </option>
                          {listaComunidades[municipio]?.map(
                            (comunidade: any) => (
                              <option
                                value={comunidade}
                                key={comunidade}
                                label={comunidade}
                              />
                            )
                          )}
                        </Form.Select>
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Complexidade")}</Form.Label>
                        <Form.Select
                          className="formSelect"
                          value={complexidade || ""}
                          onChange={(event) => {
                            setComplexidade(event.target.value);
                          }}
                        >
                          <option value="">
                            {t("Selecione a Complexidade")}
                          </option>
                          <option value="Alto">{t("Alto")}</option>
                          <option value="Médio">{t("Médio")}</option>
                          <option value="Baixo">{t("Baixo")}</option>
                        </Form.Select>
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Tipo de Atendimento")}</Form.Label>
                        <Form.Select
                          className="formSelect"
                          value={tipoAtendimento || ""}
                          onChange={(event) => {
                            setTipoAtendimento(event.target.value);
                          }}
                        >
                          <option value="">{t("Selecione")}</option>
                          <option value="Regular">{t("Regular")}</option>
                          <option value="Prioritário">
                            {t("Prioritário")}
                          </option>
                        </Form.Select>
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Stakeholder")}</Form.Label>
                        {/* <Select
                            onChange={(e: any) => {
                              setStakeholder(e.value)
                            }}
                            options={optionsStakeholders}
                            value={optionsStakeholders.find(
                              (option: any) =>
                                option.value === stakeholder
                            )}
                            isSearchable={true}
                            placeholder="Selecione Stakeholder"
                          /> */}
                        <Form.Control
                          className="formInput"
                          type={"text"}
                          required
                          value={stakeholder || ""}
                          onChange={(e) => {
                            setStakeholder(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={12} className="mt-2">
                    <Row>
                      <Col sm={6}>
                        <Form.Label>{t("Tratativa")}</Form.Label>
                        <Form.Control
                          className="formInput"
                          type={"text"}
                          as="textarea"
                          required
                          value={tratativa || ""}
                          onChange={(e) => {
                            setTratativa(e.target.value);
                          }}
                        />
                      </Col>

                      <Col sm={6}>
                        <Form.Label>{t("Descrição")}</Form.Label>
                        <Form.Control
                          className="formInput"
                          type={"text"}
                          as="textarea"
                          required
                          value={descricao || ""}
                          onChange={(e) => {
                            setDescricao(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={12} className="mt-2">
                    <Row>
                      <Col sm={4}>
                        <Form.Label>{t("Contato")}</Form.Label>
                        <Form.Control
                          className="formInput"
                          type={"text"}
                          required
                          value={contato || ""}
                          onChange={(e) => {
                            setContato(e.target.value);
                          }}
                        />
                      </Col>

                      <Col sm={4}>
                        <Form.Label>{t("Responsavel")}</Form.Label>
                        <Form.Control
                          className="formInput"
                          type={"text"}
                          required
                          value={responsavel || ""}
                          onChange={(e) => {
                            setResponsavel(e.target.value);
                          }}
                        />
                      </Col>

                      <Col sm={4}>
                        <Form.Label>{t("Prazo")}</Form.Label>
                        <Form.Control
                          className="formInput"
                          type="date"
                          value={prazo}
                          onChange={(e) => {
                            setPrazo(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={12} className="mt-2">
                    <Row>
                      <Col sm={3}>
                        <Form.Label>{t("Solicitação")}</Form.Label>

                        <Form.Control
                          className="formInput"
                          type="date"
                          value={solicitacao}
                          onChange={(e) => {
                            setSolicitacao(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Previsão")}</Form.Label>

                        <Form.Control
                          className="formInput"
                          type="date"
                          value={previsao}
                          onChange={(e) => {
                            setPrevisao(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Resposta")}</Form.Label>

                        <Form.Control
                          className="formInput"
                          type="date"
                          value={resposta}
                          onChange={(e) => {
                            setResposta(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}>
                        <Form.Label>{t("Conclusão")}</Form.Label>
                        <Form.Control
                          className="formInput"
                          type="date"
                          value={conclusao}
                          onChange={(e) => {
                            console.log("e.target.value", e.target.value)
                            setConclusao(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={12} className="mt-2">
                    <Row>
                      <Col sm={6} className="mt-3 p-2">
                        <Button onClick={handleOpenModal} style={{ width: "100%" }}>Editar Coordenadas</Button>
                        <MapModal
                          show={isModalMapOpen}
                          onClose={handleCloseModal}
                          onCoordinatesSelected={handleCoordinatesSelected}
                        />
                      </Col>
                      <Col sm={4} className="mt-3 p-2">
                        <Form.Check
                          className="formSwitch"
                          type="switch"
                          label={t("Incidente Potencial ?")}
                          checked={incidentePotencial}
                          onChange={(e) => {
                            setIncidentePotencial(e.target.checked);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={6} className="labelFotos mt-3 p-2">
                    <Form.Label>
                      Foto (Use arquivos .jpg, jpeg, png, e webp com no máximo
                      2048 kB)
                    </Form.Label>
                  </Col>

                  <Col sm={6} className="cadastroFotos mt-3">
                    <Form.Control
                      type="file"
                      accept="image/*"
                      name="avatar"
                      multiple
                      onChange={(e: any) => {
                        setRegistroFotografico(
                          e.target.files[0] ? e.target.files[0] : null
                        );
                      }}
                    />
                  </Col>

                  <Col sm={12}>
                    <Col sm={6}>
                      <ExibirImagem imageUrl={selectedRowData.foto} />
                    </Col>
                  </Col>

                  <Col sm={12}>
                    <Row>
                      <Col sm={6}>
                        {permissoesUser.atendimentos_edit ? (
                          <button className="botao-grid" onClick={handleSubmit}>
                            {t("Salvar")}
                          </button>
                        ) : (
                          <button
                            className="botao-grid"
                            onClick={() =>
                              toast.error(
                                "Você não tem permissão para editar um Atendimento!"
                              )
                            }
                          >
                            {t("Salvar")}
                          </button>
                        )}
                      </Col>

                      <Col sm={6}>
                        {permissoesUser.atendimentos_edit ? (
                          <button
                            className="botao-grid"
                            onClick={handleOpenModalDelete}
                            type="button"
                          >
                            {t("Excluir")}
                          </button>
                        ) : (
                          <button
                            className="botao-grid"
                            onClick={() =>
                              toast.error(
                                "Você não tem permissão para excluir um Atendimento!"
                              )
                            }
                            type="button"
                          >
                            {t("Excluir")}
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Form>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalDelete}
        onHide={handleCloseModalDelete}
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div id="modalLogin">
            <form className="form">
              <p className="titleForgotPassword">
                {t("Deseja excluir o registro ?")}
              </p>

              <button className="botao-grid" onClick={handleDelete}>
                {t("Sim")}
              </button>
              <button className="botao-grid" onClick={handleCloseModalDelete}>
                {t("Não")}
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </Col>
  );
}

export default GridAtendimento;
