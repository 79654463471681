import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Api, { linkBackend } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card } from "react-bootstrap";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import DateRangeInput from "../../../utils/DateRangeInput";
import { capitalize, capitalizeArray, toUpperArray } from "../../../utils/capitalize";
import treatArray from "../../../utils/treatArray";
import treatString from "../../../utils/treatString";
import ButtonExportExcel from "../../../components/buttonExportListExcel";

const SEP_NR = "/&nr/";
const SEP_DATE = "/&data/";
const SEP_AUTHOR = "/&autor/";

function tratarRespostas(respostas: string) {
    const responsesArray = [];

    if (respostas) {
        const treatedResponses = respostas.split(SEP_NR).map((resp) => {
            if (!resp) return null;

            const [message, rest] = resp.split(SEP_DATE);

            if (!rest) {
                responsesArray.push({ message, date: null, author: null });
                return null;
            }

            const [date, author] = rest.split(SEP_AUTHOR);

            const dateValues = date?.length === 10 && date.split("-").map((v) => Number(v));
            const newDate = dateValues && new Date(dateValues[0], dateValues[1] - 1, dateValues[2]);

            return {
                message,
                date: newDate,
                author,
            };
        });

        responsesArray.push(...treatedResponses);
    }

    return responsesArray;
}

function GridSolicitacoes({
    config,
    permissoesUser,
}: {
    config: any;
    toggleCard: any;
    permissoesUser: {
        administrador: boolean;
        atendimentos_create: boolean;
        atendimentos_edit: boolean;
        atendimentos_view: boolean;
        demandas_create: boolean;
        demandas_edit: boolean;
        demandas_view: boolean;
        engajamentos_create: boolean;
        engajamentos_edit: boolean;
        engajamentos_view: boolean;
        id: number;
        id_user: number;
        noticias_create: boolean;
        noticias_edit: boolean;
        noticias_view: boolean;
        notificacoes_create: boolean;
        notificacoes_edit: boolean;
        notificacoes_view: boolean;
        stakeholders_create: boolean;
        stakeholders_edit: boolean;
        stakeholders_view: boolean;
        usuario_create: boolean;
        usuario_edit: boolean;
        visitas_create: boolean;
        visitas_edit: boolean;
        visitas_view: boolean;
        sms_view: boolean;
        sms_create: boolean;
        solicitacoes_view: boolean;
        solicitacoes_edit: boolean;
    };
}) {
    let userStorage = sessionStorage.getItem("loginUsuario") as string;
    let user = JSON.parse(userStorage);

    const { t } = useTranslation();
    const navegar = useNavigate();
    const [datagridRows, setDatagridRows] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModalFiltro, setOpenModalFiltro] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);

    const [listaEstados, setListaEstados] = useState<any>([]);
    const [listaMunicipios, setListaMunicipios] = useState<any>([]);
    const [listaLocalidades, setListaLocalidades] = useState<any>([]);

    const [idSolicitacao, setIdSolicitacao] = useState<number>(0);

    //input
    const [nome, setNome] = useState<string>("");
    const [telefone, setTelefone] = useState<string>("");
    const [comunidade, setComunidade] = useState<string>("");
    const [solicitacao, setSolicitacao] = useState<string>("");
    const [novaResposta, setNovaResposta] = useState<string>("");
    const [historicoResposta, setHistoricoResposta] = useState<string>("");
    const [motivo, setMotivo] = useState<string>("");
    const [falouFuncionario, setFalouFuncionario] = useState<boolean>(false);
    const [funcionario, setFuncionario] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [data, setData] = useState<Date>();
    const [idSolicitante, setIdSolicitante] = useState<string>("");
    const [gerarAtendimento, setGerarAtendimento] = useState<boolean>(false);

    //Select
    const [estado, setEstado] = useState<string>("");
    const [municipio, setMunicipio] = useState<string>("");

    const [toastExibido, setToastExibido] = useState(false);

    useEffect(() => {
        if (!permissoesUser.solicitacoes_view && !toastExibido) {
            toast.error("Você não tem permissão para visualizar Solicitações!");
            setToastExibido(true);
        }
    }, [permissoesUser.solicitacoes_view, toastExibido]);

    const [
        nomesExistentes,
        estadosExistentes,
        municipiosExistentes,
        localidadesExistentes,
        motivosExistentes,
    ] = useMemo(() => {
        const nomes: string[] = [];
        const estados: string[] = [];
        const municipios: string[] = [];
        const localidades: string[] = [];
        const motivos: string[] = [];

        datagridRows.forEach((r) => {
            const estado = toUpperArray(treatArray(r.estado))?.filter(
                (v) => v && !estados.includes(v)
            );
            const municipio = capitalizeArray(treatArray(r.municipio))?.filter(
                (v) => v && !municipios.includes(v)
            );
            const localidade = capitalizeArray(treatArray(r.localidade))?.filter(
                (v) => v && !localidades.includes(v)
            );
            const nome = capitalize(treatString(r.nome));
            const motivo = treatString(r.motivo);

            if (estado) estados.push(...estado);
            if (municipio) municipios.push(...municipio);
            if (localidade) localidades.push(...localidade);
            if (nome && !nomes.includes(nome)) nomes.push(nome);
            if (motivo && !motivos.includes(motivo)) motivos.push(motivo);
        });

        return [
            nomes.sort((a, b) => a.localeCompare(b)),
            estados.sort((a, b) => a.localeCompare(b)),
            municipios.sort((a, b) => a.localeCompare(b)),
            localidades.sort((a, b) => a.localeCompare(b)),
            motivos.sort((a, b) => a.localeCompare(b)),
        ];
    }, [datagridRows]);

    //filtros
    const [filtroNome, setFiltroNome] = useState<string>();
    const [filtroMotivo, setFiltroMotivo] = useState<string>();
    const [filtroStatus, setFiltroStatus] = useState<string>();
    const [filtroEstado, setFiltroEstado] = useState<string>();
    const [filtroMunicipio, setFiltroMunicipio] = useState<string>();
    const [filtroLocalidade, setFiltroLocalidade] = useState<string>();
    const [filtroData, setFiltroData] = useState<DateRange>();

    const [showPickerData, setShowPickerData] = useState<string>();

    const tiposFiltro = [
        {
            value: "Nome",
            label: t("Nome"),
            type: "select",
            opts: nomesExistentes,
            filter: filtroNome,
            setFilter: setFiltroNome,
        },
        {
            value: "Motivo",
            label: t("Motivo"),
            type: "select",
            opts: motivosExistentes,
            filter: filtroMotivo,
            setFilter: setFiltroMotivo,
        },
        {
            value: "Status",
            label: t("Status"),
            type: "select",
            opts: [
                { label: t("Em Andamento"), value: "Em Andamento" },
                { label: t("Concluido"), value: "Concluido" },
            ],
            filter: filtroStatus,
            setFilter: setFiltroStatus,
        },
        {
            value: "Estado",
            label: t("Estado"),
            type: "select",
            opts: estadosExistentes,
            filter: filtroEstado,
            setFilter: setFiltroEstado,
        },
        {
            value: "Municipio",
            label: t("Municipio"),
            type: "select",
            opts: municipiosExistentes,
            filter: filtroMunicipio,
            setFilter: setFiltroMunicipio,
        },
        {
            value: "Localidade",
            label: t("Localidade"),
            type: "select",
            opts: localidadesExistentes,
            filter: filtroLocalidade,
            setFilter: setFiltroLocalidade,
        },
        {
            value: "Data",
            label: t("Data"),
            type: "date",
            filter: filtroData,
            setFilter: setFiltroData,
        },
    ];

    const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

    const dadosFiltrados = useMemo(
        () =>
            datagridRows.filter(
                (v) =>
                    (!filtroNome || v?.nome?.toLowerCase().includes(filtroNome?.toLowerCase())) &&
                    (!filtroMotivo ||
                        v.motivo?.toLowerCase().includes(filtroMotivo?.toLowerCase())) &&
                    (!filtroStatus ||
                        v.status?.toLowerCase().includes(filtroStatus?.toLowerCase())) &&
                    (!filtroEstado ||
                        v.estado?.toLowerCase().includes(filtroEstado?.toLowerCase())) &&
                    (!filtroMunicipio ||
                        v.municipio?.toLowerCase().includes(filtroMunicipio?.toLowerCase())) &&
                    (!filtroLocalidade ||
                        v.localidade?.toLowerCase().includes(filtroLocalidade?.toLowerCase())) &&
                    (!filtroData ||
                        (v.dt_solicitacao >= (filtroData.from || new Date()) &&
                            v.dt_solicitacao <= (filtroData.to || new Date())))
            ),
        [
            datagridRows,
            filtroStatus,
            filtroEstado,
            filtroMunicipio,
            filtroData,
            filtroLocalidade,
            filtroMotivo,
            filtroNome,
        ]
    );

    useEffect(() => {
        if (!selectedRowData) return;

        setIdSolicitacao(selectedRowData.id || 0);
        setNome(selectedRowData.nome || "");
        setTelefone(selectedRowData.telefone || "");
        setSolicitacao(selectedRowData.solicitacao || "");
        setHistoricoResposta(selectedRowData.resposta || "");
        setMotivo(selectedRowData.motivo || "");
        setFalouFuncionario(selectedRowData.teve_contato || "");
        setFuncionario(selectedRowData.funcionario || "");
        setStatus(selectedRowData.status || "");
        setIdSolicitante(selectedRowData.id_solicitante || "");
        setEstado(selectedRowData.estado || "");
        setMunicipio(selectedRowData.municipio || "");
        setComunidade(selectedRowData.localidade || "");
        setData(selectedRowData.dt_solicitacao);
        setGerarAtendimento(false);
    }, [selectedRowData]);

    const handleRowClick = (params: any) => {
        setSelectedRowData(params.row);
        setIsModalOpen(true);
    };

    const datagridColunas: readonly GridColDef<any>[] = [
        {
            field: "dt_solicitacao",
            headerName: t("Data da Solicitação") as string,
            type: "date",
            width: 150,
            valueGetter: ({ row }: { row: any }) => new Date(row.dt_solicitacao),
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                return params.value && params.value instanceof Date
                    ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
                    : "";
            },
        },
        {
            field: "nome",
            headerName: t("header_Nome") as string,
            type: "string",
            width: 200,
        },
        {
            field: "telefone",
            headerName: t("Telefone") as string,
            type: "string",
            width: 200,
        },
        {
            field: "estado",
            headerName: t("header_Estado") as string,
            type: "string",
            width: 200,
        },
        {
            field: "municipio",
            headerName: t("header_Municipio") as string,
            type: "string",
            width: 200,
        },
        {
            field: "localidade",
            headerName: t("Localidade") as string,
            type: "string",
            width: 200,
        },
        {
            field: "motivo",
            headerName: t("header_Motivo") as string,
            type: "string",
            width: 200,
        },
        {
            field: "status",
            headerName: t("header_Status") as string,
            type: "string",
            width: 150,
        },
    ];

    const AddDia = (dataString: any) => {
        if (!dataString) return null;
        const data = new Date(dataString);
        data.setDate(data.getDate() + 1);
        return data
      };

    const updateDatagrid = useCallback(() => {
        if (!config || !navegar || !t) return;

        Api.GetSolicitacoes(config)
            .then((data: any[]) => {
                setDatagridRows(
                    data.map((d: any, index: number) => ({
                        ...d,
                        arrayId: index + 1,
                        dt_resposta: d.dt_resposta ? AddDia(d.dt_resposta) : null,
                        dt_solicitacao: d.dt_solicitacao ? AddDia(d.dt_solicitacao) : null,
                    }))
                );
            })
            .catch((err: any) => {
                if (typeof err === "string") {
                    toast.error(t(err));
                    navegar("/login");
                    return;
                }

                toast.error(t("Houve um erro ao carregar as solicitações"));
            });
    }, [config, navegar, t]);

    const updateLocales = useCallback(() => {
        Api.GetLocales().then((data) => {
            setListaEstados(data.localidades.estados);
            setListaMunicipios(data.localidades.municipios);
            setListaLocalidades(data.comunidades);
        });
    }, []);

    useEffect(updateDatagrid, [updateDatagrid]);
    useEffect(updateLocales, [updateLocales]);

    const handleModalFiltros = (filtro: string) => {
        setFiltroAtivo((filtros) => {
            const newFiltros = [...filtros];
            const fIndex = newFiltros.findIndex((f) => f === filtro);
            if (fIndex >= 0) {
                newFiltros.splice(fIndex, 1);
                tiposFiltro.find((f) => f.value === filtro)?.setFilter(undefined);
            } else {
                newFiltros.push(filtro);
            }
            return newFiltros;
        });
        setOpenModalFiltro(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const obj = {
            id: idSolicitacao,
            idAnalista: user.id,
            status: status,
            resposta:
                historicoResposta +
                "/&nr/" +
                novaResposta +
                "/&data/" +
                new Date().toISOString().split("T")[0] +
                "/&autor/" +
                user.nome +
                " - id analista: " +
                user.id,
            dtResposta: new Date(),
        };

        const camposAtendimento = {
            nome: nome,
            solicitacao: solicitacao,
            contato: telefone,
            estado: estado,
            municipio: municipio,
            comunidade: comunidade,
        };

        Api.PutSolicitacaoResposta(obj, config)
            .then((res) => {
                updateDatagrid();
                closeModal();
                toast.success("Solicitação respondida com sucesso!");
                if (gerarAtendimento) {
                    sessionStorage.setItem("atendimentoStorage", JSON.stringify(camposAtendimento));

                    navegar("/cadastro/atendimento");
                }
            })
            .catch((err) => {
                toast.error("Não foi possível responder a solicitação!");
            });
    };

    const responsesArray = useMemo(
        () => (historicoResposta ? tratarRespostas(historicoResposta) : []),
        [historicoResposta]
    );

    const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
        const handleViewImage = () => {
            const overlayElement = document.createElement("div");
            overlayElement.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      `;

            let imgElement: HTMLImageElement | null = document.createElement("img");

            imgElement.onload = () => {
                if (imgElement && imgElement.complete) {
                    overlayElement.appendChild(imgElement);
                } else {
                    showNoImageMessage();
                }
            };

            imgElement.onerror = () => {
                showNoImageMessage();
            };

            const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
            const linkConcat = `${linkBackend}${formattedImageUrl}`;
            const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
            imgElement.src = linkCompleto;
            imgElement.alt = "Imagem";
            imgElement.style.cssText = `
        max-height: 80vh;
        max-width: 100%;
      `;

            const showNoImageMessage = () => {
                const noImageMessage = document.createElement("div");
                noImageMessage.textContent = "Nenhuma imagem disponível";
                noImageMessage.style.cssText = `
          color: white;
          font-size: 20px;
        `;

                overlayElement.appendChild(noImageMessage);
            };

            overlayElement.addEventListener("click", () => {
                document.body.removeChild(overlayElement);
            });

            document.body.appendChild(overlayElement);
        };

        return (
            <Col sm={12}>
                <Col sm={6}>
                    <button onClick={handleViewImage} className="botao-grid" type="button">
                        {t("Visualizar foto")}
                    </button>
                </Col>
            </Col>
        );
    };

    const getRangeText = (range: DateRange): string => {
        if (!range?.from) return "";

        return (
            format(range.from, "dd/MM/yyyy", { locale: ptBR }) +
            " à " +
            format(range.to || range.from, "dd/MM/yyyy", { locale: ptBR })
        );
    };

    return (
        <Col sm={12}>
            <div className="grid-dados">
                <Col sm={12}>
                    <Row>
                        <Col sm={9}>
                            {filtrosAtivos.map((filtro, i) => {
                                const filterObj = tiposFiltro.find(({ value }) => value === filtro);
                                if (!filterObj) return null;

                                const { filter, setFilter, type, opts } = filterObj;

                                return (
                                    <Col className="filtro-ativo" key={i}>
                                        <Row>
                                            <Col sm={9}>
                                                {type === "date" ? (
                                                    <>
                                                        <button
                                                            onClick={() =>
                                                                setShowPickerData(filtro)
                                                            }
                                                            style={{
                                                                border: 0,
                                                                outline: 0,
                                                                backgroundColor: "#0000",
                                                                display: "block",
                                                                width: "100%",
                                                                height: "100%",
                                                                padding: 0,
                                                                margin: 0,
                                                            }}
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={`Filtrar ${filtro}`}
                                                                value={getRangeText(filter as any)}
                                                                style={{ height: "73%" }}
                                                                onFocus={(e) => e.target.blur()}
                                                                readOnly
                                                            />
                                                        </button>
                                                        {showPickerData === filtro && (
                                                            <DateRangeInput
                                                                selected={filter as any}
                                                                onChange={setFilter}
                                                                onFinish={() =>
                                                                    setShowPickerData(undefined)
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : type === "select" ? (
                                                    <Form.Select
                                                        value={filter?.toString() || ""}
                                                        onChange={(e) => {
                                                            setFilter(e.target.value as any);
                                                        }}
                                                        style={{ height: "73%" }}
                                                    >
                                                        <option
                                                            label={`Filtrar ${filtro}`}
                                                            value={""}
                                                        />
                                                        {opts?.map((opt, i) => {
                                                            const isString =
                                                                typeof opt === "string";

                                                            return (
                                                                <option
                                                                    key={i}
                                                                    label={
                                                                        isString ? opt : opt.label
                                                                    }
                                                                    value={
                                                                        isString ? opt : opt.value
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                    </Form.Select>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Filtrar ${filtro}`}
                                                        value={filter?.toString() || ""}
                                                        onChange={(e) => {
                                                            setFilter(e.target.value as any);
                                                        }}
                                                        style={{ height: "73%" }}
                                                    />
                                                )}
                                            </Col>
                                            <Col sm={3}>
                                                <button
                                                    onClick={() => {
                                                        setFilter(undefined);
                                                    }}
                                                    className="botao-filtro mt-2"
                                                >
                                                    {t("Limpar Filtro")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>
                        <Col sm={3}>
                            <button
                                className="botao-filtro mt-2"
                                onClick={() => setOpenModalFiltro(!openModalFiltro)}
                            >
                                {t("Filtros")}
                            </button>
                        </Col>
                    </Row>

                    {openModalFiltro && (
                        <Col sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <Col sm={12} className="justify-content-center">
                                        <Row>
                                            {tiposFiltro.map((filtro, i) => (
                                                <Col sm={3} key={i}>
                                                    <button
                                                        className={`filtros-modal ${
                                                            filtrosAtivos?.includes(filtro.value)
                                                                ? "ativo"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            handleModalFiltros(filtro.value);
                                                        }}
                                                    >
                                                        {filtro.label}
                                                    </button>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Col>

                <div>
                    {!permissoesUser.solicitacoes_view && (
                        <div>
                            <DataGrid rows={[]} columns={datagridColunas} />
                        </div>
                    )}
                    {permissoesUser.solicitacoes_view && (
                        <DataGrid
                            rows={dadosFiltrados}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    )}
                </div>
                <div className="mt-2">
                    <ButtonExportExcel lista={dadosFiltrados}></ButtonExportExcel>
                </div>
            </div>

            <Modal
                show={isModalOpen}
                onHide={closeModal}
                className="modal-xl"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Detalhes da Solicitação")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRowData && (
                        <div id="content-grid">
                            <Col sm={12} className="mb-2">
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={3}>
                                            <Form.Label>{t("Nome")}</Form.Label>
                                            <Form.Control
                                                className="formInput"
                                                type={"text"}
                                                disabled
                                                value={nome}
                                                onChange={(e) => {
                                                    setNome(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>{t("Telefone")}</Form.Label>
                                            <Form.Control
                                                className="formInput"
                                                type={"text"}
                                                disabled
                                                value={telefone}
                                                onChange={(e) => {
                                                    setTelefone(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>{t("Data da solicitação")}</Form.Label>
                                            <Form.Control
                                                className="formInput"
                                                type={"date"}
                                                disabled
                                                value={
                                                    data
                                                        ? format(data, "yyyy-MM-dd", {
                                                              locale: ptBR,
                                                          })
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    setData(
                                                        e.target.value
                                                            ? new Date(e.target.value)
                                                            : undefined
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>{t("ID solicitante")}</Form.Label>
                                            <Form.Control
                                                className="formInput"
                                                type={"text"}
                                                disabled
                                                value={idSolicitante}
                                                onChange={(e) => {
                                                    setIdSolicitante(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col sm={12} className="mt-4 mb-4">
                                    <Row>
                                        <Col sm={3}>
                                            <Form.Label>{t("Estado")}</Form.Label>
                                            <Form.Select
                                                disabled
                                                value={estado}
                                                onChange={(e) => {
                                                    setEstado(e.target.value);
                                                }}
                                            >
                                                <option value={""}>
                                                    {t("Selecione o estado")}
                                                </option>
                                                {listaEstados?.map((estado: any) => {
                                                    return (
                                                        <option value={estado.sigla}>
                                                            {estado.nome}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>{t("Municipio")}</Form.Label>
                                            <Form.Select
                                                disabled
                                                value={municipio}
                                                onChange={(e) => {
                                                    setMunicipio(e.target.value);
                                                }}
                                            >
                                                <option value={""}>
                                                    {t("Selecione o municipio")}
                                                </option>
                                                {listaMunicipios[estado]?.map((municipio: any) => {
                                                    return (
                                                        <option value={municipio}>
                                                            {municipio}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>{t("Comunidade")}</Form.Label>
                                            <Form.Select
                                                className="formSelect"
                                                disabled
                                                value={comunidade}
                                                onChange={(e) => {
                                                    setComunidade(e.target.value);
                                                }}
                                            >
                                                <option value={""}>
                                                    {t("Selecione a comunidade")}
                                                </option>
                                                {listaLocalidades[municipio]?.map(
                                                    (comunidade: any) => {
                                                        return (
                                                            <option value={comunidade}>
                                                                {comunidade}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Form.Select>
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>{t("Motivo")}</Form.Label>
                                            <Form.Select
                                                disabled
                                                value={motivo}
                                                onChange={(event) => {
                                                    setMotivo(event.target.value);
                                                }}
                                            >
                                                <option value="">{t("Motivo")}</option>
                                                <option value="Solicitação">
                                                    {t("Solicitação")}
                                                </option>
                                                <option value="Reclamação">
                                                    {t("Reclamação")}
                                                </option>
                                                <option value="Dúvidas e Sugestão">
                                                    {t("Dúvidas e Sugestão")}
                                                </option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col sm={12} className="mt-4 mb-4">
                                    <Row>
                                        <Col sm={3}>
                                            <Form.Label>{t("Status")}</Form.Label>
                                            <Form.Select
                                                value={status}
                                                onChange={(event) => {
                                                    setStatus(event.target.value);
                                                }}
                                            >
                                                <option value="">{t("Status")}</option>
                                                <option value="Em Andamento">
                                                    {t("Em andamento")}
                                                </option>
                                                <option value="Concluido">{t("Concluído")}</option>
                                            </Form.Select>
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>{t("Gerar atendimento?")}</Form.Label>
                                            <Form.Check
                                                type="switch"
                                                checked={gerarAtendimento}
                                                onChange={(e) => {
                                                    setGerarAtendimento(e.target.checked);
                                                }}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Label>{t("Falou com funcionário")}</Form.Label>
                                            <Form.Check
                                                type="switch"
                                                disabled
                                                checked={falouFuncionario}
                                                onChange={(e) => {
                                                    setFalouFuncionario(e.target.checked);
                                                }}
                                            />
                                        </Col>
                                        {falouFuncionario ? (
                                            <Col sm={3}>
                                                <Form.Label>{t("Nome do Funcionario")}</Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    disabled
                                                    value={funcionario}
                                                    onChange={(e) => {
                                                        setFuncionario(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                        ) : (
                                            <></>
                                        )}
                                    </Row>
                                </Col>

                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Label>{t("Solicitação")}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                className="formInput"
                                                disabled
                                                value={solicitacao}
                                                onChange={(e) => {
                                                    setSolicitacao(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col sm={12} className="mt-4 mb-4">
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Label>{t("Histórico de resposta")}</Form.Label>
                                            <div
                                                style={{
                                                    maxHeight: "100px",
                                                    overflow: "auto",
                                                }}
                                            >
                                                {responsesArray.map((item) => {
                                                    if (!item) return <></>;

                                                    return (
                                                        <>
                                                            <p>{item?.author || ""}</p>
                                                            <p>{item.message}</p>
                                                            {item.date && (
                                                                <p>
                                                                    {item.date?.toLocaleDateString()}
                                                                </p>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Label>{t("Nova resposta")}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                className="formInput"
                                                required
                                                value={novaResposta}
                                                onChange={(e) => {
                                                    setNovaResposta(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12}>
                                    <Col sm={6}>
                                        <ExibirImagem imageUrl={selectedRowData.foto} />
                                    </Col>
                                </Col>

                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12}>
                                            {permissoesUser.solicitacoes_edit ? (
                                                <button
                                                    className="botao-grid"
                                                    onClick={handleSubmit}
                                                >
                                                    {t("Salvar")}
                                                </button>
                                            ) : (
                                                <button
                                                    className="botao-grid"
                                                    onClick={() =>
                                                        toast.error(
                                                            "Você não tem permissão para editar uma Solicitação!"
                                                        )
                                                    }
                                                >
                                                    {t("Salvar")}
                                                </button>
                                            )}
                                        </Col>
                                        {/* <Col sm={6}>
                                            <button
                                                className="botao-grid"
                                                onClick={handleClearFields}
                                            >
                                                {t("Limpar")}
                                            </button>
                                        </Col> */}
                                    </Row>
                                </Col>
                            </Col>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default GridSolicitacoes;
