import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./styles.css";
import { Col, Form, Row, Modal } from "react-bootstrap";
import Api from "../../config/api";
import { PainelSocialType } from "../../utils/types/painelSocial";
import { formatDateToYearMonth } from "../../utils/functions/convertDate";

function PainelSocial({
    config,
    permissoesUser,
}: {
    config: any;
    permissoesUser: {
        painelSocial_create: boolean;
    };
}) {
    const { t } = useTranslation();

    const [showModalRegister, setShowModalRegister] = useState<boolean>(false);

    const [unidade, setUnidade] = useState<string>();
    const [periodo, setPeriodo] = useState<string>();

    //Bloco Gestão de Relacionamento com Comunidades
    const [destaque, setDestaque] = useState<string>();

    //Bloco Desenvolvimento Social
    const [desenvolvimentoTitulo1, setDesenvolvimentoTitulo1] = useState<string>();
    const [desenvolvimentoSubtitulo1, setDesenvolvimentoSubtitulo1] = useState<string>();
    const [agua, setAgua] = useState<boolean>(false);
    const [desenvolvimentoConteudo1, setDesenvolvimentoConteudo1] = useState<string>();

    const [desenvolvimentoTitulo2, setDesenvolvimentoTitulo2] = useState<string>();
    const [desenvolvimentoSubtitulo2, setDesenvolvimentoSubtitulo2] = useState<string>();
    const [alimentacao, setAlimentacao] = useState<boolean>(false);
    const [desenvolvimentoConteudo2, setDesenvolvimentoConteudo2] = useState<string>();

    const [desenvolvimentoTitulo3, setDesenvolvimentoTitulo3] = useState<string>();
    const [desenvolvimentoSubtitulo3, setDesenvolvimentoSubtitulo3] = useState<string>();
    const [desenvolvimentoLocal, setDesenvolvimentoLocal] = useState<boolean>(false);
    const [desenvolvimentoConteudo3, setDesenvolvimentoConteudo3] = useState<string>();

    const [desenvolvimentoTitulo4, setDesenvolvimentoTitulo4] = useState<string>();
    const [desenvolvimentoSubtitulo4, setDesenvolvimentoSubtitulo4] = useState<string>();
    const [educacao, setEducacao] = useState<boolean>(false);
    const [desenvolvimentoConteudo4, setDesenvolvimentoConteudo4] = useState<string>();

    //Bloco Gestão Preventiva de Conflitos
    const [gestaoTitulo1, setGestaoTitulo1] = useState<string>();
    const [gestaoConteudo1, setGestaoConteudo1] = useState<string>();
    const [gestaoTitulo2, setGestaoTitulo2] = useState<string>();
    const [gestaoConteudo2, setGestaoConteudo2] = useState<string>();
    const [gestaoTitulo3, setGestaoTitulo3] = useState<string>();
    const [gestaoConteudo3, setGestaoConteudo3] = useState<string>();
    const [gestaoTitulo4, setGestaoTitulo4] = useState<string>();
    const [gestaoConteudo4, setGestaoConteudo4] = useState<string>();

    //imagem
    const [registroFotografico, setRegistroFotografico] = useState<string | null>(null);
    const [fotoAgua, setfotoAgua] = useState(null);
    const [fotoAlimentacao, setFotoAlimentacao] = useState(null);
    const [fotoDevLocal, setFotoDevLocal] = useState(null);
    const [fotoEducacao, setFotoEducacao] = useState(null);

    const [listPainel, setListPainel] = useState([]);

    const [toastExibido, setToastExibido] = useState(false);

    useEffect(() => {
        const getPainel = async () => {
            const allResults = await Api.GetPaineisSociaisUnidade(config);
            console.log("allResults.data", allResults)
            setListPainel(allResults)
        }
        getPainel();
    }, [])

    useEffect(() => {
        if (!permissoesUser.painelSocial_create && !toastExibido) {
            toast.error(t("Você não tem permissão para criar um painel social!"));
            setToastExibido(true);
        }
    }, [permissoesUser.painelSocial_create, toastExibido, t]);

    const handleSubmit = async (event: any) => {
        setShowModalRegister(false);

        event.preventDefault();

        const formData = new FormData();

        unidade && formData.append("unidade", unidade);
        periodo && formData.append("dt_periodo", periodo);
        destaque && formData.append("destaque", destaque);

        registroFotografico && formData.append("avatar", registroFotografico);
        if (fotoAgua) formData.append("avatar1", fotoAgua);
        if (fotoAlimentacao) formData.append("avatar2", fotoAlimentacao);
        if (fotoDevLocal) formData.append("avatar3", fotoDevLocal);
        if (fotoEducacao) formData.append("avatar4", fotoEducacao);

        desenvolvimentoTitulo1 && formData.append("desenvolvimento_titulo1", desenvolvimentoTitulo1);
        desenvolvimentoSubtitulo1 && formData.append("desenvolvimento_subtitulo1", desenvolvimentoSubtitulo1);
        desenvolvimentoConteudo1 && formData.append("desenvolvimento_conteudo1", desenvolvimentoConteudo1);
        desenvolvimentoTitulo2 && formData.append("desenvolvimento_titulo2", desenvolvimentoTitulo2);
        desenvolvimentoSubtitulo2 && formData.append("desenvolvimento_subtitulo2", desenvolvimentoSubtitulo2);
        desenvolvimentoConteudo2 && formData.append("desenvolvimento_conteudo2", desenvolvimentoConteudo2);
        desenvolvimentoTitulo3 && formData.append("desenvolvimento_titulo3", desenvolvimentoTitulo3);
        desenvolvimentoSubtitulo3 && formData.append("desenvolvimento_subtitulo3", desenvolvimentoSubtitulo3);
        desenvolvimentoConteudo3 && formData.append("desenvolvimento_conteudo3", desenvolvimentoConteudo3);
        desenvolvimentoTitulo4 && formData.append("desenvolvimento_titulo4", desenvolvimentoTitulo4);
        desenvolvimentoSubtitulo4 && formData.append("desenvolvimento_subtitulo4", desenvolvimentoSubtitulo4);
        desenvolvimentoConteudo4 && formData.append("desenvolvimento_conteudo4", desenvolvimentoConteudo4);

        gestaoTitulo1 && formData.append("gestao_titulo1", gestaoTitulo1);
        gestaoConteudo1 && formData.append("gestao_conteudo1", gestaoConteudo1);
        gestaoTitulo2 && formData.append("gestao_titulo2", gestaoTitulo2);
        gestaoConteudo2 && formData.append("gestao_conteudo2", gestaoConteudo2);
        gestaoTitulo3 && formData.append("gestao_titulo3", gestaoTitulo3);
        gestaoConteudo3 && formData.append("gestao_conteudo3", gestaoConteudo3);
        gestaoTitulo4 && formData.append("gestao_titulo4", gestaoTitulo4);
        gestaoConteudo4 && formData.append("gestao_conteudo4", gestaoConteudo4);

        await Api.PostPainelSocial(formData, config)
            .then((res) => {
                handleCloseModalRegister();
                handleClearFields();
                toast.success(t("Cadastro feito com sucesso!"));
            })
            .catch((err) => {
                toast.error(t("Não foi possível fazer o cadastro!"));
            });
    };

    const handleClearFields = () => {
        setUnidade("");
        setPeriodo("");
        setDestaque("");
        setDesenvolvimentoSubtitulo1("");
        setDesenvolvimentoConteudo1("");
        setDesenvolvimentoSubtitulo2("");
        setDesenvolvimentoConteudo2("");
        setDesenvolvimentoSubtitulo3("");
        setDesenvolvimentoConteudo3("");
        setDesenvolvimentoSubtitulo4("");
        setDesenvolvimentoConteudo4("");
        setGestaoTitulo1("");
        setGestaoConteudo1("");
        setGestaoTitulo2("");
        setGestaoConteudo2("");
        setGestaoTitulo3("");
        setGestaoConteudo3("");
        setGestaoTitulo4("");
        setGestaoConteudo4("");
        setRegistroFotografico(null);
        // setfotoAgua(null);
        // setFotoAlimentacao(null);
        // setFotoDevLocal(null);
        // setFotoEducacao(null);

        setAgua(false);
        setAlimentacao(false);
        setDesenvolvimentoLocal(false);
        setEducacao(false);
    };

    const handleCloseModalRegister = () => {
        setShowModalRegister(false);
    };

    const handleOpenModalRegister = () => {
        switch (true) {
            case !unidade:
              toast.error("Unidade é obrigatória!");
              break;
            case !periodo:
              toast.error("Periodo é obrigatório!");
              break;
      
            default:
              setShowModalRegister(true);
              break;
        };

        if (agua === false) {
            setDesenvolvimentoTitulo1("");
        } else {
            setDesenvolvimentoTitulo1("Água");
        }

        if (alimentacao === false) {
            setDesenvolvimentoTitulo2("");
        } else {
            setDesenvolvimentoTitulo2("Alimentação");
        }

        if (desenvolvimentoLocal === false) {
            setDesenvolvimentoTitulo3("");
        } else {
            setDesenvolvimentoTitulo3("Desenvolvimento Local");
        }

        if (educacao === false) {
            setDesenvolvimentoTitulo4("");
        } else {
            setDesenvolvimentoTitulo4("Educação");
        }
    }

    useEffect(() => {
        const updateStateForUnit = (element: PainelSocialType) => {
            setPeriodo(formatDateToYearMonth(element.dt_periodo));
            setDestaque(element.destaque);
            setDesenvolvimentoSubtitulo1(element.desenvolvimento_subtitulo1);
            setDesenvolvimentoConteudo1(element.desenvolvimento_conteudo1);
            setDesenvolvimentoSubtitulo2(element.desenvolvimento_subtitulo2);
            setDesenvolvimentoConteudo2(element.desenvolvimento_conteudo2);
            setDesenvolvimentoSubtitulo3(element.desenvolvimento_subtitulo3);
            setDesenvolvimentoConteudo3(element.desenvolvimento_conteudo3);
            setDesenvolvimentoSubtitulo4(element.desenvolvimento_subtitulo4);
            setDesenvolvimentoConteudo4(element.desenvolvimento_conteudo4);
            setGestaoTitulo1(element.gestao_titulo1);
            setGestaoConteudo1(element.gestao_conteudo1);
            setGestaoTitulo2(element.gestao_titulo2);
            setGestaoConteudo2(element.gestao_conteudo2);
            setGestaoTitulo3(element.gestao_titulo3);
            setGestaoConteudo3(element.gestao_conteudo3);
            setGestaoTitulo4(element.gestao_titulo4);
            setGestaoConteudo4(element.gestao_conteudo4);
            setRegistroFotografico(element.foto);
    
            setAgua(element.desenvolvimento_titulo1 === "Água");
            setAlimentacao(element.desenvolvimento_titulo2 === "Alimentação");
            setDesenvolvimentoLocal(element.desenvolvimento_titulo3 === "Desenvolvimento Local");
            setEducacao(element.desenvolvimento_titulo4 === "Educação");
        };
    
        const selectedElement = listPainel.find(
            (element: PainelSocialType) => element.unidade === unidade
        );
    
        if (selectedElement) {
            updateStateForUnit(selectedElement);
        }
    }, [unidade, listPainel]);
    

    return (
        <Col sm={12} id="smsPage">
            <Form onSubmit={handleSubmit} onReset={handleClearFields}>
                <Col className="mt-4">
                    <Form.Text className="">
                        {t("Gestão de Relacionamento com Comunidades")}
                    </Form.Text>
                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={6}>
                                <Form.Label>{t("Unidade")}</Form.Label>
                                <Form.Select
                                    className="formSelect"
                                    value={unidade}
                                    required
                                    onChange={(event) => {
                                        setUnidade(event.target.value);
                                        if (
                                            event.target.value === "Araxá" ||
                                            event.target.value === "Patrocínio"
                                        ) {
                                            setGestaoTitulo1("Gestão da Propriedade");
                                            setGestaoTitulo2("Poeira e Emissões");
                                            setGestaoTitulo3("Acessos");
                                            setGestaoTitulo4("Prevenção de Incêndios");
                                        }
                                        if (event.target.value === "Tapira") {
                                            setGestaoTitulo1("Água");
                                            setGestaoTitulo2("Gestão da Propriedade");
                                            setGestaoTitulo3("Poeira e Emissões");
                                            setGestaoTitulo4("Prevenção de Incêndios");
                                        }
                                        if (event.target.value === "Uberaba") {
                                            setGestaoTitulo1("Água");
                                            setGestaoTitulo2("Gestão da Propriedade");
                                            setGestaoTitulo3("Animais Domésticos");
                                            setGestaoTitulo4("Estradas, Paradas e Empregos");
                                        }
                                        if (event.target.value === "Cajati") {
                                            setGestaoTitulo1("Tráfego de Veículos");
                                            setGestaoTitulo2(
                                                "Limpeza/Manutenção de Vias e do Entorno"
                                            );
                                            setGestaoTitulo3("Poeira e Emissões");
                                            setGestaoTitulo4("Pontos de Encontro");
                                        }
                                        if (event.target.value === "Paranaguá") {
                                            setGestaoTitulo1("Tráfego de Veículos");
                                            setGestaoTitulo2(
                                                "Limpeza/Manutenção de Vias e do Entorno"
                                            );
                                            setGestaoTitulo3("Poeira e Emissões");
                                            setGestaoTitulo4("Empregos");
                                        }
                                        if (
                                            event.target.value === "Catalão" ||
                                            event.target.value === "Sergipe"
                                        ) {
                                            setGestaoTitulo1("Água");
                                            setGestaoTitulo2("Gestão da Propriedade");
                                            setGestaoTitulo3("Poeira e Emissões");
                                            setGestaoTitulo4("Prevenção de Incêndios");
                                        }
                                    }}
                                >
                                    <option value="">{t("Selecione a Unidade")}</option>
                                    <option value="Araxá">{t("Araxá")}</option>
                                    <option value="Tapira">{t("Tapira")}</option>
                                    <option value="Cajati">{t("Cajati")}</option>
                                    <option value="Catalão">{t("Catalão")}</option>
                                    <option value="Patrocínio">{t("Patrocínio")}</option>
                                    <option value="Uberaba">{t("Uberaba")}</option>
                                    <option value="Paranaguá">{t("Paranaguá")}</option>
                                    <option value="Sergipe">{t("Sergipe")}</option>
                                </Form.Select>
                            </Col>
                            <Col sm={6}>
                                <Form.Label>{t("Periodo")}</Form.Label>
                                <Form.Control
                                    required
                                    className="formInput"
                                    type="month"
                                    value={periodo}
                                    onChange={(e) => {
                                        console.log("Periodo -->", e.target.value)
                                        console.log("Periodo type-->", typeof(e.target.value))
                                        setPeriodo(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12}>
                        <Form.Label>{t("Destaque")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            className="formInput"
                            value={destaque}
                            onChange={(e) => {
                                setDestaque(e.target.value);
                            }}
                        />
                    </Col>
                </Col>
                <hr></hr>
                <Col className="mt-4">
                    <Form.Text>{t("Desenvolvimento Social")}</Form.Text>
                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={3}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 10,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Form.Label>{t("Água")}</Form.Label>
                                    <Form.Check
                                        className="formSwitch"
                                        type="switch"
                                        checked={agua}
                                        onChange={(e) => {
                                            setAgua(e.target.checked);
                                        }}
                                    />
                                </div>
                                {agua === true ? (
                                    <>
                                        <Form.Control
                                            placeholder={t("Digite um subtitulo") || ""}
                                            className="formInput mb-4"
                                            type={"text"}
                                            value={desenvolvimentoSubtitulo1}
                                            onChange={(e) => {
                                                setDesenvolvimentoSubtitulo1(e.target.value);
                                            }}
                                        />
                                        <Form.Control
                                            placeholder={t("Escreva o conteudo") || ""}
                                            className="formInput"
                                            as="textarea"
                                            rows={3}
                                            value={desenvolvimentoConteudo1}
                                            onChange={(e) => {
                                                setDesenvolvimentoConteudo1(e.target.value);
                                            }}
                                        />
                                    </>
                                ) : (
                                    <Col sm={9} className="cadastroFotos mt-2">
                                    <Form.Control
                                        type="file"
                                        accept="image/*"
                                        name="avatar1"
                                        onChange={(e: any) => {
                                            toast.info("Foto de água selecionada")
                                            setfotoAgua(
                                                e.target.files[0] ? e.target.files[0] : null
                                            );
                                        }}
                                    />
                                </Col>
                                )}
                            </Col>
                            <Col sm={3}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 10,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Form.Label>{t("Alimentação")}</Form.Label>
                                    <Form.Check
                                        className="formSwitch"
                                        type="switch"
                                        checked={alimentacao}
                                        onChange={(e) => {
                                            setAlimentacao(e.target.checked);
                                        }}
                                    />
                                </div>
                                {alimentacao === true ? (
                                    <>
                                        <Form.Control
                                            placeholder={t("Digite um subtitulo") || ""}
                                            className="formInput mb-4"
                                            type={"text"}
                                            value={desenvolvimentoSubtitulo2}
                                            onChange={(e) => {
                                                setDesenvolvimentoSubtitulo2(e.target.value);
                                            }}
                                        />
                                        <Form.Control
                                            placeholder={t("Escreva o conteudo") || ""}
                                            className="formInput"
                                            as="textarea"
                                            rows={3}
                                            value={desenvolvimentoConteudo2}
                                            onChange={(e) => {
                                                setDesenvolvimentoConteudo2(e.target.value);
                                            }}
                                        />
                                    </>
                                ) : (
                                    <Col sm={9} className="cadastroFotos mt-2">
                                    <Form.Control
                                        type="file"
                                        accept="image/*"
                                        name="avatar2"
                                        onChange={(e: any) => {
                                            toast.info("Foto de Alimentação selecionada")
                                            setFotoAlimentacao(
                                                e.target.files[0] ? e.target.files[0] : null
                                            );
                                        }}
                                    />
                                </Col>
                                )}
                            </Col>
                            <Col sm={3}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 10,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Form.Label>{t("Dev. Local")}</Form.Label>
                                    <Form.Check
                                        className="formSwitch"
                                        type="switch"
                                        checked={desenvolvimentoLocal}
                                        onChange={(e) => {
                                            setDesenvolvimentoLocal(e.target.checked);
                                        }}
                                    />
                                </div>
                                {desenvolvimentoLocal === true ? (
                                    <>
                                        <Form.Control
                                            placeholder={t("Digite um subtitulo") || ""}
                                            className="formInput mb-4"
                                            type={"text"}
                                            value={desenvolvimentoSubtitulo3}
                                            onChange={(e) => {
                                                setDesenvolvimentoSubtitulo3(e.target.value);
                                            }}
                                        />
                                        <Form.Control
                                            placeholder={t("Escreva o conteudo") || ""}
                                            className="formInput"
                                            as="textarea"
                                            rows={3}
                                            value={desenvolvimentoConteudo3}
                                            onChange={(e) => {
                                                setDesenvolvimentoConteudo3(e.target.value);
                                            }}
                                        />
                                    </>
                                ) : (
                                    <Col sm={9} className="cadastroFotos mt-2">
                                    <Form.Control
                                        type="file"
                                        accept="image/*"
                                        name="avatar3"
                                        onChange={(e: any) => {
                                            toast.info("Foto de desenvolvimento local selecionada")
                                            setFotoDevLocal(
                                                e.target.files[0] ? e.target.files[0] : null
                                            );
                                        }}
                                    />
                                </Col>
                                )}
                            </Col>
                            <Col sm={3}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 10,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Form.Label>{t("Educação")}</Form.Label>
                                    <Form.Check
                                        className="formSwitch"
                                        type="switch"
                                        checked={educacao}
                                        onChange={(e) => {
                                            setEducacao(e.target.checked);
                                        }}
                                    />
                                </div>
                                {educacao === true ? (
                                    <>
                                        <Form.Control
                                            placeholder={t("Digite um subtitulo") || ""}
                                            className="formInput mb-4"
                                            type={"text"}
                                            value={desenvolvimentoSubtitulo4}
                                            onChange={(e) => {
                                                setDesenvolvimentoSubtitulo4(e.target.value);
                                            }}
                                        />
                                        <Form.Control
                                            placeholder={t("Escreva o conteudo") || ""}
                                            className="formInput"
                                            as="textarea"
                                            rows={3}
                                            value={desenvolvimentoConteudo4}
                                            onChange={(e) => {
                                                setDesenvolvimentoConteudo4(e.target.value);
                                            }}
                                        />
                                    </>
                                ) : (
                                    <Col sm={9} className="cadastroFotos mt-2">
                                    <Form.Control
                                        type="file"
                                        accept="image/*"
                                        name="avatar4"
                                        onChange={(e: any) => {
                                            toast.info("Foto de educação selecionada")
                                            setFotoEducacao(
                                                e.target.files[0] ? e.target.files[0] : null
                                            );
                                        }}
                                    />
                                </Col>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Col>
                <hr></hr>
                <Col className="mt-4">
                    <Form.Text>{t("Gestão Preventiva de Conflitos")}</Form.Text>
                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={3}>
                                <Form.Control
                                    disabled
                                    placeholder={t("Digite um titulo") || ""}
                                    className="formInput mb-4"
                                    type={"text"}
                                    value={gestaoTitulo1}
                                    onChange={(e) => {
                                        setGestaoTitulo1(e.target.value);
                                    }}
                                />
                                <Form.Control
                                    required
                                    placeholder={t("Escreva o conteudo") || ""}
                                    className="formInput"
                                    as="textarea"
                                    rows={3}
                                    value={gestaoConteudo1}
                                    onChange={(e) => {
                                        setGestaoConteudo1(e.target.value);
                                    }}
                                />
                            </Col>
                            <Col sm={3}>
                                <Form.Control
                                    disabled
                                    placeholder={t("Digite um titulo") || ""}
                                    className="formInput mb-4"
                                    type={"text"}
                                    value={gestaoTitulo2}
                                    onChange={(e) => {
                                        setGestaoTitulo2(e.target.value);
                                    }}
                                />
                                <Form.Control
                                    required
                                    placeholder={t("Escreva o conteudo") || ""}
                                    className="formInput"
                                    as="textarea"
                                    rows={3}
                                    value={gestaoConteudo2}
                                    onChange={(e) => {
                                        setGestaoConteudo2(e.target.value);
                                    }}
                                />
                            </Col>
                            <Col sm={3}>
                                <Form.Control
                                    disabled
                                    placeholder={t("Digite um titulo") || ""}
                                    className="formInput mb-4"
                                    type={"text"}
                                    value={gestaoTitulo3}
                                    onChange={(e) => {
                                        setGestaoTitulo3(e.target.value);
                                    }}
                                />
                                <Form.Control
                                    required
                                    placeholder={t("Escreva o conteudo") || ""}
                                    className="formInput"
                                    as="textarea"
                                    rows={3}
                                    value={gestaoConteudo3}
                                    onChange={(e) => {
                                        setGestaoConteudo3(e.target.value);
                                    }}
                                />
                            </Col>
                            <Col sm={3}>
                                <Form.Control
                                    disabled
                                    placeholder={t("Digite um titulo") || ""}
                                    className="formInput mb-4"
                                    type={"text"}
                                    value={gestaoTitulo4}
                                    onChange={(e) => {
                                        setGestaoTitulo4(e.target.value);
                                    }}
                                />
                                <Form.Control
                                    required
                                    placeholder={t("Escreva o conteudo") || ""}
                                    className="formInput"
                                    as="textarea"
                                    rows={3}
                                    value={gestaoConteudo4}
                                    onChange={(e) => {
                                        setGestaoConteudo4(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Col>
                <hr></hr>
                <Col sm={12} className="mt-3 mb-3">
                    <Col sm={12} className="labelFotos mt-3 p-2">
                        <Form.Label>
                            {t("Foto (Use arquivos .jpg, jpeg, png, e webp com no máximo 2048 kB)")}
                        </Form.Label>
                    </Col>
                    <Col sm={6} className="cadastroFotos mt-3">
                        <Form.Control
                            type="file"
                            accept="image/*"
                            name="avatar"
                            multiple
                            onChange={(e: any) => {
                                setRegistroFotografico(
                                    e.target.files[0] ? e.target.files[0] : null
                                );
                            }}
                        />
                    </Col>
                </Col>
                <hr></hr>
                <Col sm={12}>
                    <Row>
                        <Col sm={6}>
                            {permissoesUser.painelSocial_create ? (
                                <button
                                    className="botao-sms"
                                    type="button"
                                    onClick={handleOpenModalRegister}
                                >
                                    {t("Registrar")}
                                </button>
                            ) : (
                                <button
                                    className="botao-sms"
                                    type="button"
                                    onClick={() =>
                                        toast.error(
                                            "Você não tem permissão para criar um painel social!"
                                        )
                                    }
                                >
                                    {t("Registrar")}
                                </button>
                            )}
                        </Col>
                        <Col sm={6}>
                            <button className="botao-sms" type="reset">
                                {t("Limpar")}
                            </button>
                        </Col>
                    </Row>
                </Col>

                <Modal show={showModalRegister} onHide={handleCloseModalRegister} centered={true}>
                    <Modal.Header closeButton></Modal.Header>

                    <Modal.Body>
                        <div id="modalLogin">
                            <p className="titleForgotPassword">{t("Deseja enviar o cadastro?")}</p>

                            <button className="botao-grid" type="button" onClick={handleSubmit}>
                                {t("Sim")}
                            </button>

                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalRegister}
                            >
                                {t("Não")}
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </Form>
        </Col>
    );
}

export default PainelSocial;