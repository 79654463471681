import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./styles.css";
import { Col, Form, Row, Modal } from "react-bootstrap";
import Api from "../../config/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";

function Sms({
    config,
    permissoesUser,
    toggleCard,
}: {
    config: any;
    toggleCard: any;
    permissoesUser: {
        administrador: boolean;
        atendimentos_create: boolean;
        atendimentos_edit: boolean;
        atendimentos_view: boolean;
        demandas_create: boolean;
        demandas_edit: boolean;
        demandas_view: boolean;
        engajamentos_create: boolean;
        engajamentos_edit: boolean;
        engajamentos_view: boolean;
        id: number;
        id_user: number;
        noticias_create: boolean;
        noticias_edit: boolean;
        noticias_view: boolean;
        notificacoes_create: boolean;
        notificacoes_edit: boolean;
        notificacoes_view: boolean;
        stakeholders_create: boolean;
        stakeholders_edit: boolean;
        stakeholders_view: boolean;
        usuario_create: boolean;
        usuario_edit: boolean;
        visitas_create: boolean;
        visitas_edit: boolean;
        visitas_view: boolean;
        sms_view: boolean;
        sms_create: boolean;
        solicitacoes_view: boolean;
        solicitacoes_edit: boolean;
    };
}) {
    const { t } = useTranslation();

    const [showModalRegister, setShowModalRegister] = useState<boolean>(false);

    const animatedComponents = makeAnimated();

    let userStorage = sessionStorage.getItem("loginUsuario") as string;
    let user = JSON.parse(userStorage);

    //input
    const [titulo, setTitulo] = useState<string>("");
    const [mensagem, setMensagem] = useState<string>("");

    //select
    const [publico, setPublico] = useState<string>("");
    const [pessoas, setPessoas] = useState<{ value: string }[]>([]);
    const [estado, setEstado] = useState<string>("");
    const [municipio, setMunicipio] = useState<string>("");

    const [listaEstados, setListaEstados] = useState<any>([]);
    const [listaMunicipios, setListaMunicipios] = useState<any>([]);
    const [listaUsuarios, setListaUsuarios] = useState<any>([]);

    const [toastExibido, setToastExibido] = useState(false);

    useEffect(() => {
        if (!permissoesUser.sms_create && !toastExibido) {
            toast.error("Você não tem permissão para criar um novo SMS!");
            setToastExibido(true);
        }
    }, [permissoesUser.sms_create, toastExibido]);

    useEffect(() => {
        Api.GetLocales().then((data) => {
            setListaEstados(data.localidades.estados);
            setListaMunicipios(data.localidades.municipios);
        });
    }, []);

    useEffect(() => {
        Api.GetUsuarios(config).then((data) => {
            setListaUsuarios(data);
        });
    }, []);

    const listaUsuariosFiltrados = useMemo(() => {
        const publicoTipo =
            publico === "Analistas" ? 1 : publico === "Comunidade" ? 2 : null;

        if (publicoTipo === 1 || publicoTipo === 2) {
            return listaUsuarios && listaUsuarios.filter(
                (user: any) =>
                    user.municipio === municipio && publicoTipo === user.tipo
            );
        }

        return listaUsuarios.filter(
            (user: any) => user.municipio === municipio
        );
    }, [listaUsuarios, municipio, publico]);

    const handleSubmit = () => {
        const obj = {
            titulo: titulo,
            mensagem: mensagem,
            autor: user.nome,
            numero: pessoas,
        };

        Api.PostSms(obj, config)
            .then((res) => {
                handleCloseModalRegister();
                handleClearFields();
                toast.success(t("Cadastro feito com sucesso!"));
            })
            .catch((err) => {
                toast.error(t("Não foi possível fazer o cadastro!"));
            });
    };

    const handleClearFields = () => {
        setTitulo("");
        setMensagem("");
        setEstado("");
        setMunicipio("");
        setPublico("");
        setPessoas([]);
    };

    const handleCloseModalRegister = () => {
        setShowModalRegister(false);
    };

    const handleOpenModalRegister = () => {
        setShowModalRegister(true);
    };

    const options = listaUsuariosFiltrados.map((usuarioFiltrado: any) => ({
        value: usuarioFiltrado.telefone,
        label: usuarioFiltrado.nome,
    }));

    const handleSelectChange = (pessoasSelecionadas: { value: string }[]) => {
        setPessoas(pessoasSelecionadas);
    };
    return (
        <Col sm={12} id="smsPage">
            <Form onReset={handleClearFields}>
                <Col sm={12} className="mt-4 mb-4">
                    <Form.Label>{t("Titulo")}</Form.Label>
                    <Form.Control
                        className="formInput"
                        type={"text"}
                        required
                        value={titulo}
                        onChange={(e) => {
                            setTitulo(e.target.value);
                        }}
                    />
                    <Form.Label className="mt-4">{t("Mensagem")}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        className="formInput"
                        value={mensagem}
                        onChange={(e) => {
                            setMensagem(e.target.value);
                        }}
                    />
                </Col>
                <Col sm={12} className="mt-4 mb-4">
                    <Row>
                        <Col sm={6}>
                            <Form.Label>{t("Estado")}</Form.Label>
                            <Form.Select
                                required
                                value={estado}
                                onChange={(e) => {
                                    setEstado(e.target.value);
                                }}
                            >
                                <option value={""}>
                                    {t("Selecione o estado")}
                                </option>
                                {listaEstados?.map((estado: any) => {
                                    return (
                                        <option value={estado.sigla}>
                                            {estado.nome}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>{t("Municipio")}</Form.Label>
                            <Form.Select
                                required
                                value={municipio}
                                onChange={(e) => {
                                    setMunicipio(e.target.value);
                                }}
                            >
                                <option value={""}>
                                    {t("Selecione o municipio")}
                                </option>
                                {listaMunicipios[estado]?.map(
                                    (municipio: any) => {
                                        return (
                                            <option value={municipio}>
                                                {municipio}
                                            </option>
                                        );
                                    }
                                )}
                            </Form.Select>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} className="mt-4 mb-4">
                    <Row>
                        <Col sm={6}>
                            <Form.Label>{t("Publico")}</Form.Label>
                            <Form.Select
                                value={publico}
                                onChange={(event) => {
                                    setPublico(event.target.value);
                                }}
                            >
                                <option value="">{t("Publico")}</option>
                                <option value="Analistas">
                                    {t("Analistas")}
                                </option>
                                <option value="Comunidade">
                                    {t("Comunidade")}
                                </option>
                                <option value="Todos">{t("Todos")}</option>
                            </Form.Select>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>{t("Pessoas")}</Form.Label>
                            <Select
                                className="formSelect"
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                placeholder={"Pessoas"}
                                value={pessoas}
                                onChange={(pessoasSelecionadas: any) =>
                                    handleSelectChange(
                                        pessoasSelecionadas as {
                                            value: string;
                                        }[]
                                    )
                                }
                                options={options}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12}>
                    <Row>
                        <Col sm={6}>
                            {permissoesUser.sms_create ? (
                                <button
                                    className="botao-sms"
                                    type="button"
                                    onClick={handleOpenModalRegister}
                                >
                                    {t("Registrar")}
                                </button>
                            ) : (
                                <button
                                    className="botao-sms"
                                    type="button"
                                    onClick={() =>
                                        toast.error(
                                            "Você não tem permissão para criar um novo SMS!"
                                        )
                                    }
                                >
                                    {t("Registrar")}
                                </button>
                            )}
                        </Col>
                        <Col sm={6}>
                            <button className="botao-sms" type="reset">
                                {t("Limpar")}
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Form>
            <Modal
                show={showModalRegister}
                onHide={handleCloseModalRegister}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja enviar o cadastro?")}
                            </p>

                            <button
                                className="botao-grid"
                                onClick={handleSubmit}
                            >
                                {t("Sim")}
                            </button>
                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalRegister}
                            >
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default Sms;
