import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api, { ClearSessionStorage, linkBackend } from "../../config/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { Col, Row, Form, Card } from "react-bootstrap";
import {
    FiPhone,
    FiEdit,
    FiMail,
    FiUser,
    FiMapPin,
    FiSave,
    FiEyeOff,
    FiEye,
} from "react-icons/fi";
import fotoPadrao from "../../assets/images/userFoto.png";
import BotaoVoltar from "../../components/buttonReturnPage";

function Perfil() {
    const { t } = useTranslation();
    const navegar = useNavigate();

    let userStorage = sessionStorage.getItem("loginUsuario") as string;
    let user = JSON.parse(userStorage);

    const tokenUsuario = sessionStorage.getItem("tokenUsuario");
    let config = { headers: { authorization: tokenUsuario } };

    //input
    const [nome, setNome] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [telefone, setTelefone] = useState<string>("");

    const [novaSenha, setNovaSenha] = useState<string>("");
    const [confirmaSenha, setConfirmaSenha] = useState<string>("");

    const [fotoSelecionada, setFotoSelecionada] = useState(null);
    const [fotoCadastrada, setFotoCadastrada] = useState<string>("");

    //Select
    const [estado, setEstado] = useState<string>("");
    const [municipio, setMunicipio] = useState<string>("");

    const [listaEstados, setListaEstados] = useState<any>([]);
    const [listaMunicipios, setListaMunicipios] = useState<any>([]);

    const [passwordForm, setPasswordForm] = useState<boolean>(false);
    const [passwordType, setPasswordType] = useState("password");
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setNome(user.nome);
        setEmail(user.email);
        setTelefone(user.telefone);
        setEstado(user.estado);
        setMunicipio(user.municipio);
    }, []);

    useEffect(() => {
        isVisible ? setPasswordType("text") : setPasswordType("password");
    }, [isVisible]);

    useEffect(() => {
        Api.GetLocales().then((data) => {
            setListaEstados(data.localidades.estados);
            setListaMunicipios(data.localidades.municipios);
        });
    }, []);

    useEffect(() => {
        Api.GetFotoUsuario(user.id, config).then((data) => {
            setFotoCadastrada(data.foto);
        });
    }, []);

    useEffect(() => {
        const expirationUsuario = sessionStorage.getItem("expirationUsuario");
        if (!expirationUsuario || new Date() > new Date(expirationUsuario)) {
            ClearSessionStorage();
            navegar("/login");
        }
    }, []);

    const handleUpdatePassword = (event: any) => {
        event.preventDefault();
        const obj = {
            id: user.id,
            senha: novaSenha,
        };

        if (confirmaSenha !== novaSenha) {
            toast.error(t("Senhas digitadas não são iguais!"));
        } else {
            Api.PutSenhaUsuario(obj, config)
                .then((res) => {
                    toast.success(
                        t("Senha alterada com sucesso! Por favor faça login novamente.")
                    );
                    sessionStorage.removeItem("loginUsuario");
                    navegar("/login");
                })
                .catch((err) => {
                    toast.error(t("Não foi possível alterar a senha!"));
                });
        }
    };

    const handleUpdateProfile = (event: any) => {
        event.preventDefault();
        const obj = {
            id: user.id,
            nome: nome,
            login: telefone,
            email: email,
            telefone: telefone,
            tipo: user.tipo,
            estado: estado,
            municipio: municipio,
        };

        Api.PutPerfilUsuario(obj, config)
            .then((res) => {
                toast.success(
                    t("Perfil alterado com sucesso! Por favor faça login novamente.")
                );
                sessionStorage.removeItem("loginUsuario");
                navegar("/login");
            })
            .catch((err) => {
                toast.error(t("Não foi possível alterar o perfil!"));
            });
    };

    const handleUpdatePicture = async (event: any) => {
        event.preventDefault();

        const formData = new FormData();

        if (fotoSelecionada !== null) {
            formData.append("avatar", fotoSelecionada);
        }

        formData.append("id", user.id.toString());

        await Api.PutFotoUsuario(formData, config)
            .then((res) => {
                toast.success(t("Foto de perfil salva com sucesso!"));
                window.location.reload();
            })
            .catch((err) => {
                toast.error(t("Não foi possível savar a foto de perfil!"));
            });
    };

    const formatPicture = (foto: string) => {
        const fotoFormatada = foto ? foto.replace(/\\/g, "/") : "";
        return fotoFormatada;
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "10px", marginLeft: "7px" }}>
                <BotaoVoltar />
            </div>
            <div id="perfilPage" className="mb-5">
                <div id="perfilContent">
                    <Col sm={12}>
                        <Row>
                            <Col sm={4}>
                                <Card>
                                    <Col className="userPhotoAndUserName">
                                        <Form
                                            encType="multipart/form-data"
                                            onSubmit={handleUpdatePicture}
                                        >
                                            <label className="editUserPhoto">
                                                <Form.Control
                                                    type="file"
                                                    accept="image/*"
                                                    name="avatar"
                                                    multiple
                                                    onChange={(e: any) => {
                                                        setFotoSelecionada(
                                                            e.target.files[0]
                                                                ? e.target.files[0]
                                                                : null
                                                        );
                                                    }}
                                                />

                                                <div className="userPhotoDiv">
                                                    {!fotoSelecionada &&
                                                        !fotoCadastrada ? (
                                                        <img
                                                            src={fotoPadrao}
                                                            className="userPhoto"
                                                            alt="Foto de perfil"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={
                                                                fotoSelecionada
                                                                    ? URL.createObjectURL(
                                                                        fotoSelecionada
                                                                    )
                                                                    : new URL(
                                                                        formatPicture(
                                                                            fotoCadastrada
                                                                        ),
                                                                        linkBackend
                                                                    ).href
                                                            }
                                                            className="userPhoto"
                                                            alt="Foto de perfil"
                                                        />
                                                    )}
                                                </div>

                                                <div className="editUserPenDiv">
                                                    <FiEdit className="editUserPen" />
                                                </div>
                                            </label>
                                            {fotoSelecionada !== null ? (
                                                <button
                                                    type="submit"
                                                    className="submitPictureButton"
                                                >
                                                    {" "}
                                                    {t("Salvar Foto")}{" "}
                                                </button>
                                            ) : (
                                                <></>
                                            )}
                                        </Form>

                                        <span className="userName">{nome}</span>
                                    </Col>
                                </Card>

                                <Card className="mt-2 mb-3">
                                    {passwordForm === false ? (
                                        <>
                                            <Col sm={12}>
                                                <button className="buttonActivated">
                                                    {t("Editar Perfil")}
                                                </button>
                                            </Col>
                                            <Col sm={12}>
                                                <button
                                                    className="buttonDisabled"
                                                    onClick={() => {
                                                        setPasswordForm(true);
                                                    }}
                                                >
                                                    {t("Alterar Senha")}
                                                </button>
                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                className="buttonDisabled"
                                                onClick={() => {
                                                    setPasswordForm(false);
                                                }}
                                            >
                                                {t("Editar Perfil")}
                                            </button>
                                            <button className="buttonActivated">
                                                {t("Alterar Senha")}
                                            </button>
                                        </>
                                    )}
                                </Card>
                            </Col>
                            <Col sm={8}>
                                <Card className="cardPerfil mb-5">
                                    <Col>
                                        <div className="inputsPerfil">
                                            {passwordForm === false ? (
                                                <>
                                                    <Col sm={12}>
                                                        <Form.Label className="inputTitle">
                                                            {t("Nome")}
                                                            <div className="inputAndIcon">
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type={"text"}
                                                                    required
                                                                    value={nome}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setNome(
                                                                            e.target
                                                                                .value
                                                                        );
                                                                    }}
                                                                ></Form.Control>
                                                                <div className="inputIcon">
                                                                    <FiUser color="white" />
                                                                </div>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <Form.Label className="inputTitle">
                                                            Email
                                                            <div className="inputAndIcon">
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type={"text"}
                                                                    required
                                                                    value={email}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setEmail(
                                                                            e.target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                                <div className="inputIcon">
                                                                    <FiMail color="white" />
                                                                </div>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <Form.Label className="inputTitle">
                                                            {t("Telefone")}
                                                            <div className="inputAndIcon">
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type={"text"}
                                                                    required
                                                                    value={telefone}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setTelefone(
                                                                            e.target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                                <div className="inputIcon">
                                                                    <FiPhone color="white" />
                                                                </div>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <Form.Label className="inputTitle">
                                                            {t("Estado")}
                                                            <div className="inputAndIcon">
                                                                <Form.Select
                                                                    className="formInput"
                                                                    required
                                                                    value={estado}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setEstado(
                                                                            e.target
                                                                                .value
                                                                        );
                                                                    }}
                                                                >
                                                                    {listaEstados?.map(
                                                                        (
                                                                            estado: any
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    value={
                                                                                        estado.sigla
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        estado.nome
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Form.Select>
                                                                <div className="inputIcon">
                                                                    <FiMapPin color="white" />
                                                                </div>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <Form.Label className="inputTitle">
                                                            {t("Municipio")}
                                                            <div className="inputAndIcon">
                                                                <Form.Select
                                                                    className="formInput"
                                                                    required
                                                                    value={
                                                                        municipio
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setMunicipio(
                                                                            e.target
                                                                                .value
                                                                        );
                                                                    }}
                                                                >
                                                                    {listaMunicipios[
                                                                        estado
                                                                    ]?.map(
                                                                        (
                                                                            municipio: any
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    value={
                                                                                        municipio
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        municipio
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Form.Select>
                                                                <div className="inputIcon">
                                                                    <FiMapPin color="white" />
                                                                </div>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col
                                                        sm={12}
                                                        className="d-flex justify-content-end"
                                                    >
                                                        <button
                                                            className="submitButton"
                                                            onClick={
                                                                handleUpdateProfile
                                                            }
                                                        >
                                                            <FiSave color="white" />
                                                            {t("Salvar")}
                                                        </button>
                                                    </Col>
                                                </>
                                            ) : (
                                                <>
                                                    <Col sm={12}>
                                                        <Form.Label className="inputTitle">
                                                            {t("Nova Senha")}
                                                            <div className="inputAndIcon">
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type={
                                                                        passwordType
                                                                    }
                                                                    required
                                                                    value={
                                                                        novaSenha
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setNovaSenha(
                                                                            e.target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                                <div
                                                                    className="inputIcon"
                                                                    onClick={() =>
                                                                        setIsVisible(
                                                                            !isVisible
                                                                        )
                                                                    }
                                                                >
                                                                    {isVisible ? (
                                                                        <FiEyeOff color="white" />
                                                                    ) : (
                                                                        <FiEye color="white" />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <Form.Label className="inputTitle">
                                                            {t("Confirmar Senha")}
                                                            <div className="inputAndIcon">
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type={
                                                                        passwordType
                                                                    }
                                                                    required
                                                                    value={
                                                                        confirmaSenha
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setConfirmaSenha(
                                                                            e.target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                                <div
                                                                    className="inputIcon"
                                                                    onClick={() =>
                                                                        setIsVisible(
                                                                            !isVisible
                                                                        )
                                                                    }
                                                                >
                                                                    {isVisible ? (
                                                                        <FiEyeOff color="white" />
                                                                    ) : (
                                                                        <FiEye color="white" />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col
                                                        sm={12}
                                                        className="d-flex justify-content-end"
                                                    >
                                                        <button
                                                            className="submitButton"
                                                            onClick={
                                                                handleUpdatePassword
                                                            }
                                                        >
                                                            <FiSave color="white" />
                                                            {t("Salvar")}
                                                        </button>
                                                    </Col>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </div>
        </div>
    );
}

export default Perfil;
