import userSessionStorage from "../../utils/userSessionStorage";

function sessionValidate() {
    const storageUser = userSessionStorage();

    if (!storageUser)
        return { validated: false, code: "NotLogged", message: "Login session not found" };

    const storageToken = sessionStorage.getItem("tokenUsuario");

    if (!storageToken)
        return { validated: false, code: "UndefinedToken", message: "Token not found" };

    return { validated: true };
}

export default sessionValidate;
