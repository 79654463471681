import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Api, { linkBackend } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card } from "react-bootstrap";
import treatArray from "../../../utils/treatArray";
import treatString from "../../../utils/treatString";
import {
    capitalizeArray,
    toUpperArray,
} from "../../../utils/capitalize";
import ButtonExportExcel from "../../../components/buttonExportListExcel";

const UNDEFINED_COLOR = process.env.REACT_APP_UNDEFINED_COLOR;
const RED_COLOR = process.env.REACT_APP_RED_COLOR;
const YELLOW_COLOR = process.env.REACT_APP_YELLOW_COLOR;
const GREEN_COLOR = process.env.REACT_APP_GREEN_COLOR;

interface ComunidadesPorMunicipio {
    [key: string]: string[]; // ou o tipo que for apropriado para as comunidades
  }

function GridStakeholdersRC({
    config,
    permissoesUser,
}: {
    config: any;
    permissoesUser: {
        administrador: boolean;
        atendimentos_create: boolean;
        atendimentos_edit: boolean;
        atendimentos_view: boolean;
        demandas_create: boolean;
        demandas_edit: boolean;
        demandas_view: boolean;
        engajamentos_create: boolean;
        engajamentos_edit: boolean;
        engajamentos_view: boolean;
        id: number;
        id_user: number;
        noticias_create: boolean;
        noticias_edit: boolean;
        noticias_view: boolean;
        notificacoes_create: boolean;
        notificacoes_edit: boolean;
        notificacoes_view: boolean;
        stakeholders_create: boolean;
        stakeholders_edit: boolean;
        stakeholders_view: boolean;
        usuario_create: boolean;
        usuario_edit: boolean;
        visitas_create: boolean;
        visitas_edit: boolean;
        visitas_view: boolean;
        sms_view: boolean;
        sms_create: boolean;
        solicitacoes_view: boolean;
        solicitacoes_edit: boolean;
    };
}) {
    const { t } = useTranslation();
    const navegar = useNavigate();

    const [datagridRows, setDatagridRows] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [openModalFiltro, setOpenModalFiltro] = useState(false);

    const [listaEstados, setListaEstados] = useState<any>([]);
    const [listaMunicipios, setListaMunicipios] = useState<any>([]);

    const [idStakeholder, setIdStakeholder] = useState<number>(0);

    //input
    const [stakeholder, setStakeholder] = useState<string>("");
    const [contato, setContato] = useState<string>("");
    const [pontosDeInterface, setPontosDeInterface] = useState<string>("");
    const [representacao, setRepresentacao] = useState<string>("");
    const [cargo, setCargo] = useState<string>("");
    const [comentarios, setComentarios] = useState<string>("");

    const [registroFotografico, setRegistroFotografico] = useState(null);
    const [cargoAtualizacao, setCargoAtualizacao] = useState<any>();

    //Select
    const [estado, setEstado] = useState<string>("");
    const [municipio, setMunicipio] = useState<string>("");
    const [comunidade, setComunidade] = useState<string>("");
    const [listaComunidade, setListaComunidade] = useState<ComunidadesPorMunicipio>({});


    const [funcao, setFuncao] = useState<string>("");
    const [postura, setPostura] = useState<string>("");
    const [influencia, setInfluencia] = useState<string>("");
    const [grauImpacto, setGrauImpacto] = useState<string>("");
    const [criticidade, setCriticidade] = useState<string>("");

    const [email, setEmail] = useState<string>("");
    const [endereco, setEndereco] = useState<string>("");
    const [tipo, setTipo] = useState<string>("");

    const [toastExibido, setToastExibido] = useState(false);
    const [stakeRI, setStakeRI] = useState<boolean>(false);

    useEffect(() => {
        if (!permissoesUser.stakeholders_view && !toastExibido) {
            toast.error("Você não tem permissão para visualizar Stakeholders!");
            setToastExibido(true);
        }
    }, [permissoesUser.stakeholders_view, toastExibido]);

    useEffect(() => {
        Api.GetLocales().then((data) => {
            setListaComunidade(data.comunidades);
        });
    }, []);

    const [
        estadosExistentes,
        municipiosExistentes,
        comunidadesExistentes,
        stakeholdersExistentes,
    ] = useMemo(() => {
        const estados: string[] = [];
        const municipios: string[] = [];
        const comunidades: string[] = [];
        const stakeholders: string[] = [];

        datagridRows.forEach((r) => {
            const estado = toUpperArray(treatArray(r.estado))?.filter(
                (v) => v && !estados.includes(v)
            );
            const municipio = capitalizeArray(treatArray(r.municipio))?.filter(
                (v) => v && !municipios.includes(v)
            );
            const comunidade = capitalizeArray(treatArray(r.comunidade))?.filter(
                (v) => v && !comunidades.includes(v));

            const stakeholder = capitalizeArray(treatArray(r.stakeholder))?.filter(
                (v) => v && !stakeholders.includes(v)
            );

            if (estado) estados.push(...estado);
            if (municipio) municipios.push(...municipio);
            if (comunidade) comunidades.push(...comunidade);
            if (stakeholder) stakeholders.push(...stakeholder);
        });

        return [
            estados.sort((a, b) => a.localeCompare(b)),
            municipios.sort((a, b) => a.localeCompare(b)),
            comunidades.sort((a, b) => a.localeCompare(b)),
            stakeholders.sort((a, b) => a.localeCompare(b)),
        ];
    }, [datagridRows]);

    interface MunicipiosPorEstado {
        [estado: string]: string[];
    }
    const municipiosPorEstado: MunicipiosPorEstado = {};
    datagridRows.forEach((r) => {
        if (r.estado && r.municipio) {
            if (!municipiosPorEstado[r.estado]) {
                municipiosPorEstado[r.estado] = [];
            }

            if (!municipiosPorEstado[r.estado].includes(r.municipio)) {
                municipiosPorEstado[r.estado].push(r.municipio);
            }
        }
    });

    //filtros
    const [filtroStakeholder, setFiltroStakeholder] = useState<string>();
    const [filtroEstado, setFiltroEstado] = useState<string>();
    const [filtroMunicipio, setFiltroMunicipio] = useState<string>();
    const [filtroTipo, setFiltroTipo] = useState<string>();
    const [filtroComunidade, setFiltroComunidade] = useState<string>();
    const [filtroRelacionamento, setFiltroRelacionamento] = useState<string>();

    const municipiosFiltrados = useMemo(() => {
        return filtroEstado
            ? municipiosPorEstado[filtroEstado] || []
            : municipiosExistentes;
    }, [filtroEstado, municipiosPorEstado, municipiosExistentes]);

    const tiposFiltro = [
        {
            value: "Stakeholder",
            label: t("Stakeholder"),
            type: "select",
            opts: stakeholdersExistentes,
            filter: filtroStakeholder,
            setFilter: setFiltroStakeholder,
        },
        {
            value: "Estado",
            label: t("Estado"),
            type: "select",
            opts: estadosExistentes,
            filter: filtroEstado,
            setFilter: setFiltroEstado,
        },
        {
            value: "Municipio",
            label: t("Municipio"),
            type: "select",
            opts: municipiosFiltrados,
            filter: filtroMunicipio,
            setFilter: setFiltroMunicipio,
        },
        {
            value: "Comunidade",
            label: t("Comunidade"),
            type: "select",
            opts: comunidadesExistentes,
            filter: filtroComunidade,
            setFilter: setFiltroComunidade,
        },
        {
            value: "Tipo",
            label: t("Tipo"),
            type: "select",
            opts: [
                { label: t("Externo"), value: "Externo" },
                { label: t("Interno"), value: "Interno" },
            ],
            filter: filtroTipo,
            setFilter: setFiltroTipo,
        },
        {
            value: "Relacionamento",
            label: t("Relacionamento"),
            type: "select",
            opts: [
                { label: t("RC"), value: "RC" },
                { label: t("RI"), value: "RI" },
            ],
            filter: filtroRelacionamento,
            setFilter: setFiltroRelacionamento,
        },
    ];

    const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

    const dadosFiltrados = useMemo(
        () =>
            datagridRows.filter(
                (v) =>
                    (!filtroStakeholder ||
                        v?.stakeholder
                            ?.toLowerCase()
                            .includes(filtroStakeholder?.toLowerCase())) &&
                    (!filtroEstado ||
                        v.estado?.toLowerCase().includes(filtroEstado?.toLowerCase())) &&
                    (!filtroMunicipio ||
                        v.municipio?.toLowerCase().includes(filtroMunicipio?.toLowerCase())) &&
                    (!filtroComunidade ||
                        v.comunidade?.toLowerCase().includes(filtroComunidade?.toLowerCase())) &&
                    (!filtroRelacionamento ||
                        v.relacionamento
                            ?.toLowerCase()
                            .includes(filtroRelacionamento?.toLowerCase())) &&
                    (!filtroTipo ||
                        v.tipo
                            ?.toLowerCase()
                            .includes(filtroTipo?.toLowerCase()))
            ),
        [
            datagridRows,
            filtroStakeholder,
            filtroEstado,
            filtroMunicipio,
            filtroTipo,
            filtroComunidade,
            filtroRelacionamento,

        ]
    );

    useEffect(() => {
        if (!selectedRowData) return;

        console.log("selectedRowData", selectedRowData)
        setIdStakeholder(selectedRowData.id || 0);
        setStakeholder(selectedRowData.stakeholder || "");
        setContato(selectedRowData.contato || "");
        setEmail(selectedRowData.email || "");
        setEndereco(selectedRowData.endereco || "");
        setPontosDeInterface(selectedRowData.interlocutores_mosaic || "");
        setCargo(selectedRowData.cargo || "");
        setComentarios(selectedRowData.comentarios || "");
        setEstado(selectedRowData.estado || "");
        setMunicipio(selectedRowData.municipio || "");
        setComunidade(selectedRowData.comunidade || "")
        setFuncao(selectedRowData.funcao || "");
        setPostura(selectedRowData.postura || "");
        setInfluencia(selectedRowData.influencia || "");
        setGrauImpacto(selectedRowData.impacto || "");
        setCriticidade(selectedRowData.criticidade || "");
        setCargoAtualizacao(selectedRowData.atualizado_em);
        setTipo(selectedRowData.tipo || "");
        selectedRowData.relacionamento === "RI" ? setStakeRI(true) : setStakeRI(false)
    }, [selectedRowData]);

    const handleRowClick = (params: any) => {
        setSelectedRowData(params.row);
        setIsModalOpen(true);
    };

    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
        // updateDatagrid();
    };

    const handleOpenModalDelete = () => {
        setShowModalDelete(true);
    };

    const datagridColunas: readonly GridColDef<any>[] = [
        {
            field: "stakeholder",
            headerName: t("Stakeholder") as string,
            width: 200,
        },
        {
            field: "estado",
            headerName: t("Estado") as string,
            width: 150,
        },
        {
            field: "municipio",
            headerName: t("Municipio") as string,
            width: 150,
        },
        {
            field: "comunidade",
            headerName: t("Comunidade") as string,
            width: 150,
        },
        {
            field: "tipo",
            headerName: t("Tipo") as string,
            width: 150,
        },
        {
            field: "relacionamento",
            headerName: t("Relacionamento") as string,
            width: 150,
        },
        // {
        //     field: "contato",
        //     headerName: t("Contato") as string,
        //     width: 150,
        //     valueGetter: ({ row }: { row: any }) =>
        //         [row.contato, row.email, row.endereco].filter((v) => v).join("; "),
        // },

        // {
        //     field: "funcao",
        //     headerName: t("Função") as string,
        //     width: 150,
        // },

        // {
        //     field: "representacao",
        //     headerName: t("Representação") as string,
        //     width: 200,
        // },
        // {
        //     field: "interface_portal",
        //     headerName: t("Interface") as string,
        //     width: 150,
        // },
        // {
        //     field: "influencia",
        //     headerName: t("Influencia") as string,
        //     width: 150,
        //     renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        //         const icon =
        //             params.value === t("Alto")
        //                 ? RED_COLOR
        //                 : params.value === t("Média")
        //                     ? YELLOW_COLOR
        //                     : params.value === t("Baixo")
        //                         ? GREEN_COLOR
        //                         : UNDEFINED_COLOR;

        //         return (
        //             <span>
        //                 <BsCircleFill color={icon} /> {params.value}
        //             </span>
        //         );
        //     },
        // },
        // {
        //     field: "postura",
        //     headerName: t("Postura") as string,
        //     width: 150,
        //     renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        //         const icon =
        //             params.value === t("Desfavorável")
        //                 ? RED_COLOR
        //                 : params.value === t("Neutro")
        //                     ? UNDEFINED_COLOR
        //                     : params.value === t("Favorável")
        //                         ? GREEN_COLOR
        //                         : UNDEFINED_COLOR;

        //         return (
        //             <span>
        //                 <BsCircleFill color={icon} /> {params.value}
        //             </span>
        //         );
        //     },
        // },
        // {
        //     field: "impacto",
        //     headerName: t("Impacto") as string,
        //     width: 150,
        //     renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        //         const icon =
        //             params.value === t("Alto")
        //                 ? RED_COLOR
        //                 : params.value === t("Médio")
        //                     ? YELLOW_COLOR
        //                     : params.value === t("Baixo")
        //                         ? GREEN_COLOR
        //                         : UNDEFINED_COLOR;

        //         return (
        //             <span>
        //                 <BsCircleFill color={icon} /> {params.value}
        //             </span>
        //         );
        //     },
        // },
    ];


    const AddDia = (dataString: any) => {
        if (!dataString) return null;
        const data = new Date(dataString);
        data.setDate(data.getDate() + 1);
        return data
    };

    const updateDatagrid = useCallback(() => {
        if (!config || !navegar || !t) return;

        Api.GetStakeholdersRC(config)
            .then((data: any[]) => {
                setDatagridRows(
                    data.map((d, i) => ({
                        ...d,
                        arrayId: i + 1,
                        atualizado_em: d.atualizado_em ? AddDia(d.atualizado_em) : null,
                    }))
                );
            })
            .catch((err: any) => {
                if (typeof err === "string") {
                    toast.error(t(err));
                    navegar("/login");
                    return;
                }

                toast.error(t("Houve um erro ao carregar os stakeholders"));
            });
    }, [config, navegar, t]);

    const updateLocales = useCallback(() => {
        Api.GetLocales().then((data) => {
            setListaEstados(data.localidades.estados);
            setListaMunicipios(data.localidades.municipios);
        });
    }, []);

    useEffect(updateDatagrid, [updateDatagrid]);
    useEffect(updateLocales, [updateLocales]);

    const handleModalFiltros = (filtro: string) => {
        setFiltroAtivo((filtros) => {
            const newFiltros = [...filtros];
            const fIndex = newFiltros.findIndex((f) => f === filtro);
            if (fIndex >= 0) {
                newFiltros.splice(fIndex, 1);
                tiposFiltro.find((f) => f.value === filtro)?.setFilter(undefined);
            } else {
                newFiltros.push(filtro);
            }
            return newFiltros;
        });
        setOpenModalFiltro(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const formData = new FormData();

        if (registroFotografico !== null) {
            formData.append("avatar", registroFotografico);
        }

        formData.append("id", String(idStakeholder));
        formData.append("estado", estado);
        formData.append("municipio", municipio);
        formData.append("comunidade", comunidade);
        formData.append("contato", contato);
        formData.append("email", email);
        formData.append("endereco", endereco);
        formData.append("interfacePortal", pontosDeInterface);
        formData.append("stakeholder", stakeholder);
        formData.append("representacao", representacao);
        formData.append("interacoes", "");
        formData.append("funcao", funcao);
        formData.append("postura", postura);
        formData.append("influencia", influencia);
        formData.append("impacto", grauImpacto);
        formData.append("criticidade", criticidade);
        formData.append("comentarios", comentarios);
        formData.append("tipo", tipo);

        Api.PutStakeholder(formData, config)
            .then((res) => {
                updateDatagrid();
                closeModal();
                toast.success("Alterações salvas com sucesso!");
            })
            .catch((err) => {
                toast.error("Não foi possível salvar as alterações!");
            });
    };

    const handleDelete = (event: any) => {
        event.preventDefault();
        Api.DeleteStakeholder(selectedRowData.id, config)
            .then((res) => {
                handleCloseModalDelete();
                updateDatagrid();
                closeModal();
                toast.success("Registro deletado com sucesso!");
            })
            .catch((err) => {
                toast.error("Não foi possível deletar o registro!");
            });
    };

    const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
        const handleViewImage = () => {
            const overlayElement = document.createElement("div");
            overlayElement.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      `;

            let imgElement: HTMLImageElement | null = document.createElement("img");

            imgElement.onload = () => {
                if (imgElement && imgElement.complete) {
                    overlayElement.appendChild(imgElement);
                } else {
                    showNoImageMessage();
                }
            };

            imgElement.onerror = () => {
                showNoImageMessage();
            };

            const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
            const linkConcat = `${linkBackend}${formattedImageUrl}`;
            const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
            imgElement.src = linkCompleto;
            imgElement.alt = "Imagem";
            imgElement.style.cssText = `
        max-height: 80vh;
        max-width: 100%;
      `;

            const showNoImageMessage = () => {
                const noImageMessage = document.createElement("div");
                noImageMessage.textContent = "Nenhuma imagem disponível";
                noImageMessage.style.cssText = `
          color: white;
          font-size: 20px;
        `;

                overlayElement.appendChild(noImageMessage);
            };

            overlayElement.addEventListener("click", () => {
                document.body.removeChild(overlayElement);
            });

            document.body.appendChild(overlayElement);
        };

        return (
            <Col sm={12}>
                <Col sm={6}>
                    <button
                        onClick={handleViewImage}
                        className="botao-grid"
                        type="button"
                    >
                        {t("Visualizar foto")}
                    </button>
                </Col>
            </Col>
        );
    };

    return (
        <Col sm={12}>
            <div className="grid-dados">
                <Col sm={12}>
                    <Row>
                        <Col sm={9}>
                            {filtrosAtivos.map((filtro, i) => {
                                const filterObj = tiposFiltro.find(
                                    ({ value }) => value === filtro
                                );
                                if (!filterObj) return null;

                                const { filter, setFilter, type, opts } = filterObj;

                                return (
                                    <Col className="filtro-ativo" key={i}>
                                        <Row>
                                            <Col sm={9}>
                                                {type === "select" ? (
                                                    <Form.Select
                                                        value={filter?.toString() || ""}
                                                        onChange={(e) => {
                                                            setFilter(e.target.value as any);
                                                        }}
                                                        style={{ height: "73%" }}
                                                    >
                                                        <option label={`Filtrar ${filtro}`} value={""} />
                                                        {opts?.map((opt, i) => {
                                                            const isString = typeof opt === "string";

                                                            return (
                                                                <option
                                                                    key={i}
                                                                    label={isString ? opt : opt.label}
                                                                    value={isString ? opt : opt.value}
                                                                />
                                                            );
                                                        })}
                                                    </Form.Select>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Filtrar ${filtro}`}
                                                        value={filter?.toString() || ""}
                                                        onChange={(e) => {
                                                            setFilter(e.target.value as any);
                                                        }}
                                                        style={{ height: "73%" }}
                                                    />
                                                )}
                                            </Col>
                                            <Col sm={3}>
                                                <button
                                                    onClick={() => {
                                                        setFilter(undefined);
                                                    }}
                                                    className="botao-filtro mt-2"
                                                >
                                                    {t("Limpar Filtro")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>
                        <Col sm={3}>
                            <button
                                className="botao-filtro mt-2"
                                onClick={() => setOpenModalFiltro(!openModalFiltro)}
                            >
                                {t("Filtros")}
                            </button>
                        </Col>
                    </Row>

                    {openModalFiltro && (
                        <Col sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <Col sm={12} className="justify-content-center">
                                        <Row>
                                            {tiposFiltro.map((filtro, i) => (
                                                <Col sm={4} key={i}>
                                                    <button
                                                        className={`filtros-modal ${filtrosAtivos?.includes(filtro.value)
                                                            ? "ativo"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            handleModalFiltros(filtro.value);
                                                        }}
                                                    >
                                                        {filtro.label}
                                                    </button>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Col>

                <div>
                    {!permissoesUser.stakeholders_view && (
                        <div>
                            <DataGrid rows={[]} columns={datagridColunas} />
                        </div>
                    )}
                    {permissoesUser.stakeholders_view && (
                        <DataGrid
                            rows={dadosFiltrados}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    )}
                </div>
                <div className="mt-2">
                    <ButtonExportExcel lista={dadosFiltrados}></ButtonExportExcel>
                </div>
            </div>

            {/* Modal */}
            <Modal
                show={isModalOpen}
                onHide={closeModal}
                className="modal-xl"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Detalhes da Stakeholder")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRowData && (
                        <div id="content-grid">
                            <Col sm={12} className="mb-2">
                                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                                    <Col sm={12}>
                                        <Row>

                                            <Col sm={3}>
                                                <Form.Label>{t("Estado")}</Form.Label>
                                                <Form.Select
                                                    required
                                                    value={estado}
                                                    onChange={(e) => {
                                                        setEstado(e.target.value);
                                                    }}
                                                >
                                                    <option value={""}>{t("Selecione o estado")}</option>
                                                    {listaEstados?.map((estado: any) => {
                                                        return (
                                                            <option value={estado.sigla}>
                                                                {estado.nome}
                                                            </option>
                                                        );
                                                    })}
                                                </Form.Select>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>{t("Municipio")}</Form.Label>

                                                <Form.Select
                                                    required
                                                    value={municipio}
                                                    onChange={(e) => {
                                                        setMunicipio(e.target.value);
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t("Selecione o municipio")}
                                                    </option>
                                                    {listaMunicipios[estado]?.map((municipio: any) => {
                                                        return (
                                                            <option value={municipio}>{municipio}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>{t("Comunidade")}</Form.Label>
                                                <Form.Select
                                                    required
                                                    value={comunidade}
                                                    onChange={(e) => {
                                                        setComunidade(e.target.value);
                                                    }}
                                                >
                                                    <option value={""}>{t("Selecione comunidade")}</option>
                                                    {listaComunidade[municipio]?.map((comunidade: any) => {
                                                        return <option value={comunidade}>{comunidade}</option>;
                                                    })}
                                                </Form.Select>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Tipo")}
                                                </Form.Label>
                                                <Form.Select
                                                    required
                                                    value={tipo}
                                                    onChange={(event) => {
                                                        setTipo(event.target.value);
                                                    }}
                                                >
                                                    <option value="">{t("Selecione tipo")}</option>
                                                    <option value="Interno">{t("Interno")}</option>
                                                    <option value="Externo">{t("Externo")}</option>
                                                </Form.Select>
                                            </Col>
                                           

                                        </Row>
                                    </Col>

                                    <Col sm={12} className="mt-3">
                                        <Row>
                                        <Col sm={3}>
                                                <Form.Label>Stakeholder</Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    required
                                                    value={stakeholder}
                                                    onChange={(e) => {
                                                        setStakeholder(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>{t("Contato")}</Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    value={contato}
                                                    onChange={(e) => {
                                                        setContato(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>{t("Email")}</Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Check
                                                    className="mt-4"
                                                    type="switch"
                                                    id="oficioSwitch"
                                                    label="Stakeholder RI?"
                                                    checked={stakeRI}
                                                    onChange={() => setStakeRI(!stakeRI)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>


                                    {stakeRI && (
                                        <div>
                                            <Col sm={12} className="mt-4">
                                                <Row>
                                                    <Col sm={3}>
                                                        <Form.Label>{t("Grau de Influência")}</Form.Label>
                                                        <Form.Select
                                                            value={influencia}
                                                            onChange={(event) => {
                                                                setInfluencia(event.target.value);
                                                            }}
                                                        >
                                                            <option value="">{t("Influência")}</option>
                                                            <option value="Alto">{t("Alto")}</option>
                                                            <option value="Médio">{t("Médio")}</option>
                                                            <option value="Baixo">{t("Baixo")}</option>
                                                        </Form.Select>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Form.Label>{t("Categoria do Stakeholder")}</Form.Label>
                                                        <Form.Select
                                                            value={funcao}
                                                            onChange={(event) => {
                                                                setFuncao(event.target.value);
                                                            }}
                                                        >
                                                            <option value="">{t("Categoria do Stakeholder")}</option>
                                                            <option value="Líder Comunitário">
                                                                {t("Líder Comunitário")}
                                                            </option>
                                                            <option value="Demandante Interno">
                                                                {t("Demandante Interno")}
                                                            </option>
                                                            <option
                                                                value={"Poder Público Municipal - Secretaria"}
                                                            >
                                                                {t("Poder Público Municipal - Secretaria")}
                                                            </option>

                                                            <option
                                                                value={"Poder Público Municipal - Prefeitura"}
                                                            >
                                                                {t("Poder Público Municipal - Prefeitura")}
                                                            </option>

                                                            <option value={"Poder Público Municipal - Autarquia"}>
                                                                {t("Poder Público Municipal - Autarquia")}
                                                            </option>
                                                            <option
                                                                value={
                                                                    "Poder Público Municipal - Câmara de Vereadores"
                                                                }
                                                            >
                                                                {t(
                                                                    "Poder Público Municipal - Câmara de Vereadores"
                                                                )}
                                                            </option>
                                                            <option value="Poder Público Estadual">
                                                                {t("Poder Público Estadual")}
                                                            </option>
                                                            <option value={"Poder Público Federal"}>
                                                                {t("Poder Público Federal")}
                                                            </option>
                                                            <option value="ONGs e Associações">
                                                                {t("ONGs e Associações")}
                                                            </option>
                                                            <option value="Ministério Público">
                                                                {t("Ministério Público")}
                                                            </option>
                                                            <option value="Entidades Filantrópicas">
                                                                {t("Entidades Filantrópicas")}
                                                            </option>
                                                            <option value="Cooperativas">
                                                                {t("Cooperativas")}
                                                            </option>
                                                            <option value="Fazendas">{t("Fazendas")}</option>
                                                            <option value="Emergência">{t("Emergência")}</option>
                                                            <option value="MST">{t("MST")}</option>
                                                            <option value="Comunidade Tradicional Quilombolas">
                                                                {t("Comunidade Tradicional Quilombolas")}
                                                            </option>
                                                            <option value="Comunidade Tradicional Pesca">
                                                                {t("Comunidade Tradicional Pesca")}
                                                            </option>
                                                            <option value="Entidades">{t("Entidades")}</option>
                                                            <option value={"Entidades Setoriais"}>
                                                                {t("Entidades Setoriais")}
                                                            </option>
                                                            <option value={"Entidades do Terceiro Setor"}>
                                                                {t("Entidades do Terceiro Setor")}
                                                            </option>
                                                            <option value={"Setor Privado"}>
                                                                {t("Setor Privado")}
                                                            </option>
                                                            <option value={"Academia"}>{t("Academia")}</option>
                                                            <option value="Morador">{t("Morador")}</option>
                                                            <option value={"outros"}>{t("Outros")}</option>
                                                        </Form.Select>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Form.Label>{t("Postura")}</Form.Label>
                                                        <Form.Select
                                                            value={postura}
                                                            onChange={(event) => {
                                                                setPostura(event.target.value);
                                                            }}
                                                        >
                                                            <option value="">{t("Postura")}</option>
                                                            <option value="Favorável">{t("Favorável")}</option>
                                                            <option value="Neutro">{t("Neutro")}</option>
                                                            <option value="Desfavorável">
                                                                {t("Desfavorável")}
                                                            </option>
                                                        </Form.Select>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Form.Label>{t("Criticidade")}</Form.Label>
                                                        <Form.Select
                                                            value={criticidade}
                                                            onChange={(event) => {
                                                                setCriticidade(event.target.value);
                                                            }}
                                                        >
                                                            <option value="">{t("Criticidade")}</option>
                                                            <option value="Alto">{t("Alto")}</option>
                                                            <option value="Médio">{t("Médio")}</option>
                                                            <option value="Baixo">{t("Baixo")}</option>
                                                        </Form.Select>
                                                    </Col>
                                                </Row>
                                            </Col>



                                            <Col sm={12} className="mt-4">
                                                <Row>
                                                    <Col sm={4}>
                                                        <Form.Label>{t("Interlocutores Mosaic")}</Form.Label>
                                                        <Form.Control
                                                            className="formInput"
                                                            type={"text"}
                                                            required
                                                            value={pontosDeInterface}
                                                            onChange={(e) => {
                                                                setPontosDeInterface(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Form.Label>{t("Cargo")}</Form.Label>
                                                        <Form.Control
                                                            className="formInput"
                                                            type={"text"}
                                                            required
                                                            value={cargo}
                                                            onChange={(e) => {
                                                                setCargo(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Form.Label>{t("Endereço")}</Form.Label>
                                                        <Form.Control
                                                            className="formInput"
                                                            type={"text"}
                                                            value={endereco}
                                                            onChange={(e) => {
                                                                setEndereco(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>

                                            {/* <Col sm={12} className="mt-2">
                                                <Row>


                                                    <Col sm={3}>
                                                        <Form.Label>{t("Cargo atualizado em")}</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            value={cargoAtualizacao ? cargoAtualizacao.toISOString().split('T')[0] : ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col> */}


                                            <Col sm={12} className="mt-2">
                                                <Form.Label>{t("Comentarios")}</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    className="formInput"
                                                    value={comentarios}
                                                    onChange={(e) => {
                                                        setComentarios(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </div>


                                    )}
                                    <Col sm={12} className="labelFotos mt-3 p-2">
                                        <Form.Label>
                                            Foto (Use arquivos .jpg, jpeg, png, e webp com no máximo
                                            2048 kB)
                                        </Form.Label>
                                    </Col>
                                    <Col sm={12} className="cadastroFotos mt-2">
                                        <Form.Control
                                            type="file"
                                            accept="image/*"
                                            name="avatar"
                                            multiple
                                            onChange={(e: any) => {
                                                setRegistroFotografico(
                                                    e.target.files[0] ? e.target.files[0] : null
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <Col sm={12}>
                                            <ExibirImagem imageUrl={selectedRowData.foto} />
                                        </Col>
                                    </Col>
                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={6}>
                                                {permissoesUser.stakeholders_edit ? (
                                                    <button className="botao-grid" type="submit">
                                                        {t("Salvar")}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="botao-grid"
                                                        type="button"
                                                        onClick={() =>
                                                            toast.error(
                                                                "Você não tem permissão para editar um Stakeholder!"
                                                            )
                                                        }
                                                    >
                                                        {t("Salvar")}
                                                    </button>
                                                )}
                                            </Col>
                                            {/* <Col sm={4}>
                        <button
                          className="botao-grid"
                          type="reset"
                          onClick={handleOpenModalDelete}
                        >
                          {t("Limpar")}
                        </button>
                      </Col> */}
                                            <Col sm={6}>
                                                {permissoesUser.stakeholders_edit ? (
                                                    <button
                                                        className="botao-grid"
                                                        type="button"
                                                        onClick={handleOpenModalDelete}
                                                    >
                                                        {t("Excluir")}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="botao-grid"
                                                        type="button"
                                                        onClick={() =>
                                                            toast.error(
                                                                "Você não tem permissão para excluir um Stakeholder!"
                                                            )
                                                        }
                                                    >
                                                        {t("Excluir")}
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </Col>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            <Modal
                show={showModalDelete}
                onHide={handleCloseModalDelete}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja excluir Stakeholder ?")}
                            </p>

                            <button className="botao-grid" onClick={handleDelete}>
                                {t("Sim")}
                            </button>
                            <button className="botao-grid" onClick={handleCloseModalDelete}>
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default GridStakeholdersRC;
