export const  convertDataProFusoCorreto = (date: any) => {
  if(date) {
    const dataDoBanco = new Date(date);
    return new Date(dataDoBanco.setHours(dataDoBanco.getHours() + dataDoBanco.getTimezoneOffset() / 60));
  } else {
    return null
  }
}

export function formatDateToString(date: any) {
  if(!date) return undefined;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam do 0, então adicionamos 1
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}