import "./styles.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../../config/api";
import LoadingIcon from "../../components/loadingIcon";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

// Bootstrap Elements
import { Modal } from "react-bootstrap";

// Images
import logoMosaic from "../../assets/brand/logo2.png";
import ImageHome from "../../assets/images/LoginHome.jpeg";

// Icons
import { LiaGlobeAmericasSolid } from "react-icons/lia";
import { FiEyeOff, FiEye, FiPhone } from "react-icons/fi";
import { BiLogoInstagramAlt, BiLogoFacebookSquare } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";

function Login() {
  const navegar = useNavigate();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  const [language, setLanguage] = useState<string>("");

  const [login, setLogin] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [passwordType, setPasswordType] = useState("password");
  const [isVisible, setIsVisible] = useState(false);
  const [contador, setContador] = useState(0);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalForgotPassword, setShowModalForgotPassword] =
    useState<boolean>(false);

  const [waiting, setWaiting] = useState(false);

  const submitLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!login || !senha) {
      toast.error(t("Campos login e senha obrigatórios"));
      return;
    }

    setWaiting(true);

    const obj = {
      login: login,
      senha: senha,
    };
    Api.PostLogin(obj)
      .then((data) => {
        toast.success("Login realizado com sucesso!");
        sessionStorage.setItem("tokenUsuario", data.token);
        sessionStorage.setItem("expirationUsuario", data.expiration);
        sessionStorage.setItem("loginUsuario", JSON.stringify(data.usuario));
        sessionStorage.setItem(
          "permissoesUsuario",
          JSON.stringify(data.permissoes)
        );
        // navegar("/portais");
        window.location.href = "/portais";
      })
      .catch((err) => {
        toast.error("Não foi possível realizar o login!");
        setWaiting(false);
      });
  };

  const handleForgotPassword = (event: any) => {
    event.preventDefault();
    setContador(60);

    const obj = {
      email: email,
    };

    Api.PutEsqueceuSenha(obj)
      .then((res) => {
        toast.success("Nova senha enviada com sucesso!");
      })
      .catch((err) => {
        toast.error("Não foi possível enviar nova senha!");
      });
  };

  useEffect(() => {
    if (contador > 0) {
      setTimeout(() => {
        setContador(contador - 1);
      }, 1000);
    }
  }, [contador]);

  useEffect(() => {
    if (contador > 0) {
      setTimeout(() => {
        setContador(contador - 1);
      }, 1000);
    }
  }, [contador]);

  useEffect(() => {
    isVisible ? setPasswordType("text") : setPasswordType("password");
  }, [isVisible]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModalForgotPassword = () => {
    setShowModalForgotPassword(false);
  };

  const handleOpenModalForgotPassword = () => {
    setShowModalForgotPassword(true);
    setShowModal(false);
  };

  const handleFacebookClick = () => {
    window.open("https://www.facebook.com/mosaicbr/", "_blank");
  };

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/mosaicfertilizantes/", "_blank");
  };

  return (
    <div id="pageLogin">
      <div className="headerPageLogin">
        <div className="languageSection">
          <LiaGlobeAmericasSolid />
          <select
            className="selectLanguage"
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
              changeLanguage(e.target.value);
            }}
          >
            <option className="languageOption" value={"pt"}>
              Português
            </option>
            <option className="languageOption" value={"en"}>
              English
            </option>
          </select>
        </div>
        <div className="socialMidiaSection">
          <BiLogoFacebookSquare
            size={20}
            onClick={handleFacebookClick}
            style={{ cursor: "pointer" }}
          />
          <BiLogoInstagramAlt
            size={20}
            onClick={handleInstagramClick}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      <div className="contentPageLogin">
        <div className="itensSection">
          <img className={"mosaicLogo"} src={logoMosaic} alt="Mosaic Logo" />

          <div className="textSection">
            <span className="titlePerformanceSocial">
              {t("Plataforma Performance Social")}
            </span>
            <span className="descriptionPerformanceSocial">
              {t(
                "Somos uma empresa de agromineração dedicada à missão de ajudar o mundo a produzir os alimentos de que precisa"
              )}
            </span>
          </div>

          <button className="buttonsLogin" onClick={handleOpenModal}>
            {t("Entrar")}
          </button>
        </div>
        <div className="imageSection">
          <img className={"imageHome"} src={ImageHome} alt="Home" />
        </div>
      </div>

      <div className="footerPageLogin">
        <span className="directy">© {t("2023 Directy Consultoria")}</span>
        <span className="separator">|</span>
        <span className="allRightsReserved">
          {t("Todos os direitos reservados")}
        </span>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered={true}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div id="modalLogin">
            <form onSubmit={submitLogin} className="form">
              <img
                className={"mosaicLogo"}
                src={logoMosaic}
                alt="Mosaic Logo"
              />
              <p className="formTitle">{t("Plataforma Performance Social")}</p>

              <div className="inputBox">
                <label className="inputTitle">{t("Login")}</label>
                <div className="inputAndIcon">
                  <input
                    className="inputFormLogin"
                    // placeholder={t("Digite seu login")}
                    type={"text"}
                    required
                    value={login}
                    onChange={(e) => {
                      setLogin(e.target.value);
                    }}
                  />
                  <div className="inputIcon">
                    <FiPhone color="white" />
                  </div>
                </div>
              </div>

              <div className="inputBox">
                <label className="inputTitle">{t("Senha")}</label>
                <div className="inputAndIcon">
                  <input
                    className="inputFormLogin"
                    // placeholder={t("Digite sua senha")}
                    type={passwordType}
                    required
                    value={senha}
                    onChange={(e) => {
                      setSenha(e.target.value);
                    }}
                  />
                  <div
                    className="inputIcon"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    {isVisible ? (
                      <FiEyeOff color="white" />
                    ) : (
                      <FiEye color="white" />
                    )}
                  </div>
                </div>
              </div>

              <div className="passwordOptions">
                <span
                  className="forgotPassword"
                  onClick={handleOpenModalForgotPassword}
                >
                  {t("Esqueceu a senha?")}
                </span>
              </div>

              <button
                className="buttonsLogin-modal col-12 col-sm-6"
                type="submit"
                disabled={waiting}
              >
                {waiting ? <LoadingIcon /> : <span>{t("Entrar")}</span>}
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalForgotPassword}
        onHide={handleCloseModalForgotPassword}
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div id="modalLogin">
            <form onSubmit={handleForgotPassword} className="form">
              <p className="titleForgotPassword">{t("Esqueceu sua senha?")}</p>

              <p className="forgotPasswordDescription">
                {t(
                  "Informe um email valido no campo a baixo para receber sua nova senha"
                )}
              </p>

              <div className="inputBox">
                <label className="inputTitle">{t("Email")}</label>
                <div className="inputAndIcon">
                  <input
                    className="inputFormLogin"
                    type={"text"}
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <div className="inputIcon">
                    <HiOutlineMail color="white" />
                  </div>
                </div>
              </div>

              {contador > 0 && (
                <p style={{ fontSize: 14 }}>
                  Não recebeu email? Tente novamente em {contador}s.
                </p>
              )}

              <button className="buttonsLogin" type="submit" disabled={waiting}>
                {waiting ? <LoadingIcon /> : <span>{t("Enviar")}</span>}
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Login;
