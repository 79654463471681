import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./styles.css";
import { Col, Form, Row, Modal } from "react-bootstrap";
import Api from "../../config/api";

function Notificacoes({
    config,
    permissoesUser,
    toggleCard,
}: {
    config: any;
    toggleCard: any;
    permissoesUser: {
        administrador: boolean;
        atendimentos_create: boolean;
        atendimentos_edit: boolean;
        atendimentos_view: boolean;
        demandas_create: boolean;
        demandas_edit: boolean;
        demandas_view: boolean;
        engajamentos_create: boolean;
        engajamentos_edit: boolean;
        engajamentos_view: boolean;
        id: number;
        id_user: number;
        noticias_create: boolean;
        noticias_edit: boolean;
        noticias_view: boolean;
        notificacoes_create: boolean;
        notificacoes_edit: boolean;
        notificacoes_view: boolean;
        stakeholders_create: boolean;
        stakeholders_edit: boolean;
        stakeholders_view: boolean;
        usuario_create: boolean;
        usuario_edit: boolean;
        visitas_create: boolean;
        visitas_edit: boolean;
        visitas_view: boolean;
        sms_view: boolean;
        sms_create: boolean;
        solicitacoes_view: boolean;
        solicitacoes_edit: boolean;
    };
}) {
    const { t } = useTranslation();

    //input
    const [titulo, setTitulo] = useState<string>("");
    const [texto, setTexto] = useState<string>("");

    const [toastExibido, setToastExibido] = useState(false);

    const [showModalRegister, setShowModalRegister] = useState<boolean>(false);

    let userStorage = sessionStorage.getItem("loginUsuario") as string;
    let user = JSON.parse(userStorage);

    useEffect(() => {
        if (!permissoesUser.notificacoes_create && !toastExibido) {
            toast.error(
                "Você não tem permissão para criar uma nova Notificação!"
            );
            setToastExibido(true);
        }
    }, [permissoesUser.notificacoes_create, toastExibido]);

    const handleSubmit = () => {
        const obj = {
            titulo: titulo,
            mensagem: texto,
            autor: user.nome,
            status: "pendente",
        };

        Api.PostNotificacao(obj, config)
            .then((res) => {
                handleCloseModalRegister();
                handleClearFields();
                toast.success(t("Cadastro feito com sucesso!"));
            })
            .catch((err) => {
                toast.error(t("Não foi possível fazer o cadastro!"));
            });
    };

    const handleClearFields = () => {
        setTitulo("");
        setTexto("");
    };

    const handleCloseModalRegister = () => {
        setShowModalRegister(false);
    };

    const handleOpenModalRegister = () => {
        setShowModalRegister(true);
    };

    return (
        <Col sm={12} id="notificacaoPage">
            <Form onReset={handleClearFields}>
                <Col sm={12} className="mt-4 mb-4">
                    <Form.Label>{t("Titulo")}</Form.Label>
                    <Form.Control
                        className="formInput"
                        type={"text"}
                        required
                        value={titulo}
                        onChange={(e) => {
                            setTitulo(e.target.value);
                        }}
                    />
                    <Form.Label className="mt-4">{t("Texto")}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        className="formInput"
                        value={texto}
                        onChange={(e) => {
                            setTexto(e.target.value);
                        }}
                    />
                </Col>
                <Col sm={12}>
                    <Row>
                        <Col sm={6}>
                            {permissoesUser.notificacoes_create ? (
                                <button
                                    className="botao-notificacao"
                                    type="button"
                                    onClick={handleOpenModalRegister}
                                >
                                    {t("Registrar")}
                                </button>
                            ) : (
                                <button
                                    className="botao-notificacao"
                                    type="button"
                                    onClick={() =>
                                        toast.error(
                                            "Você não tem permissão para criar uma nova Notificação!"
                                        )
                                    }
                                >
                                    {t("Registrar")}
                                </button>
                            )}
                        </Col>
                        <Col sm={6}>
                            <button className="botao-notificacao" type="reset">
                                {t("Limpar")}
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Form>
            <Modal
                show={showModalRegister}
                onHide={handleCloseModalRegister}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja enviar o cadastro?")}
                            </p>

                            <button
                                className="botao-grid"
                                onClick={handleSubmit}
                            >
                                {t("Sim")}
                            </button>
                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalRegister}
                            >
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default Notificacoes;
