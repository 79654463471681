type DateObj = {
    prioritario?: number | null;
    padrao?: number | null;
  };
  
type DateNull = Date | null;

export const convertTipoAtendimentoPraData = (tipo: string): DateObj => {
    try {
      let dateObjHoje: DateObj = {
        prioritario: null,
        padrao: null,
      };
      let dateHoje = new Date();
  
      if (tipo === "Prioritário") {
        dateObjHoje.prioritario = dateHoje.setDate(dateHoje.getDate() + 7);
      } else if (tipo === "Regular") {
        dateObjHoje.padrao = dateHoje.setDate(dateHoje.getDate() + 30);
      }
  
      return dateObjHoje;
    } catch (err) {
      console.error(err);
      return {};
    }
  };
  
export const convertFilantropiaDate = (filantropia: string) => {
      try {
          let dateHoje: DateNull = new Date();
  
          if(filantropia === "Filantropia") {
              return dateHoje.setDate(dateHoje.getDate() + 60);
          } else {
              return dateHoje = null;
          }
  
      } catch (err) {
          console.error(err);
          return null;
      }
}