import "./styles.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import sessionValidate from "../../utils/sessionValidate";
import LoadingPage from "../loadingPage";

function BaseLogin() {
    const navegar = useNavigate();
    const [sessionValidated, setSessionValidated] = useState(true);

    useEffect(() => {
    const validation = sessionValidate();

    if (validation.validated) navegar("/portais")
    else
        setTimeout(() => {
            setSessionValidated(validation.validated);
        }, 1500);
    }, [navegar]);


    if (sessionValidated) return <LoadingPage />;

    return (
        <div
        id="baseLoginPage"
        >
            <div className="baseLoginPageOutlet">
                <Outlet />
            </div>
        </div>
    );
}

export default BaseLogin;
