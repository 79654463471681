function WppToastError({ errTitle, errMessage }: { errTitle?: string; errMessage?: string }) {
    return (
        <>
            {errTitle && <b>{errTitle}</b>}
            {errTitle && errMessage && <br />}
            {errMessage && <span>{errMessage}</span>}
        </>
    );
}

export default WppToastError;
