import React from "react";
import { Modal, Button } from "react-bootstrap";
import MapAtendimentos from "../mapaAtendimentos";
import { toast } from "react-toastify";

interface MapModalProps {
  show: boolean;
  onClose: () => void;
  onCoordinatesSelected: (coordinates: { lat: number; lng: number }) => void;
}



const MapModal: React.FC<MapModalProps> = ({ show, onClose, onCoordinatesSelected }) => {
  const handleClick = () => {
    toast.success("Coordenada salva com sucesso!")
    onClose();
  }

  return (
    <Modal show={show} onHide={onClose} size="xl" aria-labelledby="map-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="map-modal-title">Mapa de Atendimentos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MapAtendimentos onCoordinatesSelected={onCoordinatesSelected} />
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ width: "10%" }} variant="primary" onClick={handleClick}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MapModal;
