import "./index.css";
import "react-day-picker/dist/style.css";
import { useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import {
    startOfMonth,
    endOfMonth,
    addMonths,
    addYears,
    startOfYear,
    endOfYear,
    startOfWeek,
    endOfWeek,
    addWeeks,
    getYear,
} from "date-fns";

const today = new Date();
const lastWeek = addWeeks(today, -1);
const lastMonth = addMonths(today, -1);
const lastYear = addYears(today, -1);

interface IPresets {
    label: string;
    selection: DateRange;
}

const presets: IPresets[] = [
    {
        label: `Hoje`,
        selection: { from: today, to: today },
    },
    {
        label: `Semana atual`,
        selection: { from: startOfWeek(today), to: endOfWeek(today) },
    },
    {
        label: `Semana passada`,
        selection: { from: startOfWeek(lastWeek), to: endOfWeek(lastWeek) },
    },
    {
        label: `Mês atual`,
        selection: { from: startOfMonth(today), to: endOfMonth(today) },
    },
    {
        label: `Mês passado`,
        selection: { from: startOfMonth(lastMonth), to: endOfMonth(lastMonth) },
    },
    {
        label: `Ano atual`,
        selection: { from: startOfYear(today), to: endOfYear(today) },
    },
    {
        label: `Ano passado`,
        selection: { from: startOfYear(lastYear), to: endOfYear(lastYear) },
    },
];

function DateRangeInput({
    selected,
    onChange,
    onFinish,
}: {
    selected: DateRange | undefined;
    onChange(value: any): any;
    onFinish(): any;
}) {
    const [range, setRange] = useState<DateRange | undefined>(selected || presets[0].selection);

    const handleSubmit = () => {
        onChange(range);
        onFinish();
    };

    const handleCancel = () => {
        onFinish();
    };

    return (
        <div id="DateRangeInput">
            <div id="selection">
                <div id="presets">
                    {presets.map((pst, i) => (
                        <button
                            key={i}
                            className={
                                pst.selection.from === range?.from && pst.selection.to === range?.to
                                    ? "selected"
                                    : ""
                            }
                            onClick={() => setRange(pst.selection)}
                        >
                            {pst.label}
                        </button>
                    ))}
                </div>

                <DayPicker
                    mode="range"
                    selected={range}
                    onSelect={setRange}
                    numberOfMonths={2}
                    showOutsideDays
                    locale={ptBR}
                    captionLayout="dropdown-buttons"
                    fromYear={2018}
                    toYear={getYear(addYears(today, 1))}
                    defaultMonth={addMonths(today, -1)}
                />
            </div>

            <div id="controls">
                <button onClick={handleCancel}>Cancelar</button>
                <button onClick={handleSubmit}>OK</button>
            </div>
        </div>
    );
}

export default DateRangeInput;
