import "./styles.css";
import { Outlet, useNavigate } from "react-router-dom";
import Cabecalho from "../../components/cabecalho";
import Menu from "../../components/menu";
import { useEffect, useState } from "react";
import sessionValidate from "../../utils/sessionValidate";
import LoadingPage from "../loadingPage";

function BaseHome() {
  const navegar = useNavigate();
  const [sessionValidated, setSessionValidated] = useState<boolean>(false);

  useEffect(() => {
    const validation = sessionValidate();

    if (!validation.validated) navegar("/login");
    else setTimeout(() => setSessionValidated(validation.validated), 1500);
  }, [navegar]);

  if (!sessionValidated) return <LoadingPage />;

  return (
    <div id="baseHomePage">
      <div className="menuLateral">
        <Menu />
      </div>

      <div className="cabecalhoOutlet">
        <div className="cabecalho">
          <Cabecalho />
        </div>
      </div>

      <div className="conteudo">
        <div className="homePageOutlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default BaseHome;
