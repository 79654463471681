export function capitalize(str: string | undefined) {
    const strSplit = str?.split(" ");
    const strCapitalize = strSplit?.map((s) => s.substring(0, 1).toUpperCase() + s.substring(1));
    const strJoin = strCapitalize?.join(" ");
    return strJoin || "";
}

export function capitalizeArray(strArray: string[] | undefined) {
    return strArray?.map((s) => capitalize(s));
}

export function toUpperArray(strArray: string[] | undefined) {
    return strArray?.map((s) => s.toUpperCase());
}

export function toLowerArray(strArray: string[] | undefined) {
    return strArray?.map((s) => s.toLowerCase());
}
