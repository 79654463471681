import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Api from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card } from "react-bootstrap";
import treatString from "../../../utils/treatString";
import { capitalize } from "../../../utils/capitalize";

function GridSms({
    config,
    permissoesUser,
}: {
    config: any;
    permissoesUser: {
        administrador: boolean;
        atendimentos_create: boolean;
        atendimentos_edit: boolean;
        atendimentos_view: boolean;
        demandas_create: boolean;
        demandas_edit: boolean;
        demandas_view: boolean;
        engajamentos_create: boolean;
        engajamentos_edit: boolean;
        engajamentos_view: boolean;
        id: number;
        id_user: number;
        noticias_create: boolean;
        noticias_edit: boolean;
        noticias_view: boolean;
        notificacoes_create: boolean;
        notificacoes_edit: boolean;
        notificacoes_view: boolean;
        stakeholders_create: boolean;
        stakeholders_edit: boolean;
        stakeholders_view: boolean;
        usuario_create: boolean;
        usuario_edit: boolean;
        visitas_create: boolean;
        visitas_edit: boolean;
        visitas_view: boolean;
        sms_view: boolean;
        sms_create: boolean;
        solicitacoes_view: boolean;
        solicitacoes_edit: boolean;
    };
}) {
    const { t } = useTranslation();
    const navegar = useNavigate();

    const [datagridRows, setDatagridRows] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModalFiltro, setOpenModalFiltro] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);

    const [titulo, setTitulo] = useState<string>("");
    const [mensagem, setMensagem] = useState<string>("");
    const [autor, setAutor] = useState<string>("");

    const [toastExibido, setToastExibido] = useState(false);

    useEffect(() => {
        if (!permissoesUser.sms_view && !toastExibido) {
            toast.error("Você não tem permissão para visualizar SMS!");
            setToastExibido(true);
        }
    }, [permissoesUser.sms_view, toastExibido]);

    const [autoresExistentes] = useMemo(() => {
        const autores: string[] = [];

        datagridRows.forEach((r) => {
            const autor = capitalize(treatString(r.autor));

            if (autor && !autores.includes(autor)) autores.push(autor);
        });

        return [autores.sort((a, b) => a.localeCompare(b))];
    }, [datagridRows]);

    //filtros
    const [filtroMensagem, setFiltroMensagem] = useState<string>();
    const [filtroTitulo, setFiltroTitulo] = useState<string>();
    const [filtroAutor, setFiltroAutor] = useState<string>();
    const [filtroStatus, setFiltroStatus] = useState<string>();

    const tiposFiltro = [
        {
            value: "Titulo",
            label: t("Titulo"),
            type: "string",
            filter: filtroTitulo,
            setFilter: setFiltroTitulo,
        },
        {
            value: "Mensagem",
            label: t("Mensagem"),
            type: "string",
            filter: filtroMensagem,
            setFilter: setFiltroMensagem,
        },
        {
            value: "Autor",
            label: t("Autor"),
            type: "select",
            opts: autoresExistentes,
            filter: filtroAutor,
            setFilter: setFiltroAutor,
        },
        {
            value: "Status",
            label: t("Status"),
            type: "select",
            opts: [
                { label: t("pendente"), value: "pendente" },
                { label: t("concluido"), value: "concluido" },
            ],
            filter: filtroStatus,
            setFilter: setFiltroStatus,
        },
    ];

    const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

    const dadosFiltrados = useMemo(
        () =>
            datagridRows.filter(
                (v) =>
                    (!filtroMensagem ||
                        v?.mensagem?.toLowerCase().includes(filtroMensagem?.toLowerCase())) &&
                    (!filtroTitulo ||
                        v.titulo?.toLowerCase().includes(filtroTitulo?.toLowerCase())) &&
                    (!filtroStatus ||
                        v.status?.toLowerCase().includes(filtroStatus?.toLowerCase())) &&
                    (!filtroAutor || v.autor?.toLowerCase().includes(filtroAutor?.toLowerCase()))
            ),
        [datagridRows, filtroStatus, filtroAutor, filtroMensagem, filtroTitulo]
    );

    useEffect(() => {
        if (!selectedRowData) return;

        setTitulo(selectedRowData.titulo || "");
        setMensagem(selectedRowData.mensagem || "");
        setAutor(selectedRowData.autor || "");
    }, [selectedRowData]);

    const handleRowClick = (params: any) => {
        setSelectedRowData(params.row);
        setIsModalOpen(true);
    };

    const datagridColunas: readonly GridColDef<any>[] = [
        {
            field: "autor",
            headerName: t("Autor") as string,
            width: 140,
        },
        {
            field: "mensagem",
            headerName: t("Mensagem") as string,
            width: 200,
        },
        {
            field: "titulo",
            headerName: t("Titulo") as string,
            width: 300,
        },
        {
            field: "status",
            headerName: t("Status") as string,
            width: 150,
        },
    ];

    const updateDatagrid = useCallback(() => {
        Api.GetSms(config)
            .then((data: any[]) => {
                setDatagridRows(
                    data.map((d, i) => ({
                        ...d,
                        arrayId: i + 1,
                    }))
                );
            })
            .catch((err: any) => {
                if (typeof err === "string") {
                    toast.error(t(err));
                    navegar("/login");
                    return;
                }

                toast.error(t("Houve um erro ao carregar os sms"));
            });
    }, [config, navegar, t]);

    useEffect(updateDatagrid, [updateDatagrid]);

    const handleModalFiltros = (filtro: string) => {
        setFiltroAtivo((filtros) => {
            const newFiltros = [...filtros];
            const fIndex = newFiltros.findIndex((f) => f === filtro);
            if (fIndex >= 0) {
                newFiltros.splice(fIndex, 1);
                tiposFiltro.find((f) => f.value === filtro)?.setFilter(undefined);
            } else {
                newFiltros.push(filtro);
            }
            return newFiltros;
        });
        setOpenModalFiltro(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Col sm={12}>
            <div className="grid-dados">
                <Col sm={12}>
                    <Row>
                        <Col sm={9}>
                            {filtrosAtivos.map((filtro, i) => {
                                const filterObj = tiposFiltro.find(({ value }) => value === filtro);
                                if (!filterObj) return null;

                                const { filter, setFilter, type, opts } = filterObj;

                                return (
                                    <Col className="filtro-ativo" key={i}>
                                        <Row>
                                            <Col sm={9}>
                                                {type === "select" ? (
                                                    <Form.Select
                                                        value={filter?.toString() || ""}
                                                        onChange={(e) => {
                                                            setFilter(e.target.value as any);
                                                        }}
                                                        style={{ height: "73%" }}
                                                    >
                                                        <option
                                                            label={`Filtrar ${filtro}`}
                                                            value={""}
                                                        />
                                                        {opts?.map((opt, i) => {
                                                            const isString =
                                                                typeof opt === "string";

                                                            return (
                                                                <option
                                                                    key={i}
                                                                    label={
                                                                        isString ? opt : opt.label
                                                                    }
                                                                    value={
                                                                        isString ? opt : opt.value
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                    </Form.Select>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Filtrar ${filtro}`}
                                                        value={filter?.toString() || ""}
                                                        onChange={(e) => {
                                                            setFilter(e.target.value as any);
                                                        }}
                                                        style={{ height: "73%" }}
                                                    />
                                                )}
                                            </Col>
                                            <Col sm={3}>
                                                <button
                                                    onClick={() => {
                                                        setFilter(undefined);
                                                    }}
                                                    className="botao-filtro mt-2"
                                                >
                                                    {t("Limpar Filtro")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>
                        <Col sm={3}>
                            <button
                                className="botao-filtro mt-2"
                                onClick={() => setOpenModalFiltro(!openModalFiltro)}
                            >
                                {t("Filtros")}
                            </button>
                        </Col>
                    </Row>

                    {openModalFiltro && (
                        <Col sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <Col sm={12} className="justify-content-center">
                                        <Row>
                                            {tiposFiltro.map((filtro, i) => (
                                                <Col sm={3} key={i}>
                                                    <button
                                                        className={`filtros-modal ${
                                                            filtrosAtivos?.includes(filtro.value)
                                                                ? "ativo"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            handleModalFiltros(filtro.value);
                                                        }}
                                                    >
                                                        {filtro.label}
                                                    </button>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Col>

                <div>
                    {!permissoesUser.sms_view && (
                        <div>
                            <DataGrid rows={[]} columns={datagridColunas} />
                        </div>
                    )}
                    {permissoesUser.sms_view && (
                        <DataGrid
                            rows={dadosFiltrados}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    )}
                </div>
            </div>

            {/* Modal */}
            <Modal
                show={isModalOpen}
                onHide={closeModal}
                className="modal-xl"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Detalhes do SMS")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRowData && (
                        <div id="content-grid">
                            <Col sm={12} className="mb-2">
                                <Col sm={12} className="mt-3 mb-3">
                                    <Form.Label>{t("Titulo")}</Form.Label>
                                    <Form.Control
                                        className="formInput"
                                        type={"text"}
                                        value={titulo}
                                        onChange={(e) => {
                                            setTitulo(e.target.value);
                                        }}
                                    />
                                    <Form.Label className="mt-3">{t("Texto")}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        className="formInput"
                                        value={mensagem}
                                        onChange={(e) => {
                                            setMensagem(e.target.value);
                                        }}
                                    />
                                </Col>
                                <Col sm={12} className="mt-3 mb-3">
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Label className="mt-3">{t("Autor")}</Form.Label>
                                            <Form.Control
                                                type={"text"}
                                                className="formInput"
                                                value={autor}
                                                onChange={(e) => {
                                                    setAutor(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default GridSms;
