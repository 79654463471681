import React, { useState, useEffect } from "react";
import { Col, Row, Table, Modal, Form, Button } from "react-bootstrap";
import { PiLockKeyOpenFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { toast } from "react-toastify";
import Api from "../../config/api";
import { userInfo } from "os";
import BotaoVoltar from "../../components/buttonReturnPage";
interface Permissions {
  administrador: boolean;
  atendimentos_create: boolean;
  atendimentos_edit: boolean;
  atendimentos_view: boolean;
  engajamentos_create: boolean;
  engajamentos_edit: boolean;
  engajamentos_view: boolean;
  visitas_create: boolean;
  visitas_edit: boolean;
  visitas_view: boolean;
  noticias_create: boolean;
  noticias_edit: boolean;
  noticias_view: boolean;
  notificacoes_create: boolean;
  // notificacoes_edit: boolean;
  notificacoes_view: boolean;
  stakeholders_create: boolean;
  stakeholders_edit: boolean;
  stakeholders_view: boolean;
  demandas_create: boolean;
  demandas_edit: boolean;
  demandas_view: boolean;
  usuario_create: boolean;
  usuario_edit: boolean;
  sms_view: boolean;
  sms_create: boolean;
  solicitacoes_view: boolean;
  solicitacoes_edit: boolean;
  painelSocial_view: boolean;
  painelSocial_create: boolean;
}

function Permissoes() {
  const { t } = useTranslation();

  const tokenUsuario = sessionStorage.getItem("tokenUsuario");
  let config = { headers: { authorization: tokenUsuario } };

  const initialPermissions: Permissions = {
    administrador: false,
    atendimentos_create: false,
    atendimentos_edit: false,
    atendimentos_view: false,
    demandas_create: false,
    demandas_edit: false,
    demandas_view: false,
    engajamentos_create: false,
    engajamentos_edit: false,
    engajamentos_view: false,
    visitas_create: false,
    visitas_edit: false,
    visitas_view: false,
    noticias_create: false,
    noticias_edit: false,
    noticias_view: false,
    notificacoes_create: false,
    // notificacoes_edit: false,
    notificacoes_view: false,
    stakeholders_create: false,
    stakeholders_edit: false,
    stakeholders_view: false,
    usuario_create: false,
    usuario_edit: false,
    sms_view: false,
    sms_create: false,
    solicitacoes_view: false,
    solicitacoes_edit: false,
    painelSocial_view: false,
    painelSocial_create: false,
  };

  const [showCadastroModal, setShowCadastroModal] = useState(false);
  const [idTabela, setIdTabela] = useState<number>();
  const [showModal, setShowModal] = useState(false);
  const [permissions, setPermissions] =
    useState<Permissions>(initialPermissions);

  const [permissoesTrocadas, setPermissoesTrocadas] = useState(false);

  //inputs
  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telefone, setTelefone] = useState<string>("");
  const [senha, setSenha] = useState("");

  //select
  const [estado, setEstado] = useState<string>("");
  const [municipio, setMunicipio] = useState<string>("");

  // listas
  const [listaEstados, setListaEstados] = useState<any>([]);
  const [listaMunicipios, setListaMunicipios] = useState<any>([]);
  const [listaAnalistas, setListaAnalistas] = useState<any>([]);

  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleOpenModalDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
  };

  // permissoes user
  const [permissoesUser, setPermissoesUser] = useState({
    administrador: false,
    atendimentos_create: false,
    atendimentos_edit: false,
    atendimentos_view: false,
    demandas_create: false,
    demandas_edit: false,
    demandas_view: false,
    engajamentos_create: false,
    engajamentos_edit: false,
    engajamentos_view: false,
    id: 0,
    id_user: 0,
    noticias_create: false,
    noticias_edit: false,
    noticias_view: false,
    notificacoes_create: false,
    // notificacoes_edit: false,
    notificacoes_view: false,
    stakeholders_create: false,
    stakeholders_edit: false,
    stakeholders_view: false,
    usuario_create: false,
    usuario_edit: false,
    visitas_create: false,
    visitas_edit: false,
    visitas_view: false,
    sms_view: false,
    sms_create: false,
    solicitacoes_view: false,
    solicitacoes_edit: false,
    painelSocial_view: false,
    painelSocial_create: false,
  });

  const usuarioJson = sessionStorage.getItem("loginUsuario");

  let usuario: any;
  if (usuarioJson != null) {
    usuario = JSON.parse(usuarioJson);
  }

  const handleCadastroShow = () => setShowCadastroModal(true);
  const handleCadastroClose = () => setShowCadastroModal(false);

  useEffect(() => {
    const permissionsUsuario = sessionStorage.getItem("permissoesUsuario");
    if (permissionsUsuario) {
      setPermissoesUser(JSON.parse(permissionsUsuario));
    }
  }, [permissoesTrocadas]);

  const getPermissoesByUser = async (userId: number) => {
    const data = await Api.GetPermissoesById(userId, config);
    const objetoRetorno = {
      administrador: data.administrador,
      atendimentos_create: data.atendimentos_create,
      atendimentos_edit: data.atendimentos_edit,
      atendimentos_view: data.atendimentos_view,
      demandas_create: data.demandas_create,
      demandas_edit: data.demandas_edit,
      demandas_view: data.demandas_view,
      engajamentos_create: data.engajamentos_create,
      engajamentos_edit: data.engajamentos_edit,
      engajamentos_view: data.engajamentos_view,
      visitas_create: data.visitas_create,
      visitas_edit: data.visitas_edit,
      visitas_view: data.visitas_view,
      noticias_create: data.noticias_create,
      noticias_edit: data.noticias_edit,
      noticias_view: data.noticias_view,
      notificacoes_create: data.notificacoes_create,
      // notificacoes_edit: data.notificacoes_edit,
      notificacoes_view: data.notificacoes_view,
      stakeholders_create: data.stakeholders_create,
      stakeholders_edit: data.stakeholders_edit,
      stakeholders_view: data.stakeholders_view,
      usuario_create: data.usuario_create,
      usuario_edit: data.usuario_edit,
      sms_view: data.sms_view,
      sms_create: data.sms_create,
      solicitacoes_view: data.solicitacoes_view,
      solicitacoes_edit: data.solicitacoes_edit,
      painelSocial_view: data.painelSocial_view,
      painelSocial_create: data.painelSocial_create,
    };

    setPermissions(objetoRetorno);
  };

  const handleShow = (userId: number) => {
    getPermissoesByUser(userId);
    setIdTabela(userId);
    setShowModal(true);
    setPermissions(initialPermissions);
  };

  const handleClose = () => setShowModal(false);

  const handlePermissionToggle = (permission: keyof Permissions) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permission]: !prevPermissions[permission],
    }));
  };

  const getApi = async () => {
    await Api.GetLocales().then((data) => {
      setListaEstados(data.localidades.estados);
      setListaMunicipios(data.localidades.municipios);
    });

    await Api.GetAnalistas(config).then((data: any) => {
      setListaAnalistas(data);
    });
  };

  const handleDeleteAnalista = async (id: any) => {
    await Api.DeleteUsuario(id).then((res) => {
      toast.success(t("Analista deletado com sucesso!"));
      handleCloseModalDelete();
      handleClose();
      getApi();
    })
      .catch((err) => {
        toast.error(t("Não foi possivel deletar analista!"));
        handleCloseModalDelete();
        handleClose();
      });
  }

  useEffect(() => {
    getApi();
  }, []);

  const handdleSubimitUsuario = async (event: any) => {
    event.preventDefault();

    const data = {
      nome,
      login: telefone,
      senha,
      email,
      telefone,
      tipo: 1,
      aceitou_termos: true,
      localidade: null,
      foto: null,
      estado,
      municipio,
      familia: null,
      lat: null,
      lon: null,
      linguagem: "pt",
    };

    await Api.PostUsuario(data, config)
      .then((data) => {
        toast.success(t("Cadastro realizado com sucesso!"));
        return data;
      })
      .catch((err) => toast.error(t("Não foi possível realizar o cadastro!")));

    setNome("");
    setEmail("");
    setTelefone("");
    setSenha("");
    setEstado("");
    setMunicipio("");
  };

  const handdleSubimitPermissoes = async () => {
    const {
      administrador,
      atendimentos_create,
      atendimentos_edit,
      atendimentos_view,
      engajamentos_create,
      engajamentos_edit,
      engajamentos_view,
      visitas_create,
      visitas_edit,
      visitas_view,
      noticias_create,
      noticias_edit,
      noticias_view,
      notificacoes_create,
      // notificacoes_edit,
      notificacoes_view,
      stakeholders_create,
      stakeholders_edit,
      stakeholders_view,
      demandas_create,
      demandas_edit,
      demandas_view,
      usuario_create,
      usuario_edit,
      sms_view,
      sms_create,
      solicitacoes_view,
      solicitacoes_edit,
      painelSocial_view,
      painelSocial_create,
    } = permissions;

    const data = {
      idUser: idTabela,
      administrador,
      atendimentos_create,
      atendimentos_edit,
      atendimentos_view,
      engajamentos_create,
      engajamentos_edit,
      engajamentos_view,
      visitas_create,
      visitas_edit,
      visitas_view,
      noticias_create,
      noticias_edit,
      noticias_view,
      notificacoes_create,
      // notificacoes_edit,
      notificacoes_view,
      stakeholders_create,
      stakeholders_edit,
      stakeholders_view,
      demandas_create,
      demandas_edit,
      demandas_view,
      usuario_create,
      usuario_edit,
      sms_view,
      sms_create,
      solicitacoes_view,
      solicitacoes_edit,
      painelSocial_view,
      painelSocial_create,
    };

    await Api.PutPermissoes(data, config)
      .then((res) => {
        toast.success(t("Permissões alteradas com sucesso!"));

        const userStorage = sessionStorage.getItem("userStorage");
        const userID = userStorage ? JSON.parse(userStorage).id : null;

        if (data.idUser !== userID) return;

        sessionStorage.setItem("permissoesUsuario", JSON.stringify(data));
        setPermissoesTrocadas(true);
      })
      .catch((err) => {
        toast.error(t("Não foi possível alterar as permissões!"));
      });

    handleClose();
  };

  const convertSnakeCaseToReadable = (text: string) => {
    const words = text.split("_");

    const replaceMap: { [key: string]: string } = {
      create: "Criar",
      edit: "Editar",
      view: "Visualizar",
    };

    const convertedWords = words.map((word, _index) => {
      if (replaceMap[word.toLowerCase()]) {
        return replaceMap[word.toLowerCase()];
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return convertedWords.join(" - ");
  };


  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "10px", marginLeft: "7px" }}>
        <BotaoVoltar />
      </div>
      <Col sm={12} id="permissoesPage">
        {permissoesUser.usuario_create ? (
          <Button
            onClick={handleCadastroShow}
            className="botao-cadastro-usuarios"
          >
            {t("Cadastrar Analista")}
          </Button>
        ) : (
          <Button
            onClick={() =>
              toast.error("Você não tem permissão para criar um novo analista!")
            }
            className="botao-cadastro-usuarios"
          >
            {t("Cadastrar Analista")}
          </Button>
        )}

        <Modal
          show={showCadastroModal}
          onHide={handleCadastroClose}
          className="modal-xl d-flex justify-content-center align-items-center"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Cadastrar Novo Analista")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handdleSubimitUsuario}>
              <Col sm={12}>
                <Row>
                  <Col sm={4}>
                    <Form.Label>{t("Nome")}</Form.Label>
                    <Form.Control
                      className="formInput"
                      type="text"
                      value={nome}
                      onChange={(e) => {
                        setNome(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Label>{t("Email")}</Form.Label>
                    <Form.Control
                      className="formInput"
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Label>{t("Telefone")}</Form.Label>
                    <Form.Control
                      className="formInput"
                      type="text"
                      value={telefone}
                      onChange={(e) => {
                        setTelefone(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} className="mt-4">
                    <Form.Label>{t("Estado")}</Form.Label>
                    <Form.Select
                      className="formSelect"
                      required
                      value={estado}
                      onChange={(e) => {
                        setEstado(e.target.value);
                      }}
                    >
                      <option value={""}>{t("Selecione o estado")}</option>
                      {listaEstados?.map((estado: any) => {
                        return (
                          <option value={estado.sigla}>{estado.nome}</option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  <Col sm={6} className="mt-4">
                    <Form.Label>{t("Municipio")}</Form.Label>
                    <Form.Select
                      className="formSelect"
                      required
                      value={municipio}
                      onChange={(e) => {
                        setMunicipio(e.target.value);
                      }}
                    >
                      <option value={""}>{t("Selecione o municipio")}</option>
                      {listaMunicipios[estado]?.map((municipio: any) => {
                        return <option value={municipio}>{municipio}</option>;
                      })}
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                <Col sm={12}>
                  <Button onClick={handdleSubimitUsuario} className="botao-grid">
                    {t("Cadastrar")}
                  </Button>
                </Col>
              </Col>
            </Form>
          </Modal.Body>
        </Modal>

        <h3>{t("Permissões de acesso")}</h3>
        <Col sm={12} className="tabela-container mb-5">
          <Table striped hover className="tabela-permissoes">
            <thead>
              <tr>
                <th>Login</th>
                <th>{t("Nome")}</th>
                <th>Email</th>
                <th>{t("Tipo de Usuário")}</th>
                <th>{t("Permissionamento")}</th>
              </tr>
            </thead>
            {!!listaAnalistas &&
              Array.isArray(listaAnalistas) &&
              listaAnalistas.map((usuario: any, index: any) => (
                <tbody key={index}>
                  <tr
                    className={index % 2 === 0 ? "linha-branca" : "linha-cinza"}
                  >
                    <td className="dados-usuario">{usuario.login}</td>
                    <td className="dados-usuario">{usuario.nome}</td>
                    <td className="dados-usuario">{usuario.email}</td>
                    <td className="dados-usuario">
                      {usuario.tipo === 1 && "Analista"}
                    </td>
                    {permissoesUser.usuario_edit ? (
                      <td>
                        <PiLockKeyOpenFill
                          onClick={() => handleShow(usuario.id)}
                        />
                      </td>
                    ) : (
                      <td>
                        <PiLockKeyOpenFill
                          onClick={() =>
                            toast.error(
                              "Você não tem permissão para alterar permissões de outro analista!"
                            )
                          }
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              ))}

            {!listaAnalistas && <></>}
          </Table>
        </Col>

        {/* Modal */}
        <Modal
          show={showModal}
          onHide={handleClose}
          className="modal-permissoes mt-3"
        >
          <Modal.Body className="container-tabela-button">
            <Col sm={12} className="tabela-container-modal">
              <Table striped hover className="tabela-permissoes-usuarios">
                <thead>
                  <tr>
                    <th>{t("Acessos")}</th>
                    <th>{t("Editar")}</th>
                  </tr>
                </thead>
                <tbody>
                  { }
                  {Object.entries(permissions).map(
                    (permission: any, index: any) => (
                      <tr key={index[0]}>
                        <td>{t(convertSnakeCaseToReadable(permission[0]))}</td>
                        <td>
                          <Form.Check
                            type="switch"
                            checked={permission[1]}
                            onChange={() =>
                              handlePermissionToggle(
                                permission[0] as keyof Permissions
                              )
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </Col>
            <Button
              className="botao-modal-permissoes"
              type="submit"
              onClick={handdleSubimitPermissoes}
            >
              {t("Salvar")}
            </Button>
            <Button
              className="botao-modal-delete btn-danger"
              type="button"
              onClick={handleOpenModalDelete}
            >
              {t("Deletar analista")}
            </Button>
          </Modal.Body>
        </Modal>

        <Modal
          show={showModalDelete}
          onHide={handleCloseModalDelete}
          centered={true}
        >
          <Modal.Body>
            <div id="modalLogin">
              <form className="form">
                <p className="titleForgotPassword">
                  {t("Deseja deletar analista?")}
                </p>

                <button
                  className="botao-grid"
                  type="button"
                  onClick={() => handleDeleteAnalista(idTabela)}
                >
                  {t("Sim")}
                </button>
                <button
                  className="botao-grid"
                  type="button"
                  onClick={handleCloseModalDelete}
                >
                  {t("Não")}
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </Col>
    </div>
  );
}

export default Permissoes;
